import { toast } from 'react-toastify';
import axios from 'axios';

export const newPractice = async (date, team_id, user_access_key, share_level, practice_drills) =>{
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/practice/create`, 
            {date, team_id, user_access_key, share_level, practice_drills})
        .then((response)=>{
            toast.success(`Practice created!`)
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('An error ocurred.')
    }
}

export const updatePracticeService = async (date, team_id, access_key, share_level, practice_drills) =>{
    try {
        return await axios.put(`${process.env.REACT_APP_API_URL}/api/practice/update`, 
            {date, team_id, access_key, share_level, practice_drills})
        .then((response)=>{
            toast.success(`Practice updated!`)
            return true
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('An error ocurred.')
    }
}

export const listPracticeByUserAccessKey = async (access_key, page, startDate, endDate, teamSelected) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/practice/list_by_user_access_key/`,{access_key, page, startDate, endDate, teamSelected})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('A server error ocurred.')
    }
}

export const listGoalsByUserAccessKey = async (access_key, startDate, endDate, teamSelected) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/practice/list_goals_by_user_access_key/`,{access_key, startDate, endDate, teamSelected})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('A server error ocurred.')
    }
}

export const listJournalByUserAccessKey = async (access_key, startDate, endDate, teamSelected) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/practice/list_journal_by_user_access_key/`,{access_key, startDate, endDate, teamSelected})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('A server error ocurred.')
    }
}

export const getPracticeDetailsService = async (access_key) => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/practice/get_practice_by_access_key/${access_key}`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('A server error ocurred.')
    }
}

export const getPracticeFeedbackService = async (access_key) => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/practice/get_practice_feedback_by_access_key/${access_key}`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('A server error ocurred.')
    }
}

export const deletePracticeService = async (access_key) => {
    try {
        return await axios.delete(`${process.env.REACT_APP_API_URL}/api/practice/delete_practice/${access_key}`)
        .then((response)=>{
            toast.success('Practice deleted successfuly!')
            return true
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('A server error ocurred.')
    }
}

export const savePracticeFeedbackService = async (practice_feedback) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/practice/save_practice_feedback/`,{practice_feedback})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('A server error ocurred.')
    }
}

