import React from 'react'
import Navbar from '../../../components/Navbar/Navbar'
import Footer from '../../../components/Footer/Footer'
import './HomeLandingPage.css'
import PracticeScreenshotImage from '../../../images/practice_screenshot.png'
import { PiArticle, PiChartBarBold, PiClipboardText, PiPresentationChart, PiUsersThree } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import TipsComponent from '../../../components/TipsComponent'
import DrillListComponent from '../../app_pages/Drill/DrillListComponent'

function LandingPage() {
  const navigate = useNavigate()

  return (
    <>
    <Navbar />
    <div className="hero">
      <div className="flex flex-wrap md:flex-nowrap lg:flex-nowrap gap-4 justify-content-between">
        <div className='flex flex-column gap-4 flex-1 justify-content-center'>
          <div className="font-bold text-2xl md:text-4xl line-height-3 text-center">
            Transform Your Volleyball Practices with LEAGR
          </div>
          <div className="text--regular--normal text-center">
            Plan, Organize, and Analyze Your Team’s Performance Like a Pro.
          </div>
          <Button label='Start Today!' onClick={()=>navigate('/register')} className='w-max align-self-center' size='large' />
        </div>
        <div className="flex flex-1 justify-content-center">
          <img src={PracticeScreenshotImage} alt="practice screenshot" className='max-h-28rem border-round shadow-6' />
        </div>
      </div>
    </div>

    <div className="container">
      <div className="flex justify-content-center w-full">
        <DrillListComponent isNavigable={false} checkDrillAdded={()=>{}} demoMode={true} />  
      </div>
    </div>

    <div className="container" style={{backgroundColor: '#CCD9E5'}}>
      <div className="flex flex-column justify-content-center w-full">
        <div className="benefits--texts mb-4">
          <div className='text-center text-2xl md:text-4xl font-bold w-full'>Everything You Need to Elevate Your Team’s Performance</div>
        </div>
        <div className='flex flex-wrap justify-content-center gap-4'>
          <Card className='w-18rem'>
            <div className='flex gap-2 mb-2 align-items-center text-2xl font-bold'><PiPresentationChart size={32} /> Dashboard</div>
            Get a comprehensive overview of your team’s progress, upcoming practices, and key statistics—all in one place.
          </Card>
          <Card className='w-18rem'>
            <div className='flex gap-2 mb-2 align-items-center text-2xl font-bold'><PiClipboardText size={32} /> Practices</div>
            Easily create and manage practices, select drills, and track your team’s focus on physical, technical, and tactical goals.
          </Card>
          <Card className='w-18rem'>
            <div className='flex gap-2 mb-2 align-items-center text-2xl font-bold'><PiArticle size={32} /> Drills</div>
            Create drills with detailed goals and video links. Save them as public to share or keep them private to you.
          </Card>
          <Card className='w-18rem'>
            <div className='flex gap-2 mb-2 align-items-center text-2xl font-bold'><PiUsersThree size={32} /> Teams</div>
            Manage your teams and players seamlessly, ensuring everyone will be tracked by the practices and statistics.
          </Card>
          <Card className='w-18rem'>
            <div className='flex gap-2 mb-2 align-items-center text-2xl font-bold'><PiChartBarBold size={32} /> Statistics</div>
            Dive deep into individual and team performance metrics, from player attack efficiency to team tendencies.
          </Card>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="flex flex-column w-full">
        <TipsComponent />
      </div>
    </div>

    <div className="container" style={{backgroundColor: '#CCD9E5'}}>
      <div className="flex flex-column gap-4 w-full">
        <div className="text-center text-2xl md:text-4xl font-bold">
          Simple Steps to Smarter Practices
        </div>
        <div className='flex flex-column gap-2 w-full'>
          <Card className='w-full'>
            <div className='flex flex-column gap-2'>
              <span className='font-bold text-lg md:text-2xl'>1. Create your team: </span>
              <span className=''>Add your players and organize them into teams for easy management.</span>
            </div>
          </Card>
          <Card>
            <div className='flex flex-column gap-2'>
              <span className='font-bold text-lg md:text-2xl'>2. Build/Find Your Drills: </span>
              <span className=''>Design drills tailored to your team’s needs, or choose from a library of public drills created by other coaches.</span>
            </div>
          </Card>
          <Card>
            <div className='flex flex-column gap-2'>
              <span className='font-bold text-lg md:text-2xl'>3. Plan Your Practices: </span>
              <span className=''>Select drills, and set goals, for each practice session.</span>
            </div>
          </Card>
          <Card>
            <div className='flex flex-column gap-2'>
              <span className='font-bold text-lg md:text-2xl'>4. Analyze & Improve: </span>
              <span className=''>Track your team’s progress with detailed statistics and adjust your strategy and practices for maximum impact.</span>
            </div>
          </Card>
        </div>
        <Button label='Start Today!' onClick={()=>navigate('/register')} className='w-max align-self-center' size='large' />
      </div>
    </div>

    <Footer />
    </>
  )
}

export default LandingPage