import React from 'react'
import TipsComponent from '../../components/TipsComponent'
import AppBodyComponent from '../../components/AppBody/AppBodyComponent'

function Tips() {
    return (
        <AppBodyComponent activeMenuNumber={9}>
            <TipsComponent />
        </AppBodyComponent>
    )
}

export default Tips