import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../../../context/AuthContext/AuthContext'
import { Calendar } from 'primereact/calendar'
import UserAutocompleteComponent from '../../../../components/UserAutocompleteComponent/UserAutocompleteComponent'
import { Button } from 'primereact/button'
import { deleteStatisticsData, listStatsPlayer, updateStatisticsData } from '../../../../services/StatsService'
import { set } from 'react-hook-form'
import { DateTime } from 'luxon'
import { Chart } from 'primereact/chart'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog'
import { PiWarningCircle } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'

function MyStatsPlayers() {

    const [list, setList] = useState()
    const navigate = useNavigate()
    const [listOriginal, setListOriginal] = useState()
    const {userAuth} = useContext(AuthContext)
    const getDaysDiff = (days) => {
        const now = new Date();
        const weekAgo = new Date(now.getTime() + days * 24 * 60 * 60 * 1000);
        weekAgo.setHours(0, 0, 0, 0)
        return weekAgo;
    };
    const [startDate, setStartDate] = useState(getDaysDiff(-7))
    const [endDate, setEndDate] = useState(getDaysDiff(7))
    const [player,setPlayer] = useState()
    const [idxEditMode, setIdxEditMode] = useState({idx: '', key: ''})

    const listStats = () => {
        if (userAuth?.user?.access_key){
            listStatsPlayer(userAuth?.user?.access_key, startDate, endDate, player?.player_id).then(response=>{
                response && oraganizeData(response)
            })
        }
    }

    const oraganizeData = (data) => {
        let _tempData = []
        data.forEach(element => {
            element.statistics_data_temp?.forEach(dataItem=>{
                const index = _tempData.findIndex(e=>dataItem.name === e.name)
                if (index === -1){
                    _tempData.push(
                        {
                            name: dataItem.name,
                            labels: [DateTime.fromISO(element.date, { zone: 'UTC' }).toFormat('MM/dd/yyyy')],
                            datasets: [{
                                label: dataItem.name,
                                data: [dataItem.number ? dataItem.number : ''],
                                ids: [dataItem.id ? dataItem.id : ''],
                                fill: false,
                                borderColor: documentStyle.getPropertyValue('--blue-500'),
                                tension: 0.4
                            }] 
                        }
                    )
                } else {
                    _tempData[index].labels.push(DateTime.fromISO(element.date, { zone: 'UTC' }).toFormat('MM/dd/yyyy'))
                    _tempData[index].datasets[0].data.push(dataItem.number ? dataItem.number : '')
                    _tempData[index].datasets[0].ids.push(dataItem.id ? dataItem.id : '')
                }
            })
        });
        setList(JSON.parse(JSON.stringify(_tempData)))
        setListOriginal(JSON.parse(JSON.stringify(_tempData)))
    }

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            }
        }
    };

    const deleteNumber = (idx, key) => {
        confirmDialog({
            message: `Do you want to delete this number?`,
            header: 'Confirm?',
            icon: <PiWarningCircle />,
            defaultFocus: 'accept',
            accept: () => confirmDeleteNumber(idx, key),
            reject : ()=>{}
        });
    }

    const confirmDeleteNumber = (idx, key) => {
        const _list = [...list]
        deleteStatisticsData(_list[idx].datasets[0].ids[key]).then(response=>{
            if (response) {
                _list[idx].labels.splice(key, 1)
                _list[idx].datasets[0].data.splice(key, 1)
                _list[idx].datasets[0].ids.splice(key, 1)
                setList(_list)
                const _listOriginal = [...listOriginal]
                _listOriginal[idx].labels.splice(key, 1)
                _listOriginal[idx].datasets[0].data.splice(key, 1)
                _listOriginal[idx].datasets[0].ids.splice(key, 1)
                setListOriginal(_listOriginal)
            }
        })
    }

    const updateNumber = (idx, key) => {
        const _id = Number(list[idx].datasets[0].ids[key])
        const _number = Number(list[idx].datasets[0].data[key])
        if (Number(_number) != Number(listOriginal[idx].datasets[0].data[key])){
            updateStatisticsData(_id, _number).then(response=>{
                if (!response) return
                const _listOriginal = [...listOriginal]
                listOriginal[idx].datasets[0].data[key] = _number
                setListOriginal(_listOriginal)
            })
        }
        setIdxEditMode({idx: '', key:''})
    }

    const changeNumber = (value, idx, key) => {
        let _list = [...list]
        if (Number(value) != Number(_list[idx].datasets[0].data[key])){
            _list[idx].datasets[0].data[key] = Number(value)
        }
        setList(_list)
    }
    
    const enableEdit = (key, idx) => {
        setIdxEditMode({idx: idx, key:key})
    }

    return (
        <div className='flex flex-column gap-2'>
            <Button label='Add player stats' className='w-max' icon='pi pi-plus' onClick={()=>navigate('/app/my_stats_player')} />
            <div className='flex flex-wrap gap-2'>
                <Calendar placeholder='Start Date' value={startDate} onChange={(e)=>setStartDate(e.value)} className='flex-1' />
                <Calendar placeholder='End Date' value={endDate} onChange={(e)=>setEndDate(e.value)} className='flex-1' />
                <UserAutocompleteComponent user={player} setUser={setPlayer} canCreate={false} searchCoaches={false} />
            </div>
            <Button label='Show data' className='w-max' onClick={listStats} />
            <Accordion activeIndex={0}>
                <AccordionTab header='Charts'>
                    <div className='flex flex-column gap-2'>
                        <div className='flex flex-column gap-4'>

                        </div>
                        {list ? list?.map((item, key)=>(
                        <div key={key} className={'flex flex-column gap-2 '}>
                            <div>{list.name}</div>
                            <Chart className='h-10rem md:h-15rem' type="line" data={item} options={options} />
                            
                        </div>)) : 'no data found'}
                    </div>
                </AccordionTab>
                <AccordionTab header='List'>
                    <div className='flex flex-column gap-4'>
                    <ConfirmDialog />
                    {list ? list?.map((item,idx)=>(
                        <div key={idx} className='flex flex-column gap-2'>
                            <div className='font-bold'>{item.name}</div>
                            {item.labels.map((e,key)=>(
                                <div key={key} className='flex gap-2 justify-content-between align-items-center'>
                                    <div className=''>{e}</div>
                                    {idxEditMode.idx === idx && idxEditMode.key === key ? 
                                        <div className='flex gap-1 md:gap-3 align-items-center'>
                                            <InputText className='w-5rem text-right' onChange={(e)=>changeNumber(e.target.value, idx, key)} value={item.datasets[0].data[key] ? item.datasets[0].data[key] : ''} />
                                            <Button size='small' icon='pi pi-check' severity='success' onClick={()=>updateNumber(idx, key)} />
                                        </div>
                                        : 
                                        <div className='flex gap-1 md:gap-3 align-items-center'>
                                            <div>{item.datasets[0].data[key] ? item.datasets[0].data[key] : ''}</div>
                                            <Button size='small' icon='pi pi-pencil' onClick={()=>enableEdit(key, idx)} />
                                            <Button size='small' icon='pi pi-trash' severity='danger' onClick={()=>deleteNumber(idx, key)} />
                                        </div>
                                    }
                            </div>
                            ))}
                        </div>)) : 'no data found'}
                    </div>
                </AccordionTab>
            </Accordion>
        </div>
    )
}

export default MyStatsPlayers