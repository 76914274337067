import React, { createContext, useEffect, useRef, useState } from 'react'
import { getMatchByAccessKey, getMatchById } from '../../../services/StatsService';
const StatisticsContext = createContext();

export const StatisticsProvider = ({children}) => {

    // const [gridData, setGridData] = useState(()=>{
    //     const statisticsSession = localStorage.getItem("gridData");
    //     try{
    //         return JSON.parse(statisticsSession)
    //     } catch {
    //         return null
    //     }
    // })

    // useEffect(()=>{
    //     localStorage.setItem("gridData", JSON.stringify(gridData))
    // },[gridData])

    const [gridData, setGridData] = useState()

    const _positions = [
        {
            number: 1,
        },
        {
            number: 6,
        },
        {
            number: 5,
        },
        {
            number: 4,
        },
        {
            number: 3,
        },
        {
            number: 2,
        },
    ]

    const [positions, setPositions] = useState(_positions)
    const [activeTeam, setActiveTeam] = useState(1)
    const [rotationA, setRotationA] = useState(1)
    const [rotationB, setRotationB] = useState(1)
    const [rotateA, setRotateA] = useState(false)
    const [rotateB, setRotateB] = useState(false)
    const [showSelectPosition, setShowSelectionPosition] = useState(false)
    const [playersA, setPlayersA] = useState()
    const [playersB, setPlayersB] = useState()
    const [currentRallyContext, setCurrentRallyContext] = useState([])
    const [gridLayout, setGridLayout] = useState(false)
    const currentActionRefContext = useRef()
    const [setMatchContext, setSetMatchContext] = useState(1) 
    const [enabledAutoRotationContext, setEnabledAutoRotationContext] = useState(true)
    const [activePlayersAContext, setActivePlayersAContext] = useState([])
    const [activePlayersBContext, setActivePlayersBContext] = useState([])
    const [speachMode, setSpeachMode] = useState(true)
    const [initialLocalData, setInitialLocalData] = useState({})



    useEffect(()=>{
        var _statsInitialDataList = JSON.parse(localStorage.getItem("statsInitialData"));
        const initialDataIndex = _statsInitialDataList?.length > 0 ? _statsInitialDataList?.findIndex(e=>e.matchAccessKey === initialLocalData?.matchAccessKey) : -1
        if (initialDataIndex === -1) 
        {
            _statsInitialDataList = _statsInitialDataList?.length > 0 ? [..._statsInitialDataList, initialLocalData] : [initialLocalData]
            localStorage.setItem("statsInitialData", JSON.stringify(_statsInitialDataList))
        } else {
            const index = _statsInitialDataList?.findIndex(e=>e.matchAccessKey === initialLocalData?.matchAccessKey)
            _statsInitialDataList[index] = initialLocalData
            localStorage.setItem("statsInitialData", JSON.stringify(_statsInitialDataList))
        }
        // localStorage.setItem("statsInitialData", JSON.stringify(null))
    },[initialLocalData])

    const handleInitialData = async () => {
        const statsInitialData = await JSON.parse(localStorage.getItem("statsInitialData"));
        const initialDataIndex = statsInitialData?.length > 0 ? statsInitialData?.findIndex(e=>e.matchAccessKey === gridData?.matchAccessKey) : -1
        if (initialDataIndex >= 0) {
            setInitialLocalData(statsInitialData[initialDataIndex])
            statsInitialData[initialDataIndex].playersA && setPlayersA(statsInitialData[initialDataIndex].playersA)
            statsInitialData[initialDataIndex].playersB && setPlayersB(statsInitialData[initialDataIndex].playersB)
            statsInitialData[initialDataIndex].activePlayersA && setActivePlayersAContext(statsInitialData[initialDataIndex].activePlayersA)
            statsInitialData[initialDataIndex].activePlayersB && setActivePlayersBContext(statsInitialData[initialDataIndex].activePlayersB)
            statsInitialData[initialDataIndex].currentRallyContext && setCurrentRallyContext(statsInitialData[initialDataIndex].currentRallyContext)
            statsInitialData[initialDataIndex].rotationA && setRotationA(statsInitialData[initialDataIndex].rotationA)
            statsInitialData[initialDataIndex].rotationB && setRotationB(statsInitialData[initialDataIndex].rotationB)
        } else {
            setInitialLocalData({matchAccessKey: gridData?.matchAccessKey})
            setPlayersA(gridData?.playersTeamA?.map((e)=>{
                var color = '';
                if (e.position == 4) color = "#0dc61d"
                if (e.position == 1) color = "#3af25b"
                if (e.position == 3) color = "#8398c4"
                if (e.position == 6) color = "#323035"
                if (e.position == 2) color = "#87663b"
                if (e.position == 5) color = "#b0dee5"
                return (
                    {
                        ...e,
                        color,
                        courtPosition: Number(e.position),
                        label: Number(e.number),
                    }
                )
            }))
            setPlayersB(gridData?.playersTeamB?.map((e)=>{
                var color = '';
                if (e.position == 4) color = "#0dc61d"
                if (e.position == 1) color = "#3af25b"
                if (e.position == 3) color = "#8398c4"
                if (e.position == 6) color = "#323035"
                if (e.position == 2) color = "#87663b"
                if (e.position == 5) color = "#b0dee5"
                return (
                    {
                        ...e,
                        color,
                        courtPosition: Number(e.position),
                        label: Number(e.number),
                    }
                )
            }))
        }
    }

    const handleGetMatchByAccessKey = (access_key) => {
        getMatchByAccessKey(access_key).then((res)=>{
            setGridData(res)
        })
    }

    const handleRotationA = (_rotation) => {
        if (enabledAutoRotationContext) {
            setRotateA(true)
            _rotation = _rotation ? _rotation : 1
            const newRotation = _rotation + 1;
            newRotation === 7 ? setRotationA(1) : setRotationA(newRotation)
        }
    }

    const handleRotationB = (_rotation) => {
        if (enabledAutoRotationContext) {
            setRotateB(true)
            _rotation = _rotation ? _rotation : 1
            const newRotation = _rotation + 1;
            newRotation === 7 ? setRotationB(1) : setRotationB(newRotation)
        }
    }

    const changePlayerPosition = (_pos) => {
        if (rotationA && rotationB && _pos){
            var newPosition = _pos - 1
            if (newPosition === 0) 
                newPosition = 6 
            else if (newPosition >= 7)
                newPosition = _pos
            return newPosition
        }
    }

    useEffect(()=>{
        if (!rotateA) return
        if (playersA) {
            var tempPlayers = [...playersA]
            setPlayersA(tempPlayers.map((e)=>{
                return (
                    {
                        ...e,
                        position: changePlayerPosition(e.position),
                    }
                )
            }))
        }
        if (activePlayersAContext) {
            var tempPlayersActiveContext = [...activePlayersAContext]
            setActivePlayersAContext(tempPlayersActiveContext.map((e)=>{
                return (
                    {
                        ...e,
                        position: changePlayerPosition(e.position),
                    }
                )
            }))
        }
        setRotateA(false)
    },[rotateA])

    useEffect(()=>{
        if (!rotateB) return
        if (playersB){
            var tempPlayers = [...playersB]
            setPlayersB(tempPlayers.map((e)=>{
                return (
                    {
                        ...e,
                        position: changePlayerPosition(e.position),
                    }
                )
            }))
        }
        if (activePlayersBContext) {
            var tempPlayersActiveContext = [...activePlayersBContext]
            setActivePlayersBContext(tempPlayersActiveContext.map((e)=>{
                return (
                    {
                        ...e,
                        position: changePlayerPosition(e.position),
                    }
                )
            }))
        }
        setRotateB(false)
    },[rotateB])

    return (
        <StatisticsContext.Provider
        value={
            {
                positions,
                gridData, setGridData,
                handleGetMatchByAccessKey,
                handleRotationA, handleRotationB,
                rotationA, setRotationA, 
                rotationB, setRotationB,
                rotateA, rotateB, setRotateA, setRotateB, 
                showSelectPosition, setShowSelectionPosition,
                playersA, setPlayersA,
                playersB, setPlayersB,
                activeTeam, setActiveTeam,
                currentRallyContext, setCurrentRallyContext,
                gridLayout, setGridLayout,
                currentActionRefContext,
                setMatchContext, setSetMatchContext,
                enabledAutoRotationContext, setEnabledAutoRotationContext,
                activePlayersAContext, setActivePlayersAContext,
                activePlayersBContext, setActivePlayersBContext,
                speachMode, setSpeachMode,
                initialLocalData, setInitialLocalData,
                handleInitialData
            }}
        >
            {children}
        </StatisticsContext.Provider>
    )
}

export default StatisticsContext