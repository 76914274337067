import React, { useContext, useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'
import AuthContext from '../context/AuthContext/AuthContext'
import PracticeGoalsComponent from './PracticeGoalsComponent'
import TeamAutocompleteComponent from './TeamAutocompleteComponent/TeamAutocompleteComponent'
import { listGoalsByUserAccessKey } from '../services/PracticeService'

function DashboardGoals() {
    const {userAuth} = useContext(AuthContext)
    const [practiceGoals, setPracticeGoals] = useState()
    const [practiceTotalDuration, setPracticeTotalDuration] = useState(0)
    const getDaysDiff = (days) => {
        const now = new Date();
        const weekAgo = new Date(now.getTime() + days * 24 * 60 * 60 * 1000);
        weekAgo.setHours(0, 0, 0, 0)
        return weekAgo;
    };
    const [startDate, setStartDate] = useState(getDaysDiff(-7))
    const [endDate, setEndDate] = useState(getDaysDiff(7))
    const [teamSelected, setTeamSelected] = useState()
    const [modalIsOpen, setModalIsOpen] = useState(false)

    useEffect(()=>{
        listMyGoals()
    },[])

    const listMyGoals = () => {
        listGoalsByUserAccessKey(userAuth?.user?.access_key, startDate, endDate, teamSelected).then((response)=>{
            setPracticeGoals(response.data)
            setPracticeTotalDuration(response.total_duration)
        })
        setModalIsOpen(false)
    } 

    const handleCancelFilter = () => {
        setModalIsOpen(!modalIsOpen)
    }

    const handleModalIsOpen = () => {
        setModalIsOpen(!modalIsOpen)
    }

    const footer = () => {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={handleCancelFilter} className="p-button-text" />
                <Button label="Apply" icon="pi pi-check" onClick={listMyGoals} autoFocus />
            </div>
        )
    }

    return (
        <div>
            <div className='flex flex-column gap-2'>
                <Button  onClick={handleModalIsOpen} icon='pi pi-search' label={'Search Practices'} className='w-max' />

                <div>
                    Practices total duration: <span className='font-bold'>{practiceTotalDuration} min</span>
                </div>

                {(practiceGoals && practiceTotalDuration) ? 
                <PracticeGoalsComponent drillsSelected={practiceGoals} practiceTotalDuration={practiceTotalDuration} /> : ''}
            </div>       

            <Dialog className='w-11 md:w-8' header="Filter" visible={modalIsOpen} onHide={() => setModalIsOpen(false)}
                footer={footer}>
                <div className='flex gap-2 justify-content-between align-items-end flex-wrap'>
                    <div className='flex flex-column gap-1 w-full lg:w-15rem'>
                        <div className='text-sm'>Team</div>
                        <TeamAutocompleteComponent setTeam={(e)=>setTeamSelected(e)} team={teamSelected} />
                    </div>
                    <div className='flex flex-column gap-1'>
                        <div className='text-sm'>Start Date</div>
                        <Calendar className='w-8rem' value={startDate} onChange={(e)=>setStartDate(e.value)} />
                    </div>
                    <div className='flex flex-column gap-1'>
                        <div className='text-sm'>End Date</div>
                        <Calendar className='w-8rem' value={endDate} onChange={(e)=>setEndDate(e.value)} />
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default DashboardGoals