import { toast } from 'react-toastify';
import axios from 'axios';

export const newDrill = async (title, technicalGoals, taticalGoals, physicalGoals, playerPositions, 
    duration, media_url, description, user_access_key, share_level, ages, mainGoals) =>{
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/drill/create`, 
            {title, technicalGoals, taticalGoals, physicalGoals, playerPositions, 
                duration, media_url, description, user_access_key, share_level, ages, mainGoals})
        .then((response)=>{
            toast.success(`Drill ${title} created!`)
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('An error ocurred.')
    }
}

export const updateDrillService = async (access_key, title, technicalGoals, taticalGoals, physicalGoals, playerPositions, 
    duration, media_url, description, user_access_key, share_level, ages, mainGoals) =>{
    try {
        return await axios.put(`${process.env.REACT_APP_API_URL}/api/drill/update`, 
            {access_key, title, technicalGoals, taticalGoals, physicalGoals, playerPositions, 
                duration, media_url, description, user_access_key, share_level, ages, mainGoals})
        .then((response)=>{
            toast.success(`Drill ${title} updated!`)
            return true
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('An error ocurred.')
    }
}

export const listAllVolleyballSkills = async () =>{
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/drill/list_all_skills`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const listAllTaticalGoals = async () =>{
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/drill/list_all_tatical`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const listAllTechnicalGoals = async () =>{
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/drill/list_all_technical`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const listAllPhysicalGoals = async () =>{
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/drill/list_all_physical`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const listAllMainGoals = async () =>{
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/drill/list_all_main`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const listAllAges = async () =>{
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/drill/list_all_ages`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}


export const listDrillByName = async (name = '') => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/drill/list_by_name/${name}`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const listDrillByUserAccessKey = async (access_key, title, playerPositionsSelected,
    taticalGoalsSelected, technicalGoalsSelected, physicalGoalsSelected, agesSelected, page, isFavorite, createdBy, mainGoalsSelected) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/drill/list_by_user_access_key/`,{access_key, title, playerPositionsSelected,
            taticalGoalsSelected, technicalGoalsSelected, physicalGoalsSelected, agesSelected, page, isFavorite, createdBy, mainGoalsSelected})
        .then((response)=>{
            return response ? response.data : false
        }).catch((error)=>{
            error?.response?.data && toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('A server error ocurred.')
    }
}

export const getDrillDetailsService = async (access_key, user_access_key) => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/drill/get_drill_by_access_key/${access_key}/${user_access_key}`)
        .then((response)=>{
            return response.data[0]
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('A server error ocurred.')
    }
}

export const listMostUsedDrillsService = async (user_access_key, page) => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/drill/list_most_used/${user_access_key}/${page}`)
        .then((response)=>{
            return response?.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('A server error ocurred.')
    }
}

export const deleteDrillService = async (access_key) => {
    try {
        return await axios.delete(`${process.env.REACT_APP_API_URL}/api/drill/delete_drill/${access_key}`)
        .then((response)=>{
            toast.success('Drill deleted successfuly!')
            return true
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('A server error ocurred.')
    }
}

export const handleDrillFavouriteService = async (user_access_key, drill_access_key) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/drill/handle_drill_favorites`,{user_access_key, drill_access_key})
        .then((response)=>{
            return true
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        console.log(error)
        toast.error('A server error ocurred.')
    }
}


