import { toast } from 'react-toastify';
import axios from 'axios';

export const translateService = async (text, language) =>{
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/util/translate`, 
            {text, language})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('An error ocurred.')
    }
}

export const listAllTipsService = async (text, language) =>{
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/util/listTips`, 
            {text, language})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('An error ocurred.')
    }
}

