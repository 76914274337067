import React from 'react'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'
import MyMatches from './MyMatches'

function MyStatsHome() {
    return (
        <AppBodyComponent activeMenuNumber={8}>
            <MyMatches />
        </AppBodyComponent>
    )
}

export default MyStatsHome