import React, { useContext, useEffect, useState } from 'react'
import StatisticsContext from '../StatisticsContext'
import { Button } from 'primereact/button'
import { PiArrowClockwise, PiArrowsLeftRight } from 'react-icons/pi'

function MiniCourtRotationComponent({teamAorB}) {
    const {gridData, activePlayersAContext, setActivePlayersAContext, activePlayersBContext, setActivePlayersBContext, 
        handleRotationA, handleRotationB, rotationA, rotationB, playersA, playersB, setPlayersA, setPlayersB} = useContext(StatisticsContext)
    const [_activePlayersContext, _setActivePlayersContext] = useState([])
    
    const getPlayer = (position) => {
        let index = -1
        if (teamAorB === 1){
            index = activePlayersAContext?.findIndex(e=>Number(e.position) === position)
            return activePlayersAContext[index]
        } else if (teamAorB === 2){
            index = activePlayersBContext?.findIndex(e=>Number(e.position) === position)
            return activePlayersBContext[index]
        }
        return null
    }

    useEffect(()=>{
        if (teamAorB === 1 && activePlayersAContext){
            _setActivePlayersContext([...activePlayersAContext])
        } else if (teamAorB === 2 && activePlayersBContext){
            _setActivePlayersContext([...activePlayersBContext])
        }
    },[activePlayersAContext, activePlayersBContext, teamAorB])

    const showPlayerInfo = (_player) => {
        return (_player && _player.length > 0) ? _player[0].number : ' - ' 
    }

    const updatePlayers = (_activePlayersContext) => {
        const _players = teamAorB === 1 ? [...playersA] : [...playersB]
        const _activePlayers =[..._activePlayersContext]
        for (let index = 0; index < _activePlayers.length; index++) {
            const element = _activePlayers[index];
            const playerIndex = _players.findIndex(e=>e.player.id === element.player.id)
            _players[playerIndex].position = playerIndex > -1 ? element.position : null
        }
        teamAorB === 1 ? setPlayersA(_players) : setPlayersB(_players)
    }

    const subLiberoMiddle = () => {
        const _activePlayers = teamAorB === 1 ? [...activePlayersAContext] : [...activePlayersBContext]

        const findActivePlayers = (_skill, _positions) => {
            return _activePlayers.findIndex(e=>Number(e.player.skill) === _skill && _positions.includes(e.position))
        }

        const swapPositionsActivePlayers = (_idxPlayer1, _idxPlayer2) => {
            if (_idxPlayer1 > -1 && _idxPlayer2 > -1) {
                const tempPosition = _activePlayers[_idxPlayer1].position;
                _activePlayers[_idxPlayer1].position = _activePlayers[_idxPlayer2].position;
                _activePlayers[_idxPlayer2].position = tempPosition;
                return true; // Successfully swapped
            }
            return false; // No swap occurred
        }

        // Try swapping libero in front row with middle in back row
        let liberoFrontRowIndex = findActivePlayers(14, [4, 3, 2]); // Libero in front row
        let middleOutIndex = findActivePlayers(9, [8]); // Middle out
        if (swapPositionsActivePlayers(liberoFrontRowIndex, middleOutIndex)) {
            teamAorB === 1 ? setActivePlayersAContext(_activePlayers) : setActivePlayersBContext(_activePlayers);
            updatePlayers(_activePlayers)
            return;
        }

        let liberoOutIndex = findActivePlayers(14, [8]); // Libero in back row
        let middleBackRowIndex = findActivePlayers(9, [1, 6, 5]); // Middle in back row
        if (swapPositionsActivePlayers(liberoOutIndex, middleBackRowIndex)) {
            teamAorB === 1 ? setActivePlayersAContext(_activePlayers) : setActivePlayersBContext(_activePlayers);
            updatePlayers(_activePlayers)
            return;
        }

        let liberoBackRowIndex = findActivePlayers(14, [1, 6, 5]); // Libero in back row
        if (swapPositionsActivePlayers(liberoBackRowIndex, middleOutIndex)) {
            teamAorB === 1 ? setActivePlayersAContext(_activePlayers) : setActivePlayersBContext(_activePlayers);
            updatePlayers(_activePlayers)
            return;
        }

    }

    const subSixTwo = () => {
        const _activePlayers = teamAorB === 1 ? [...activePlayersAContext] : [...activePlayersBContext]

        const findActivePlayers = (_skill, _positions) => {
            return _activePlayers.findIndex(e=>Number(e.player.skill) === _skill && _positions.includes(e.position))
        }

        const activeSetterFrontRowIndex = findActivePlayers(12, [4, 3, 2]); 
        const activeOppositeOutIndex = findActivePlayers(11, [10]); 
        const activeSetterOutIndex = findActivePlayers(12, [9]);
        const activeOppositeBackRowIndex = findActivePlayers(11, [1, 6, 5]);
        if (activeSetterFrontRowIndex > -1 && activeOppositeOutIndex > -1 && activeSetterOutIndex > -1 && activeOppositeBackRowIndex > -1) {
            const activeSetterFrontRowPostiion = _activePlayers[activeSetterFrontRowIndex].position
            _activePlayers[activeSetterFrontRowIndex].position = 9
            _activePlayers[activeOppositeOutIndex].position = activeSetterFrontRowPostiion

            const activeOppositeBackRowPostiion = _activePlayers[activeOppositeBackRowIndex].position
            _activePlayers[activeOppositeBackRowIndex].position = 10
            _activePlayers[activeSetterOutIndex].position = activeOppositeBackRowPostiion
        }
        teamAorB === 1 ? setActivePlayersAContext(_activePlayers) : setActivePlayersBContext(_activePlayers);
        updatePlayers(_activePlayers)
    }

    const handleRotation = () => {
        if (teamAorB === 1)
            handleRotationA(rotationA)
        else 
            handleRotationB(rotationB)
    }
    
    return (
        _activePlayersContext && 
        <div className={`flex gap-2 justify-content-between flex-1 p-2 border-round ${teamAorB === 2 ? 'bg-red-200' : 'bg-blue-200'}`}>
            <div className={`flex justify-content-between flex-column gap-1`}>
                <div>{teamAorB === 1 ? gridData.teamA?.name : gridData.teamB?.name}</div>
                <div className='flex gap-1'>
                    <Button size='small' onClick={handleRotation} className='p-2 bg-gray-500 border-500'>
                        <PiArrowClockwise className='-rotate-90' size={24} />
                    </Button>
                    <div className='flex flex-column gap-1'>
                        <div>Rotation</div>
                        <div className={'flex align-items-center gap-2 font-bold'}>{teamAorB === 1 ? rotationA : rotationB}</div>
                    </div>
                </div>
            </div>
            <div className='flex gap-1 align-content-center'>
                <div className='flex flex-column w-6rem'>
                    <div className='flex h-3rem'>
                        <div className='col-4 border-1 flex align-items-center justify-content-center h-full'>
                            {showPlayerInfo(_activePlayersContext.filter(e=>Number(e.position) === 4))}
                        </div>
                        <div className='col-4 border-1 flex align-items-center justify-content-center h-full'>
                            {showPlayerInfo(_activePlayersContext.filter(e=>Number(e.position) === 3))}
                        </div>
                        <div className='col-4 border-1 flex align-items-center justify-content-center h-full'>
                            {showPlayerInfo(_activePlayersContext.filter(e=>Number(e.position) === 2))}
                        </div>
                    </div>
                    <div className='flex h-3rem'>
                        <div className='col-4 border-1 flex align-items-center justify-content-center h-full'>
                            {showPlayerInfo(_activePlayersContext.filter(e=>Number(e.position) === 5))}
                        </div>
                        <div className='col-4 border-1 flex align-items-center justify-content-center h-full'>
                            {showPlayerInfo(_activePlayersContext.filter(e=>Number(e.position) === 6))}
                        </div>
                        <div className='col-4 border-1 flex align-items-center justify-content-center bg-bluegray-300 h-full'>
                            {showPlayerInfo(_activePlayersContext.filter(e=>Number(e.position) === 1))}
                        </div>
                    </div>
                </div>
                <div className='flex flex-column justify-content-between'>
                    <div className='flex gap-1'>
                        <Button onClick={subLiberoMiddle} size='small' className='p-2 w-max h-min'><PiArrowsLeftRight size={28} /></Button>
                        <div className='flex flex-column w-full justify-content-center'>
                            <div className='font-bold'>
                                Subs
                            </div>
                            <div className='flex justify-content-between'>
                                <div className='flex flex-1 flex-nowrap mr-2'>Lib/Mid</div>
                                <div className='flex flex-0 font-bold'>{showPlayerInfo(_activePlayersContext.filter(e=>Number(e.position) === 8))}</div>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-1'>
                        <Button onClick={subSixTwo} size='small' className='p-2 flex justify-content-center'><PiArrowsLeftRight size={28} /></Button>
                        <div className='flex flex-column w-full justify-content-center'>
                            <div className='flex justify-content-between'>
                                <div className='flex flex-1 flex-nowrap'>6x2 Setter</div>
                                <div className='flex flex-0 font-bold'>{showPlayerInfo(_activePlayersContext.filter(e=>Number(e.position) === 9))}</div>
                            </div>
                            <div className='flex justify-content-between'>
                                <div className='flex flex-1 flex-nowrap'>6x2 Oppo</div>
                                <div className='flex flex-0 font-bold'>{showPlayerInfo(_activePlayersContext.filter(e=>Number(e.position) === 10))}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MiniCourtRotationComponent