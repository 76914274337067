import React, { useContext, useEffect, useState } from 'react'
import {confirmDialog} from 'primereact/confirmdialog'
import AuthContext from '../../context/AuthContext/AuthContext'
import { listCoachesByName, listUsersByName, newUser } from '../../services/UserService'
import { AutoComplete } from "primereact/autocomplete";
import { PiWarningCircle } from 'react-icons/pi';
import { toast } from 'react-toastify';

function UserAutocompleteComponent({user, setUser, size='', invalid = false, canCreate = true, 
    placeholder=`Enter the player's name.`, searchCoaches = false}) {
    const {userAuth, registerPlayer} = useContext(AuthContext)
    const [users, setUsers] = useState([])
    const [userSelected, setUserSelected] = useState(null)
    const [filteredUsers, setFilteredUsers] = useState(null)
    const [name, setName] = useState('')
    const [hideValidate, setHideValidate] = useState(true)

    useEffect(() => {
        name.length > 2 && handleListUsersByName(name);
    }, [name]);

    useEffect(()=>{
        user && setUserSelected(user?.name)
    },[user])

    useEffect(()=>{
        if (users?.length){
            setFilteredUsers(users);
        } else {
            canCreate ? setFilteredUsers([{
                access_key: 0,
                name: 'Create '+ name,
            }]) : 
            setFilteredUsers([])
        }
    },[users])
    
    const handleListUsersByName = async (_name = '') => {
        searchCoaches ? setUsers(await listCoachesByName(name)) 
        : setUsers(await listUsersByName(name, userAuth?.user?.access_key))
    }

    const search = async (user) => {
        var _name = user.query.charAt(0).toUpperCase() + user.query.slice(1)
        await setName(_name)
    }

    const handleUserOnChange = async (e) => {
        if (typeof e.value === "object"){
            if (e.value.access_key === 0){
                confirmDialog({
                    message: `Do you want to create the player ${name}?`,
                    header: 'Confirm?',
                    icon: <PiWarningCircle />,
                    defaultFocus: 'accept',
                    accept,
                    reject
                });
            } else {
                await setUserSelected(e.value.name)
                await setUser(e.value)
                await setHideValidate(true)
            }
        } else {
            await setUserSelected(e.value)
        }
    }

    const accept = () => {
        const nameArray = name?.split(' ')
        if (nameArray.length !== 2) {
            toast.error('Inform only one first and last names.')
            return
        }
        if (name && userAuth?.user?.access_key){
            registerPlayer({
                first_name: nameArray[0], 
                last_name: nameArray[1], 
                email: nameArray[0].toLowerCase()+(nameArray[1] ? nameArray[1].toLowerCase() : '')+'@email.com'},
                userAuth?.user?.access_key).then(async(player_id)=>{
                if (player_id){
                    await setUser({player_id, name})
                    await setUserSelected(name)
                    await setHideValidate(true)
                }
            });
        }
    }

    const reject = async () => {
        
    }

    const itemTemplate = (item) => {
        return(item?.name)
    }

    return (
        <>
            <AutoComplete 
                onClear={()=>setUser()}
                emptyMessage='not found'
                showEmptyMessage={true}
                className={`flex ${size} ${invalid && 'p-invalid'}`}
                placeholder={placeholder}
                inputStyle={{width: '100%'}} style={{width: '100%'}}
                minLength={3}
                field="name" 
                value={userSelected} 
                suggestions={filteredUsers} 
                completeMethod={search} 
                onChange={handleUserOnChange} 
                itemTemplate={itemTemplate}
            />
        </>
    )
}

export default UserAutocompleteComponent