import { useEffect } from 'react';

const GoogleAdComponent = ({slot}) => {
    useEffect(() => {
        // Only initialize if not already done
        if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
          window.adsbygoogle = window.adsbygoogle || [];
          window.adsbygoogle.push({});
        }
      }, []); // Empty dependency array ensures it runs once
    
      return (
        <ins className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-1429522962657868"
          data-ad-slot={slot}
          data-ad-format="auto"
          data-full-width-responsive="true"
        />
      );
};

export default GoogleAdComponent;