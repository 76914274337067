import React, { useState } from 'react'
import { PiArrowSquareOut, PiEyeSlash } from 'react-icons/pi'
import { FacebookEmbed, InstagramEmbed, TikTokEmbed, YouTubeEmbed } from 'react-social-media-embed' 

function SocialMediaEmbedComponent({media_url=''}) {
    const [error, setError] = useState(false)

    if (error) {
        return (
            media_url ? <a href={media_url} target='_blank' rel='noreferrer' className='font-bold text-primary'>click here <PiArrowSquareOut /></a> : 'no media'
        )
    } else {
        return (
            media_url?.includes('instagram') ? <InstagramEmbed onError={()=>setError(true)} url={media_url} width={328} /> : 
            media_url?.includes('tiktok') ? <TikTokEmbed onError={()=>setError(true)} url={media_url} width={328} /> : 
            media_url?.includes('facebook') ? <FacebookEmbed onError={()=>setError(true)} url={media_url} width={328} /> : 
            (media_url?.includes('youtu.be') || media_url?.includes('youtube')) ? <YouTubeEmbed onError={()=>setError(true)} url={media_url} width={328} />
            : 
            <div className='flex flex-column align-items-center gap-2 m-4 font-bold text-2xl'>
                <PiEyeSlash size={34} />
                No media
            </div>
        )
    }
}

export default SocialMediaEmbedComponent