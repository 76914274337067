import React, { useContext, useEffect, useState } from 'react'
import { Checkbox } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'
import { Divider } from 'primereact/divider'
import { InputNumber } from 'primereact/inputnumber'
import { updateMatchPlayerNumber } from '../../../../../services/StatsService'
import StatisticsContext from '../../StatisticsContext'
import MiniCourtRotationComponent from '../MiniCourtRotationComponent'
import { Dropdown } from 'primereact/dropdown'

function ManageTeamButtonsComponent({players, setPlayers, activePlayersContext, setActivePlayersContext, rotation, teamAorB}) {

    const {gridData} = useContext(StatisticsContext)
    const skills = [
        {label: 'Middle', value: 9},
        {label: 'Outside', value: 10},
        {label: 'Opposite', value: 11},
        {label: 'Libero/DS', value: 14},
        {label: 'Setter', value: 12},
    ]

    const [freePosition, setFreePosition] = useState(0)

    const handlePlayerBoxColor = (skill) => {
        if (skill === 10) return 'border-blue-400 bg-blue-100' 
        if (skill === 11) return 'border-bluegray-400 bg-bluegray-100'
        if (skill === 9) return 'border-yellow-400 bg-yellow-100'
        if (skill === 14) return 'border-purple-400 bg-purple-100'
        if (skill === 12) return 'border-pink-400 bg-pink-100'
    }

    const findNextAvaiablePosition = (_players) => {
        let _freePosition = 0
        for (let position = 1; position <= 10; position++) {
            if (position === 7) continue // can't be 7, lower than seven rotate, higher than 7 are players to sub
            const indexPosition = _players.findIndex(e => Number(e.position) === position);
            if (indexPosition === -1) {
                _freePosition = position;
                break;
            }
        }
        setFreePosition(_freePosition)
        return _freePosition
    }

    useEffect(()=>{
        findNextAvaiablePosition(activePlayersContext)
    },[activePlayersContext])

    const handleActivePlayers = (element) => {
        // Create new copies of the state to avoid mutation
        const _activePlayers = [...activePlayersContext];
        const _players = [...players];
    
        const playerIdx = _players.findIndex(player => Number(player.player.id) === Number(element.value.player.id));
        if (playerIdx === -1) return
        const player = _players[playerIdx];
        let updatedPlayer = null
        
        // If player is checked
        if (element.checked) {
            if (!player?.position && !player?.courtPosition) {
                let _position = 0;
                _position = findNextAvaiablePosition(_players)
    
                // Update player position
                updatedPlayer = { ...player, position: _position, courtPosition: _position };
            } else {
                // If already has a position, clear it
                updatedPlayer = { ...player, position: '', courtPosition: '' };
            }
            _players[playerIdx] = updatedPlayer;
            _activePlayers.push(updatedPlayer);
        } else {
            // If unchecked, remove from active players
            const indexToRemove = _activePlayers.findIndex(e=> e.player.id === element.value.player.id);
            if (indexToRemove !== -1) {
                _activePlayers.splice(indexToRemove, 1);
            }
            // If already has a position, clear it
            updatedPlayer = { ...player, position: '', courtPosition: '' };
            _players[playerIdx] = updatedPlayer;
        }
        setPlayers([..._players]); // Use a new array to set state
    
        setActivePlayersContext([..._activePlayers]);
    };

    const handleChangePlayerNumber = (_value, _access_key) => {
        let _activePlayers = [...activePlayersContext]
        let _players = [...players]

        const _activeIndex = _activePlayers.findIndex(e=>e.player.access_key === _access_key)
        if (_activeIndex >= 0){
            _activePlayers[_activeIndex].number = Number(_value)
            _activePlayers[_activeIndex].label = Number(_value)
            console.log(_activePlayers[_activeIndex])
            setActivePlayersContext(_activePlayers)
        }

        const _index = _players.findIndex(e=>e.player.access_key === _access_key)
        if (_index >= 0){
            _players[_index].number = Number(_value)
            _players[_index].label = Number(_value)
            console.log(_players[_index])
            setPlayers(_players)
        }
    }
    
    const handleSavePlayerNumber = (match_id, player_id, number) => {
        updateMatchPlayerNumber(match_id, player_id, Number(number))
    }

    const handlePositionMessage = () => {
        if (freePosition < 7){
            return 'Select the player in position '+freePosition
        } else {
            if (freePosition === 8)
                return 'Select the Libero.'
            else if (freePosition === 9)
                return 'Select the Setter to 6x2.'
            else if (freePosition === 10)
                return 'Select the Opposite to 6x2.'
        }
    }

    const handleChangeSkill = (skillValue, _playerId) => {
        const _players = [...players]
        const indexPlayer = _players.findIndex(e=>e.player.id === _playerId)
        if (indexPlayer > -1) _players[indexPlayer].player.skill = skillValue
        setPlayers(_players) 
        
        const _activePlayers =[...activePlayersContext]
        const indexActivePlayer = _activePlayers.findIndex(e=>e.player.id === _playerId)
        if (indexActivePlayer > -1) _activePlayers[indexActivePlayer].player.skill = skillValue        
        setActivePlayersContext(_activePlayers)
    }

    const buttonLayout = (skill) => {
        return (players?.filter(e=>e.player.skill === skill).map((element, key)=>(
            <div className={`flex flex-column justify-content-between gap-1 p-1 md:p-2 border-2 border-round w-11rem ${handlePlayerBoxColor(element.player.skill)}`}>
                <div className='text-sm flex flex-1 gap-2 align-items-center font-bold'>
                    <Checkbox value={element} 
                    checked={activePlayersContext?.findIndex(e=> e?.player?.id === element?.player?.id) >= 0} 
                    onChange={handleActivePlayers} />
                    <div className='white-space-nowrap overflow-hidden text-overflow-ellipsis'>
                        {element.player.first_name} {element.player.last_name}
                    </div>
                </div>
                <div className='flex justify-content-between align-items-center'>
                    <span className='text-lg'>Player #</span>
                    <InputNumber inputClassName='w-3rem md:w-4rem text-xs md:text-base h-2rem md:h-3rem' onBlur={(e)=>handleSavePlayerNumber(gridData.matchId, element.player.id, e.target.value)} 
                        value={element.number} onChange={(e)=>handleChangePlayerNumber(e.value, element.player.access_key)} />
                </div>
                <div className='flex pb-1 justify-content-between align-items-center'>
                    <Dropdown className='w-full' options={skills} value={element.player.skill} onChange={(e)=>handleChangeSkill(e.value, element.player.id)} />
                </div>
            </div> 
        )))
    }

    return (
        <div>
            <div className='flex flex-column gap-4 mb-4 justify-content-between'>
                <MiniCourtRotationComponent teamAorB={teamAorB} />
                <div className='text-2xl font-bold text-red-300'>
                    {freePosition === 0 ? 'No positions avaiable.' : handlePositionMessage()}
                </div>
            </div>
            {
                players && 
                <div className='flex flex-column gap-2 justify-content-start'>
                    <div className='flex gap-2 flex-wrap'>{buttonLayout(10)}</div>
                    <div className='flex gap-2 flex-wrap'>{buttonLayout(11)}</div>
                    <div className='flex gap-2 flex-wrap'>{buttonLayout(9)}</div>
                    <div className='flex gap-2 flex-wrap'>{buttonLayout(14)}</div>
                    <div className='flex gap-2 flex-wrap'>{buttonLayout(12)}</div>
                </div>
            }
        </div>
    )
}

export default ManageTeamButtonsComponent