import { Button } from 'primereact/button'
import React from 'react'
import { PiCaretLeftBold, PiCaretRightBold } from 'react-icons/pi'

function PaginatorComponent({page, total, setPage}) {
    return (
        <div className='flex justify-content-center gap-3 flex-nowrap'>
            <div className=''><Button rounded outlined severity="info" icon={<PiCaretLeftBold />} onClick={()=>setPage(page-1)} disabled={page<=1} /></div>
            <div className='align-content-center'>({page} of {total})</div>
            <div className=''><Button rounded outlined severity="info" icon={<PiCaretRightBold />} onClick={()=>setPage(page+1)} disabled={page>=total} /></div>
        </div>

    )
}

export default PaginatorComponent