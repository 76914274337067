import React, { useContext, useEffect, useState } from 'react'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'
import { useNavigate, useParams } from 'react-router-dom'
import { deletePracticeService, getPracticeDetailsService } from '../../../services/PracticeService'
import { PiArticle, PiGlobe, PiHouse, PiLock, PiUsers, PiWarningCircle } from 'react-icons/pi'
import { Button } from 'primereact/button'
import AuthContext from '../../../context/AuthContext/AuthContext'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Dialog } from 'primereact/dialog'
import PracticeGoalsComponent from '../../../components/PracticeGoalsComponent'
import DrillDetails from '../Drill/DrillDetails'
import { DateTime } from 'luxon'
import LoadingSkeletonDetailsComponent from '../../../components/LoadingSkeletonDetailsComponent'
import PracticeFeedbackDetails from './PracticeFeedbackDetails'

function PracticeDetails() {
    const {access_key} = useParams()
    const [practiceDetails, setPracticeDetails] = useState()
    const [practiceDrills, setPracticeDrills] = useState()
    const [drillDetails, setDrillDetails] = useState()
    const navigate = useNavigate()
    const {userAuth} = useContext(AuthContext)
    const [dialogDetailIsOpen, setDialogDetailIsOpen] = useState(false)
    const [dialogFeedbackIsOpen, setDialogFeedbackIsOpen] = useState(false)
    const [practiceTotalDuration, setPracticeTotalDuration] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{
        setPracticeTotalDuration(
            practiceDetails ? 
            practiceDetails.practice_drills?.map(e=>Number(e.duration)).reduce((sum, duration) => sum + Number(duration), 0) 
            : 0)
    },[practiceDrills])

    useEffect(()=>{
        access_key && getPracticeDetails()
    },[access_key])

    const getPracticeDetails = async () => {
        setIsLoading(true)
        access_key && await getPracticeDetailsService(access_key).then(response=>{
            if (response) {
                setPracticeDetails(response.practice)
                setPracticeDrills(response.drills)
            }
        })
        setIsLoading(false)
    }

    const getDrillDetails = (drillAccessKey) => {
        if (practiceDrills && practiceDrills.length > 0){
            const drillIndex = practiceDrills.findIndex(e=>e.access_key === drillAccessKey)
            drillIndex >= 0 ? setDrillDetails(practiceDrills[drillIndex]) : setDrillDetails(null)
            setDialogDetailIsOpen(true)
        }
    }

    const openDrillDetails = (drillAccessKey) => {
        getDrillDetails(drillAccessKey)
    }

    const showIconShareLevel = (share_level) => {
        return(
            share_level === 1 ? <PiLock size={18} /> :
            share_level === 2 ? <PiUsers size={18} /> :
            share_level === 3 ? <PiHouse size={18} /> :
            share_level === 4 ? <PiGlobe size={18} /> : ''
        )
    }

    const deletePractice = () => {
        confirmDialog({
            message: `Do you want to delete the practice?`,
            header: 'Confirm?',
            icon: <PiWarningCircle />,
            defaultFocus: 'accept',
            accept: () => confirmDeletePractice(),
            reject : ()=>{}
        });
    }

    const confirmDeletePractice = () =>{
        deletePracticeService(practiceDetails.access_key).then(response=>{
            navigate(-1)
        })
    }

    const updatePractice = () => {
        navigate('/app/update_practice/'+practiceDetails.access_key)
    }

    const openFeedback = () => {
        setDialogFeedbackIsOpen(true)
    }

 
    
    
    return (
        <AppBodyComponent activeMenuNumber={2}>
            <ConfirmDialog />
            {isLoading ? <LoadingSkeletonDetailsComponent /> : practiceDetails ? 
            <div className='flex flex-column gap-2'>
                {userAuth && 
                <div className='flex justify-content-between gap-2'>
                    <div className="event_detail--actions">
                        <Button onClick={()=>navigate(-1)} outlined size='small' icon='pi pi-angle-left' />
                    </div>
                    {practiceDetails?.user_access_key === userAuth?.user?.access_key && 
                    <div className='flex gap-2'>
                        <div className="event_detail--actions">
                            <Button onClick={openFeedback} size='small' icon='pi pi-comments' />
                        </div>
                        <div className="event_detail--actions">
                            <Button onClick={updatePractice} size='small' icon='pi pi-pencil' />
                        </div>
                        <div className="event_detail--actions">
                            <Button onClick={deletePractice} size='small' icon='pi pi-trash' />
                        </div>
                    </div>}
                </div>}
                <div className='flex gap-2 justify-content-between'>
                    <div className='flex gap-2'>
                        <div className='flex gap-1 flex-wrap'>
                            <span className='font-bold'>Date: </span> {DateTime.fromISO(practiceDetails.date, { zone: 'UTC' }).toFormat('MM/dd/yyyy')}
                        </div>
                    </div>
                    <div className=''>
                        {showIconShareLevel(practiceDetails.share_level)}
                    </div>
                </div>
                <div className='flex gap-2 justify-content-between'>
                    <div className='flex gap-1 flex-wrap'>
                        <span className='font-bold'>Team: </span> {practiceDetails.team_name}
                    </div>
                    <div className='flex gap-1 flex-wrap'>
                        <span className='font-bold'>Duration: </span> {practiceTotalDuration} min
                    </div>
                </div>
                <PracticeGoalsComponent drillsSelected={practiceDrills} practiceTotalDuration={practiceTotalDuration} />
                <div className='font-bold mt-2'>
                    Practice's Drills
                </div>
                {practiceDetails.practice_drills ? practiceDetails.practice_drills.map((drill, key)=>(
                    <div key={key} className='flex flex-column gap-1 mb-2 text-sm md:text-base'>
                        <div className='flex gap-2 justify-content-between align-items-center'>
                            <div className='flex gap-2'>
                                <div className=''>
                                    {drill.drill_order}.
                                </div>
                                <div className='font-bold'>
                                    {drill.title}
                                </div>
                            </div>
                            <div className='flex gap-2 align-items-center'>
                                <div className='white-space-nowrap'>
                                    {drill.duration} min
                                </div>                                    
                                <Button size='small' onClick={()=>openDrillDetails(drill.access_key)} icon='pi pi-info-circle' />
                            </div>
                        </div>
                        <div className='flex flex-column gap-1 pl-3'>
                            <div className='flex align-items-center gap-1 font-bold'><PiArticle /> Comments</div>
                            <div dangerouslySetInnerHTML={{__html: drill.comments ? drill.comments.replace(/\n/g, '<br>') : '-'}} />
                        </div>
                    </div>
                )) : '-'}
            </div> : 'Practice not found'}









            <Dialog className='w-11 md:w-8' header="Drill Details" visible={dialogDetailIsOpen} onHide={() => setDialogDetailIsOpen(false)}
            footer={
                <div>
                    <Button label="Close" icon="pi pi-times" onClick={()=>setDialogDetailIsOpen(false)} className="p-button-text" />
                </div>
                }>
                {drillDetails ? 
                <DrillDetails access_key={drillDetails.access_key} canDeleteUpdate={false} /> : 'Drill not found'}
            </Dialog>

            <Dialog className='w-11 md:w-8' header="Practice's Feddback" visible={dialogFeedbackIsOpen} onHide={() => setDialogFeedbackIsOpen(false)}
            footer={
                <div>
                    <Button label="Close" icon="pi pi-times" onClick={()=>setDialogFeedbackIsOpen(false)} className="p-button-text" />
                </div>
                }>
                <PracticeFeedbackDetails access_key={practiceDetails?.access_key} />
            </Dialog>

        </AppBodyComponent>
    )
}

export default PracticeDetails