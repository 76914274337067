import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel'
import React, { useEffect, useState } from 'react'
import { listAllTipsService } from '../services/UtilService';
import { InputText } from 'primereact/inputtext';

function TipsComponent() {

    const [tips, setTips] = useState([]);
    const [tipsOriginal, setTipsOringial] = useState([]);
    const [searchText, setSearchText] = useState('')
    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 4,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '821px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    useEffect(()=>{
        handleListTips()
    },[])

    function shuffleTips(array) {
        for (let i = array?.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]]; // Swap elements
        }
        return array;
      }

    const handleListTips = () => {
        listAllTipsService().then(response=>{
            const responseShuffled = shuffleTips(response)
            setTips(responseShuffled)
            setTipsOringial(responseShuffled)
        })
    }

    const handleSearchText = (_text) => {
        let _tips = [...tipsOriginal]
        _tips = _tips.filter(item => item.title?.includes(_text) || item.text?.includes(_text));
        setSearchText(_text)
        setTips(_tips)
    }

    const itemTemplate = (_tip) => {
        return (
            <div className="border-1 surface-border border-round m-2 text-center py-5 px-3 flex flex-column gap-4">
                <div className="mb-3">
                    <iframe className='w-10 md:w-8 h-25rem' src={_tip.media_url} title={_tip.title} frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" 
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                </div>
                <div className="font-bold text-lg text-center">{_tip.title}</div>
                <p className='text-left'>{_tip.text}</p>
            </div>
        );
    };
    return (
        <div className='flex flex-column gap-2'>
            <div className='font-bold text-4xl'>Tips/Help</div>
            <div>
                <InputText className='w-full' placeholder='Search for a tip/help' value={searchText} onChange={e=>handleSearchText(e.target.value)} />
            </div>
            <Carousel showIndicators={false} circular value={tips} numVisible={3} numScroll={3} responsiveOptions={responsiveOptions} itemTemplate={itemTemplate} />
        </div>
    )
}

export default TipsComponent