import React, { createContext, useEffect, useState } from 'react'
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth"
// import {firebase_auth} from '../../firebase';
import { toast } from 'react-toastify';
import axios from 'axios';
import { firebase_auth } from '../../firebase';
import ReactGA from 'react-ga4'

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [userAuth, setUserAuth] = useState(()=>{
        const userSession = localStorage.getItem("userAuth");
        try{
            return JSON.parse(userSession)
        } catch {
            return null
        }
    })

    useEffect(()=>{
        localStorage.setItem("userAuth", JSON.stringify(userAuth))
    },[userAuth])

    const registerPlayer = async (player, user_access_key) => {
        try {
            return await axios.post(`${process.env.REACT_APP_API_URL}/api/user/register_player`, {...player, user_access_key})
            .then((response)=>{
                toast.success('Player '+player.first_name+' created!')
                return response?.data?.player_id
            }).catch((error)=>{
                toast.error(error.response.data.message)
                return false
            })
        } catch (error) {
            toast.error('An error ocurred.')
        }
    }

    const userRegister = async (user) => {
        try {
            return await axios.post(`${process.env.REACT_APP_API_URL}/api/user/signup`, user)
            .then((response)=>{
                firebase_auth.signInWithEmailAndPassword(process.env.REACT_APP_FIREBASE_USER_EMAIL, process.env.REACT_APP_FIREBASE_USER_PASSWORD)
                setUserAuth(response.data)
                toast.success('Welcome '+user.first_name+'!')
                ReactGA.event({
                    category: "User",
                    action: "Form register",
                    label: user.first_name + " " + user.last_name,
                })
                return true
            }).catch((error)=>{
                toast.error(error.response.data.message)
                ReactGA.event({
                    category: "Error User",
                    action: "Form Register",
                    label: error.response.data.message,
                })
                return false
            })
        } catch (error) {
            ReactGA.event({
                category: "Error User",
                action: "Form Register",
                label: error.message,
            })
            toast.error('An error ocurred.')
        }
    }

    const googleRegister = async (user) => {
        try {
            return await axios.post(`${process.env.REACT_APP_API_URL}/api/user/register_google`, user)
            .then((response)=>{
                firebase_auth.signInWithEmailAndPassword(process.env.REACT_APP_FIREBASE_USER_EMAIL, process.env.REACT_APP_FIREBASE_USER_PASSWORD)
                setUserAuth(response.data)
                toast.success('Welcome '+user.first_name+'!')
                ReactGA.event({
                    category: "User",
                    action: "Google register",
                    label: user.first_name + " " + user.last_name,
                })
                return true
            }).catch((error)=>{
                toast.error(error.response.data.message)
                ReactGA.event({
                    category: "Error User",
                    action: "Form Register",
                    label: error.response.data.message,
                })
                return false
            })
        } catch (error) {
            ReactGA.event({
                category: "Error User",
                action: "Google Register",
                label: error.message,
            })
            toast.error('An error ocurred.')
        }
    }

    const userLogin = async (email, password) => {
        try {
            return await axios.post(`${process.env.REACT_APP_API_URL}/api/user/signin`, {email, password})
            .then((response)=>{
                firebase_auth.signInWithEmailAndPassword(process.env.REACT_APP_FIREBASE_USER_EMAIL, process.env.REACT_APP_FIREBASE_USER_PASSWORD)
                setUserAuth(response.data)
                toast.success('Welcome back '+response.data.user.first_name+'!')
                ReactGA.event({
                    category: "User",
                    action: "Form login",
                    label: response.data.user.first_name + " " + response.data.user.last_name,
                })
                return true
            }).catch((error)=>{
                console.log(error)
                toast.error(error.response.data.message)
                ReactGA.event({
                    category: "Error User",
                    action: "Form Login",
                    label: error.response.data.message,
                })
                return false
            })
        } catch (error) {
            ReactGA.event({
                category: "Error User",
                action: "Form Login",
                label: error.message,
            })
            toast.error('An error ocurred.')
        }
    }

    const googleLogin = async (email) => {
        try {
            return await axios.post(`${process.env.REACT_APP_API_URL}/api/user/signin_google`, {email})
            .then((response)=>{
                firebase_auth.signInWithEmailAndPassword(process.env.REACT_APP_FIREBASE_USER_EMAIL, process.env.REACT_APP_FIREBASE_USER_PASSWORD)
                setUserAuth(response.data)
                toast.success('Welcome back '+response.data.user.first_name+'!')
                ReactGA.event({
                    category: "User",
                    action: "Google login",
                    label: response.data.user.first_name + " " + response.data.user.last_name
                })
                return true
            }).catch((error)=>{
                ReactGA.event({
                    category: "Error User",
                    action: "Google Login",
                    label: error.response.data.message,
                })

                toast.error(error.response.data.message)
                return false
            })
        } catch (error) {
            ReactGA.event({
                category: "Error User",
                action: "Google Login",
                label: error.message,
            })

            toast.error('An error ocurred.')
        }
    }

    const userUpdateProfile = async (userProp) => {
        try {
            return await axios.put(`${process.env.REACT_APP_API_URL}/api/user/update`, userProp)
            .then((response)=>{
                setUserAuth({
                    accessToken: userAuth.accessToken,
                    refreshToken: userAuth.refreshToken,
                    user: response.data                    
                })
                toast.success('Info updated!')
                return true
            }).catch((error)=>{
                toast.error(error.response.data.message)
                return false
            })
        } catch (error) {
            toast.error('An error ocurred.')
        }
    }

    const userUpdateSetting = async (userProp) => {
        try {
            return await axios.put(`${process.env.REACT_APP_API_URL}/api/user/update_setting`, userProp)
            .then((response)=>{
                setUserAuth({
                    accessToken: userAuth.accessToken,
                    refreshToken: userAuth.refreshToken,
                    user: response.data                    
                })
                toast.success('Settings updated!')
                return true
            }).catch((error)=>{
                toast.error(error.response.data.message)
                return false
            })
        } catch (error) {
            toast.error('An error ocurred.')
        }
    }

    const userUpdatePicture = async (access_key, picture_url, picture_name) => {
        try {
            return await axios.put(`${process.env.REACT_APP_API_URL}/api/user/update_picture`, {access_key, picture_url, picture_name})
            .then((response)=>{
                setUserAuth({
                    accessToken: userAuth.accessToken,
                    refreshToken: userAuth.refreshToken,
                    user: response.data                    
                })
                toast.success('Info updated!')
                return true
            }).catch((error)=>{
                toast.error(error.response.data.message)
                return false
            })
        } catch (error) {
            toast.error('An error ocurred.')
        }
    }

    const logOut = async (access_key) => {
        try {
            return await axios.post(`${process.env.REACT_APP_API_URL}/api/user/logout`, {access_key: access_key})
            .then((response)=>{
                // firebase_auth.signOut()
                setUserAuth(null)
                toast.success('See you soon!')
                return true;
            }).catch((error)=>{
                toast.error(error.response.data.message)
                return false
            })
        } catch (error) {
            toast.error('An error ocurred.')
        }
    }

    const deleteUserProfile = async (access_key) => {
        if (access_key){
            try {
                return await axios.delete(`${process.env.REACT_APP_API_URL}/api/user/delete/${access_key}`)
                .then((response)=>{
                    response.data && toast.success('Profile deleted.')
                    ReactGA.event({
                        category: "User",
                        action: "Delete",
                        label: userAuth.user.first_name + " " + userAuth.user.last_name
                    })
                        return true
                }).catch((error)=>{
                    toast.error(error.response.data.message)
                    ReactGA.event({
                        category: "Error User",
                        action: "Delete",
                        label: error.response.data.message,
                    })
                        return false
                })
            } catch (error) {
                toast.error('An error ocurred.')
                ReactGA.event({
                    category: "Error User",
                    action: "Delete",
                    label: error.message,
                })
                return false
            }
        } else {
            return false
        }
    }

    return (
        <AuthContext.Provider
        value={
            {
                userAuth, 
                googleRegister,
                googleLogin,
                // firebase_auth,
                userLogin,
                logOut,
                userRegister,
                userUpdateProfile,
                userUpdatePicture,
                userUpdateSetting,
                deleteUserProfile,
                registerPlayer,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext