import React, { useContext, useEffect, useState } from 'react'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'
import { useNavigate, useParams } from 'react-router-dom'
import { deletePracticeService, getPracticeDetailsService, getPracticeFeedbackService, savePracticeFeedbackService } from '../../../services/PracticeService'
import { PiArticle, PiConfetti, PiGlobe, PiHouse, PiLock, PiStar, PiStarFill, PiStarThin, PiUsers, PiWarningCircle } from 'react-icons/pi'
import { Button } from 'primereact/button'
import AuthContext from '../../../context/AuthContext/AuthContext'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Dialog } from 'primereact/dialog'
import PracticeGoalsComponent from '../../../components/PracticeGoalsComponent'
import DrillDetails from '../Drill/DrillDetails'
import { DateTime } from 'luxon'
import LoadingSkeletonDetailsComponent from '../../../components/LoadingSkeletonDetailsComponent'
import { InputTextarea } from 'primereact/inputtextarea'
import { Rating } from 'primereact/rating'
import { Accordion, AccordionTab } from 'primereact/accordion'

function PracticeFeedbackDetails() {
    const {access_key} = useParams()
    const [practiceDetails, setPracticeDetails] = useState()
    const [practiceDrills, setPracticeDrills] = useState()
    const [drillDetails, setDrillDetails] = useState()
    const [dialogDetailIsOpen, setDialogDetailIsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isCopied, setIsCopied] = useState(false);

    useEffect(()=>{
        access_key && getPracticeDetails()
    },[access_key])

    const getPracticeDetails = async () => {
        setIsLoading(true); // Set loading state to true
    
        try {
            if (access_key) {
                const response = await getPracticeFeedbackService(access_key); // Await the API call
                if (response?.practice?.practice_drills) {

                    const updatedDrills = response.practice.practice_drills.map(e => {
                        const totalRating = e.practice_drill_feedbacks.reduce((sum, feedback) => sum + feedback.rating, 0);
                        const countNoZero = e.practice_drill_feedbacks.filter(e=>e.rating > 0)
                        const averageRating = (totalRating / countNoZero.length || 0).toFixed(1); // Avoid division by zero
                        return {
                            ...e,
                            averageRating,}
                    });
    
                    const updatedPractice = {
                        ...response.practice,
                        practice_drills: updatedDrills,
                    };
    
                    setPracticeDetails(updatedPractice);
                } else {
                    setPracticeDetails(response?.practice || {});
                }
                setPracticeDrills(response?.drills || null);
            }
        } catch (error) {
        } finally {
            setIsLoading(false); // Set loading state to false
        }
    };

    const getDrillDetails = (drillAccessKey) => {
        if (practiceDrills && practiceDrills.length > 0){
            const drillIndex = practiceDrills.findIndex(e=>e.access_key === drillAccessKey)
            drillIndex >= 0 ? setDrillDetails(practiceDrills[drillIndex]) : setDrillDetails(null)
            setDialogDetailIsOpen(true)
        }
    }

    const openDrillDetails = (drillAccessKey) => {
        getDrillDetails(drillAccessKey)
    }

    const copyUrlToClipboard = () => {
        if (!access_key) return
        const url = 'https://leagr.net/app/practice_feedback/'+access_key; // Get the current URL
        navigator.clipboard.writeText(url) // Copy the URL to the clipboard
        .then(() => {
            setIsCopied(true); // Update state to show "Copied!" message
            setTimeout(() => setIsCopied(false), 10000); // Reset after 2 seconds
        })
        .catch((error) => {
        });
    };
    
    
    return (
        <div>
            <div className='mb-2'>
                <Button onClick={copyUrlToClipboard} label={isCopied ? 'Link generated! Paste to share' : 'Generate feedback link'} />
            </div>                
            {isLoading ? <LoadingSkeletonDetailsComponent /> : practiceDetails ? 
            <div className='flex flex-column gap-2'>
                <div className='flex gap-2 justify-content-between'>
                    <div className='flex gap-1 flex-wrap'>
                        <span className='font-bold'>Date: </span> {DateTime.fromISO(practiceDetails.date, { zone: 'UTC' }).toFormat('MM/dd/yyyy')}
                    </div>
                </div>
                <div className='flex gap-2 justify-content-between'>
                    <div className='flex gap-1 flex-wrap'>
                        <span className='font-bold'>Team: </span> {practiceDetails.team_name}
                    </div>
                </div>
                <div className='font-bold mt-2'>
                    Practice's Drills
                </div>
                {practiceDetails.practice_drills ? practiceDetails.practice_drills.map((drill, key)=>(
                    <div key={key} className='flex flex-column gap-1 mb-2 text-sm md:text-base'>
                        <Accordion>
                            <AccordionTab header={
                                <div className='flex gap-2 justify-content-between align-items-center'>
                                    <div className='flex gap-2'>
                                        <div className=''>
                                            {drill.drill_order}.
                                        </div>
                                        <div className='font-bold'>
                                            {drill.title}
                                        </div>
                                    </div>
                                    <div className='flex gap-2 align-items-center'>
                                        <div className='white-space-nowrap'>
                                            <PiStarFill /> {drill.averageRating}
                                        </div>                                    
                                        <Button size='small' onClick={()=>openDrillDetails(drill.access_key)} icon='pi pi-info-circle' />
                                    </div>
                                </div>
                            }>
                                <div className='flex flex-column gap-2'>
                                    {drill?.practice_drill_feedbacks ? drill?.practice_drill_feedbacks.map((feedback, key)=>(
                                        <div key={key} className='flex gap-2 pl-3 flex-wrap'>
                                            <div className='flex flex-column flex-0 gap-1'>
                                                <div className='flex align-items-center gap-1 font-bold'><PiStar /> Rating</div>
                                                <Rating cancel={false} value={feedback.rating} />
                                            </div>
                                            <div className='flex flex-column flex-1 gap-1'>
                                                <div className='flex align-items-center gap-1 font-bold'><PiArticle /> Comments</div>
                                                <span className='w-full text-sm'>{feedback.comment}</span>
                                            </div>
                                        </div>                            
                                    ))
                                    
                                    : 'no feedback'}
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                )) : '-'}
            </div> : 'No feedback to show'}









            <Dialog className='w-11 md:w-8' header="Drill Details" visible={dialogDetailIsOpen} onHide={() => setDialogDetailIsOpen(false)}
                footer={
                    <div>
                        <Button label="Close" icon="pi pi-times" onClick={()=>setDialogDetailIsOpen(false)} className="p-button-text" />
                    </div>
                }>
                {drillDetails ? 
                <DrillDetails access_key={drillDetails.access_key} canDeleteUpdate={false} /> : 'Drill not found'}
            </Dialog>
        </div>
    )
}

export default PracticeFeedbackDetails