import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../context/AuthContext/AuthContext'
import { listMostUsedDrillsService } from '../services/DrillService'
import { Button } from 'primereact/button'
import PaginatorComponent from './PaginatorComponent/PaginatorComponent'
import { useNavigate } from 'react-router-dom'
import { PiWarning } from 'react-icons/pi'

function DashboardMostUsedDrills() {
    const navigate = useNavigate()
    const {userAuth} = useContext(AuthContext)
    const [mostUsedDrills, setMostUsedDrills] = useState()
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [totalPages, setTotalPages] = useState(0)

    useEffect(()=>{
        handleListMostUsedDrills()
    },[])

    useEffect(()=>{
        handleListMostUsedDrills()
    },[page])

    const handleListMostUsedDrills = () => {
        userAuth?.user?.access_key && listMostUsedDrillsService(userAuth?.user?.access_key, page).then((response)=>{
            response?.data && setMostUsedDrills(response.data)
            response?.pages && setTotalPages(response.pages)
            response?.total && setTotal(response.total)
        })
    }

    const getBackGroundColor = (index) => {
        let color = index === 1 ? 'bg-yellow-500' : index === 2 ? 'bg-yellow-400' : index === 3 ? 'bg-yellow-300' : 
        index === 4 ? 'bg-yellow-200' : index === 5 ? 'bg-yellow-100' : ''
        return color
    }


    return (
        <div className='flex flex-column gap-4'>
            <div className='font-bold text-2xl'>My Drills Ranking</div>
            <div className='font-bold'>You created {total} drills</div>
            {(mostUsedDrills && mostUsedDrills.length > 0) ? 
            <div className='flex flex-column gap-2'>
                {mostUsedDrills?.map((item, key)=>(
                    <div key={key} className={`flex gap-2 justify-content-between p-2 border-1 border-round ${getBackGroundColor(item.seq)}`}>
                        <div className='flex text-sm md:text-base gap-2 font-bold w-10'>
                            <span>{item.seq}.{item.title}</span>
                        </div>
                        <div className='flex gap-2'>
                            <div className='flex flex-column gap-1 align-items-center'>
                                <span className='text-xs md:text-sm text-center'>Used overall</span>
                                <span className='font-bold text-center'>{item.most_used}</span>
                            </div>
                            <div className='flex flex-column gap-1 align-items-center'>
                                <span className='text-xs md:text-sm text-center'>Used by me</span>
                                <span className='font-bold text-center'>{item.most_used_by_me}</span>
                            </div>
                        </div>
                    </div>
                ))}
                    <PaginatorComponent page={page} total={totalPages} setPage={setPage} />
            </div> : 
            <div className='flex flex-column align-items-center gap-4'>
                <div className='flex flex-column gap-1 text-lg text-center align-items-center'>
                    <div className='w-max'><PiWarning size='24' /></div>
                    <span>You don't have drills. Create your drill to see your drill's ranking.</span>
                </div>
                <div><Button label='Create my frist drill' size='large' onClick={()=>navigate('/app/create_drill')} /></div>
            </div>}
        </div>
    )
}

export default DashboardMostUsedDrills