import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../../context/AuthContext/AuthContext'
import { useNavigate, useParams } from 'react-router-dom'
import { getPracticeDetailsService, newPractice, updatePracticeService } from '../../../services/PracticeService'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'
import { Button } from 'primereact/button'
import TeamAutocompleteComponent from '../../../components/TeamAutocompleteComponent/TeamAutocompleteComponent'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { PiArrowsDownUp, PiArticle, PiClock } from 'react-icons/pi'
import { Accordion, AccordionTab } from 'primereact/accordion'
import PracticeGoalsComponent from '../../../components/PracticeGoalsComponent'
import DrillListComponent from '../Drill/DrillListComponent'
import DrillDetails from '../Drill/DrillDetails'
import { InputTextarea } from 'primereact/inputtextarea'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { DateTime } from 'luxon'

function PracticeForm() {
    const {userAuth} = useContext(AuthContext)
    const {access_key} = useParams()
    const navigate = useNavigate()
    const [date, setDate] = useState('')
    const share_levels = [
        {value: 1, label: 'Only me'},
        // {value: 2, label: 'My team'},
        // {value: 3, label: 'My club'},
        {value: 4, label: 'Everyone'},
    ]
    const [shareLevelsSelected, setShareLevelsSelected] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [drillsSelected, setDrillsSelected] = useState()
    const [teamId, setTeamId] = useState()
    const [teamSelected, setTeamSelected] = useState()
    const [dialogIsOpen, setDialogIsOpen] = useState(false)
    const [dialogDetailIsOpen, setDialogDetailIsOpen] = useState(false)


    const [drillDetailsAccessKey, setDrillDetailsAccessKey] = useState()
    
    useEffect(()=>{
        loadPractice()
    },[])
    
    const loadPractice = () => {
        if (access_key) {
            getPracticeDetailsService(access_key).then((element)=>{
                setDate(new Date(DateTime.fromISO(element?.practice?.date, { zone: 'UTC' }).toFormat('MM/dd/yyyy')))
                element?.practice?.team_id && setTeamSelected({
                    id: element?.practice?.team_id, access_key: element?.practice?.team_access_key, name: element?.practice?.team_name})
                setTeamId(element?.practice?.team_id)
                setShareLevelsSelected(element?.practice?.share_level)

                const _practiceDrills = element?.practice?.practice_drills ? [...element?.practice?.practice_drills] : []
                let _drills = [...element?.drills]
                _practiceDrills?.forEach(elementDrill=> {
                    const drillsIndex = _drills.findIndex(e=>e.access_key === elementDrill.access_key)
                    if (drillsIndex >= 0) {
                        _drills[drillsIndex].drill_order = elementDrill.drill_order
                        _drills[drillsIndex].duration = elementDrill.duration
                        _drills[drillsIndex].comments = elementDrill.comments
                    } 
                })
                setDrillsSelected(_drills)
            })
        }
    }

    const onSubmit = async () => {
        setIsLoading(true)
        const _selectedDrills = drillsSelected?.length ? drillsSelected : null
        if (!access_key){
            await newPractice(date, teamId, userAuth.user.access_key, shareLevelsSelected, _selectedDrills).then((response)=>{
                response && navigate('/app/practice_list')
            })
        } else {
            await updatePracticeService(date, teamId, access_key, shareLevelsSelected, _selectedDrills).then((response)=>{
                response && navigate('/app/practice_list')
            })
        }
        setIsLoading(false)
    }

    const getDrillDetails = (access_key) => {
        setDrillDetailsAccessKey(access_key)
        setDialogDetailIsOpen(true)
    }

    const addDrill = (_drill) => {
        _drill = {
            ..._drill,
            order: '',
            duration: _drill.duration,
            comments: '',
        }
        drillsSelected ? setDrillsSelected([...drillsSelected, _drill]) : setDrillsSelected([_drill])
    }

    const removeDrill = (_access_key) => {
        const _drillsSelected = [...drillsSelected]
        const index = _drillsSelected?.findIndex(item => item.access_key === _access_key);
        if (index !== -1) {
            _drillsSelected?.splice(index, 1);
            setDrillsSelected(_drillsSelected)
        }
    }

    const checkDrillAdded = (_access_key) => {
        const drillIndex = drillsSelected?.findIndex(e=>e.access_key === _access_key)
        return drillIndex >= 0 ? true : false 
    }
    
    const changeDuration = (_access_key, value) => {
        const _drillsSelected = [...drillsSelected]
        const drillIndex = _drillsSelected?.findIndex(e=>e.access_key === _access_key)
        if (drillIndex >= 0){
            _drillsSelected[drillIndex].duration = value
            setDrillsSelected(_drillsSelected)
        } 
    }
    
    const changeOrder = (_access_key, value) => {
        const _drillsSelected = [...drillsSelected]
        const drillIndex = _drillsSelected?.findIndex(e=>e.access_key === _access_key)
        if (drillIndex >= 0){
            _drillsSelected[drillIndex].drill_order = value
            let newValue = Number(value)
            _drillsSelected.forEach(e=>{
                if (e.drill_order && (Number(e.drill_order) === newValue && e.access_key != _access_key)){
                    const oldValue = Number(e.drill_order)
                    newValue = Number(e.drill_order) + 1
                    e.drill_order = newValue
                } else {
                    e.drill_order = e.drill_order > 0 ? Number(e.drill_order) : ''
                }
            })
            setDrillsSelected(_drillsSelected)
        } 
    }

    const changeComments = (_access_key, value) => {
        const _drillsSelected = [...drillsSelected]
        const drillIndex = _drillsSelected?.findIndex(e=>e.access_key === _access_key)
        if (drillIndex >= 0){
            _drillsSelected[drillIndex].comments = value
            setDrillsSelected(_drillsSelected)
        } 
    }

    const [practiceTotalDuration, setPracticeTotalDuration] = useState(0)
    useEffect(()=>{
        setPracticeTotalDuration(
            drillsSelected ? 
            drillsSelected?.map(e=>Number(e.duration)).reduce((sum, duration) => sum + Number(duration), 0) 
            : 0)
    },[drillsSelected])

    const handleSetTeam = (value) => {
        setTeamId(value.id)
        setTeamSelected(value)
    }

    const handleOpenDialog = () => {
        setDialogIsOpen(true)
    }

    const orderMyDrills = () => {
        if (drillsSelected && drillsSelected?.length > 0){
            let _drillsSelected = [...drillsSelected]
            _drillsSelected?.sort((a,b)=>a.drill_order - b.drill_order)
            setDrillsSelected(_drillsSelected)
        }
    }
    
    return (
        <AppBodyComponent activeMenuNumber={2}>
            <div className='p-2 font-bold text-4xl'>
                {access_key ? 'Update Practice' : `Create new Practice`}
            </div>
        <div className="form--container">
            <div className="form--row">
                <div className='flex flex-column gap-1'>
                    <div className='text-sm flex gap-1 align-items-center'>Date</div> 
                    <Calendar className='flex flex-1' value={date} type='text' placeholder='Practice Date' name='date' onChange={(e)=>setDate(e.target.value)} />
                </div>
                <div className='flex flex-column gap-1'>
                    <div className='text-sm flex gap-1 align-items-center'>Share</div> 
                    <Dropdown placeholder='Share my practice' options={share_levels} value={shareLevelsSelected} onChange={e=>setShareLevelsSelected(e.value)} />
                </div>
            </div>
            <div className="form--row gap-1">
                <div className='text-sm flex gap-1 align-items-center'>Team</div> 
                <TeamAutocompleteComponent setTeam={handleSetTeam} team={teamSelected} />
                <ConfirmDialog />
            </div>
            <div className='flex flex-column gap-2'>
                <Accordion>
                    <AccordionTab header='Practice Goals'>
                        <PracticeGoalsComponent drillsSelected={drillsSelected} practiceTotalDuration={practiceTotalDuration} />
                    </AccordionTab>
                </Accordion>
                <div className='flex flex-column gap-2'>
                    <div className='font-bold text-lg'>Practice Drills</div>
                    <Button icon='pi pi-plus' onClick={handleOpenDialog} label='Add Drill' className='w-max' />
                    <div className='flex gap-2 justify-content-between align-items-center'>
                        <div className='flex flex-wrap gap-1'>Practice Duration: <span className='font-bold'>{practiceTotalDuration} minutes</span></div>
                        <Button className='white-space-nowrap' label='Sort Drills' onClick={orderMyDrills} text icon='pi pi-sort-numeric-down' size='small' />
                    </div>
                </div>
                <Accordion>
                {drillsSelected ? drillsSelected.map((element, key)=>(
                        <AccordionTab key={key} header={
                            <div className='flex flex-column justify-content-between gap-2'>
                                <div>{element.title}</div>
                                <div className='flex gap-2 align-items-center justify-content-between text-500 text-sm'>
                                    <span className='flex align-items-center gap-2'><PiClock /> Duration: {element.duration} min</span>
                                    <span className='flex align-items-center gap-2'><PiArrowsDownUp /> Order: {element.drill_order ? element.drill_order : '-'}</span>
                                </div>
                            </div>
                        }>
                            <div key={key}>
                                <div className='flex justify-content-between gap-2 px-2 pt-2 mb-2'>
                                    <div className='font-bold text-lg'>{element.title}</div>
                                    <div className='flex gap-2'>
                                        <Button size='small' onClick={()=>getDrillDetails(element.access_key)} icon='pi pi-info-circle' />
                                        <Button severity='danger' size='small' onClick={()=>removeDrill(element.access_key)} icon='pi pi-trash' />
                                    </div>
                                </div>
                                <div className='flex gap-2 justify-content-between p-2'>
                                    <div className='flex flex-0 flex-column gap-1 w-5rem'>
                                        <div className='flex align-items-center gap-1 text-sm'><PiClock /> Duration </div>
                                        <InputText className='w-full' value={element.duration} onChange={(e)=>changeDuration(element.access_key, e.target.value)} />
                                        <div className='flex align-items-center gap-1 text-sm'><PiArrowsDownUp /> Order</div>
                                        <InputText className='w-full' value={element.drill_order} onChange={(e)=>changeOrder(element.access_key, e.target.value)} />
                                    </div>
                                    <div className='flex flex-1 flex-column gap-1'>
                                        <div className='flex align-items-center gap-1 text-sm'><PiArticle /> Comments</div>
                                        <InputTextarea value={element.comments} onChange={(e)=>changeComments(element.access_key, e.target.value)} 
                                        className='w-full h-full' />
                                    </div>
                                </div>
                            </div>
                        </AccordionTab>
                    )) : 'No drills selected'}
                </Accordion>
            </div>
            
            <div className="form--action_row">
                <Button loading={isLoading} type="submit" onClick={onSubmit}>Save</Button>
                <Button loading={isLoading} onClick={()=>navigate(-1)}>Cancel</Button>
            </div>
        </div>



        <Dialog maximized header="Add Drills" visible={dialogIsOpen} onHide={() => setDialogIsOpen(false)}
            footer={
                <div className='mt-2'>
                    <Button label="Add and Close" icon="pi pi-check" severity='success' onClick={()=>setDialogIsOpen(false)} />
                </div>
                }>
                    <DrillListComponent isNavigable={false} addDrill={addDrill} removeDrill={removeDrill} checkDrillAdded={checkDrillAdded} />
            </Dialog>

        <Dialog maximized header="Add Drills" visible={dialogDetailIsOpen} onHide={() => setDialogDetailIsOpen(false)}
        footer={
            <div className='mt-2'>
                <Button label="Apply" icon="pi pi-check" severity='success' onClick={()=>setDialogDetailIsOpen(false)} />
            </div>
            }>
                <DrillDetails access_key={drillDetailsAccessKey} />
        </Dialog>

    </AppBodyComponent>
    )
}

export default PracticeForm