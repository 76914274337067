import React, { useState } from 'react'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'
import { Calendar } from 'primereact/calendar'
import { PiCalendar } from 'react-icons/pi'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'
import AddStatsTeamComponent from './AddStatsTeamComponent'
import ShowTeamStats from './ShowTeamStats'
import AddStatsPlayerComponent from './AddStatsPlayerComponent'
import { TabPanel, TabView } from 'primereact/tabview'
import MyStatsPlayers from './Utils/MyStatsPlayers'
import MyStatsTeams from './Utils/MyStatsTeams'

function MyStats() {

    const navigate = useNavigate()
    const [showStatsForm, setShowStatsForm] = useState(0)

    return (
        <AppBodyComponent activeMenuNumber={8}>
            <div className='flex flex-column gap-2'>
                <div className="event_detail--actions">
                    <Button onClick={()=>navigate('/app/stats_home')} outlined size='small' icon='pi pi-angle-left' />
                </div>

                <TabView>
                    <TabPanel header='Teams Stats'>
                        <MyStatsTeams />
                    </TabPanel>
                    <TabPanel header='Players Stats'>
                        <MyStatsPlayers />
                    </TabPanel>
                </TabView>

                <div className='flex flex-column'>
                    {showStatsForm === 1 ? <AddStatsTeamComponent /> : showStatsForm === 2 ? <AddStatsPlayerComponent /> : '' }
                </div>

            </div>
        </AppBodyComponent>
    )
}

export default MyStats