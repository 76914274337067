import { InputText } from 'primereact/inputtext'
import React, { useContext, useEffect, useRef, useState } from 'react'
import StatisticsContext from '../StatisticsContext'
import { MultiSelect } from 'primereact/multiselect';
import { PiArrowUUpLeft } from 'react-icons/pi';
import { Button } from 'primereact/button';

function PracticeStatisticsListItemComponent({element, setCurrentPlayerAction, changePositionA, index}) {
    const actionsList = [
        {
            label: 'Attack',
            code: 'att',
            items: [
                { label: 'Quality', value: 'att_qua' },
                { label: 'Position', value: 'att_pos' },
            ]
        },
        {
            label: 'Reception',
            code: 'rec',
            items: [
                { label: 'Quality', value: 'rec_qua' },
            ]
        },
        {
            label: 'Serve',
            code: 'ser',
            items: [
                { label: 'Quality', value: 'ser_qua' },
                { label: 'Position', value: 'ser_pos' },
            ]
        },
        {
            label: 'Set',
            code: 'set',
            items: [
                { label: 'Quality', value: 'set_qua' },
                { label: 'Position', value: 'set_pos' },
            ]
        },
        {
            label: 'Block',
            code: 'blo',
            items: [
                { label: 'Quality', value: 'blo_qua' },
            ]
        },
        {
            label: 'Dig',
            code: 'dig',
            items: [
                { label: 'Quality', value: 'dig_qua' },
            ]
        },
    ];

    const [selectedActions, setSelectedActions] = useState(actionsList.flatMap(action => action.items.map(item => item.label === "Quality" ? item.value : null)))
    const {rotationA, gridData, setMatchContext} = useContext(StatisticsContext)
    const positions = [
        {color: '#FF5733', label: 1, value: 1, action: ()=>clickPosition({label: 1, value: 1})},
        {color: '#33FF57', label: 2, value: 2, action: ()=>clickPosition({label: 2, value: 2})},
        {color: '#3357FF', label: 3, value: 3, action: ()=>clickPosition({label: 3, value: 3})},
        {color: '#FF33A1', label: 4, value: 4, action: ()=>clickPosition({label: 4, value: 4})},
        {color: '#FFBD33', label: 5, value: 5, action: ()=>clickPosition({label: 5, value: 5})},
        {color: '#33FFF5', label: 6, value: 6, action: ()=>clickPosition({label: 6, value: 6})},
    ]

    const originalActions = [
        {color: '#188DA5', label: 'ser', value: 1, action: ()=>clickAction({label: 'ser', value: 1})},
        {color: '#12ed89', label: 'rec', value: 2, action: ()=>clickAction({label: 'rec', value: 2})},
        {color: '#E61556', label: 'att', value: 4, action: ()=>clickAction({label: 'att', value: 4})},
        {color: '#F4EBEC', label: 'dig', value: 6, action: ()=>clickAction({label: 'dig', value: 6})},
        {color: '#1C69A5', label: 'blo', value: 5, action: ()=>clickAction({label: 'blo', value: 5})},
        {color: '#F7D025', label: 'set', value: 3, action: ()=>clickAction({label: 'set', value: 3})},
    ]

    const qualitiesFive = [
        {color: '#FF4C4C', label: 'E', value: 1, action: ()=>clickQuality({label: 0, value: 1})},
        {color: '#FFA500', label: 'D', value: 2, action: ()=>clickQuality({label: 1, value: 2})},
        {color: '#FFFF00', label: 'C', value: 3, action: ()=>clickQuality({label: 2, value: 3})},
        {color: '#ADFF2F', label: 'B', value: 4, action: ()=>clickQuality({label: 3, value: 4})},
        {color: '#32CD32', label: 'A', value: 5, action: ()=>clickQuality({label: 4, value: 5})},
    ]

    // const qualitiesFour = [
    //     {color: '#FF4C4C', label: 0, value: 1, action: ()=>clickQuality({label: 0, value: 1})},
    //     {color: '#FFA500', label: 1, value: 2, action: ()=>clickQuality({label: 1, value: 2})},
    //     {color: '#ADFF2F', label: 2, value: 3, action: ()=>clickQuality({label: 2, value: 3})},
    //     {color: '#32CD32', label: 3, value: 4, action: ()=>clickQuality({label: 3, value: 4})},
    // ]

    const qualitiesThree = [
        {color: '#FF4C4C', label: 'C', value: 1, action: ()=>clickQuality({label: 0, value: 1})},
        {color: '#FFFF00', label: 'B', value: 2, action: ()=>clickQuality({label: 1, value: 2})},
        {color: '#32CD32', label: 'A', value: 3, action: ()=>clickQuality({label: 2, value: 3})},
    ]

    useEffect(()=>{
        selectShowActions()
    }, [selectedActions])

    const [currentButtonsLabel, setCurrentButtonsLabel] = useState('Actions')
    const [currentPostion, setCurrentPosition] = useState()
    const currentPositionRef = useRef(currentPostion)
    const [currentAction, setCurrentAction] = useState()
    const currentActionRef = useRef(currentAction)
    const [currentQuality, setCurrentQuality] = useState()
    const [playerPosition, setPlayerPosition] = useState(element?.position ? element?.position : '')
    const [buttons, setButtons] = useState(originalActions)
    const playerPositionRef = useRef(playerPosition)
    const actionsRef = useRef(originalActions)

    const selectShowActions = () => {
        var _actions = [...originalActions]
        _actions = _actions.filter((a)=>selectedActions?.findIndex(sa=>sa ? sa.substring(0,3) === a.label : false) >= 0)
        actionsRef.current = _actions
        currentButtonsLabel === 'Actions' && setButtons(_actions)
    }

    const clickAction = (_item) => {
        setCurrentAction(_item)
        currentActionRef.current = _item
        if (_item.label === 'ser') {
            if (selectedActions.indexOf('ser_pos') >= 0){
                setButtons(positions); setCurrentButtonsLabel('Serve Position')
            } else if (selectedActions.indexOf('ser_qua') >= 0){
                setButtons(qualitiesFive); setCurrentButtonsLabel('Serve Qualities')
            } else {
                finishAction()
            }
        } else if (_item.label === 'att') {
            if (selectedActions.indexOf('att_pos') >= 0){
                setButtons(positions); setCurrentButtonsLabel('Attack Attack')
            } else if (selectedActions.indexOf('att_qua') >= 0){
                setButtons(qualitiesThree); setCurrentButtonsLabel('Attack Qualities')
            } else {
                finishAction()
            }
        } else if (_item.label === 'set') {
            if (selectedActions.indexOf('set_pos') >= 0){
                setButtons(positions); setCurrentButtonsLabel('Set Positions')
            } else if (selectedActions.indexOf('set_qua') >= 0){
                setButtons(qualitiesThree); setCurrentButtonsLabel('Set Qualities')
            } else {
                finishAction()
            }
        } else if (_item.label === 'dig') {
            if (selectedActions.indexOf('dig_qua') >= 0){
                setButtons(qualitiesThree); setCurrentButtonsLabel('Dig Qualities')
            } else {
                finishAction()
            }
        } else if (_item.label === 'blo') {
            if (selectedActions.indexOf('blo_qua') >= 0){
                setButtons(qualitiesThree); setCurrentButtonsLabel('Block Qualities')
            } else {
                finishAction()
            }
        } else if (_item.label === 'rec') {
            setCurrentPosition(playerPositionRef.current ? {value: Number(playerPositionRef.current), label: Number(playerPositionRef.current)} : null)
            currentPositionRef.current = playerPositionRef.current ? {value: Number(playerPositionRef.current), label: Number(playerPositionRef.current)} : null
            if (selectedActions.indexOf('rec_qua') >= 0){
                setButtons(qualitiesFive); setCurrentButtonsLabel('Reception Qualities')
            } else {
                finishAction()
            }
        }
    }

    const clickPosition = (_item) => {
        setCurrentPosition(_item)
        currentPositionRef.current = _item
        if (currentActionRef.current.label === 'ser'){
            if (selectedActions.indexOf('ser_qua') >= 0) {
                setCurrentButtonsLabel('Qualities')
                setButtons(qualitiesFive)
            } else {
                finishAction()
            }
        } else if (currentActionRef.current.label === 'att'){
            if (selectedActions.indexOf('att_qua') >= 0) {
                setCurrentButtonsLabel('Qualities')
                setButtons(qualitiesThree)
            } else {
                finishAction()
            }
        } else if (currentActionRef.current.label === 'set'){
            if (selectedActions.indexOf('set_qua') >= 0) {
                setCurrentButtonsLabel('Qualities')
                setButtons(qualitiesThree)
            } else {
                setCurrentButtonsLabel('Qualities')
                finishAction()
            }
        }
    }

    const clickQuality = (_item) => {
        setCurrentQuality(_item)
        setCurrentButtonsLabel('Actions')
    }

    useEffect(()=>{
        setPlayerPosition(element?.position ? element?.position : '')
    }, [element])

    useEffect(()=>{
        changePositionA(playerPosition, index)
        playerPositionRef.current = playerPosition
    }, [playerPosition])

    useEffect(()=>{
        currentQuality && finishAction()
    },[currentQuality])

    const finishAction = () => {
        const currentPlayerAction = {
            player: element, 
            player_id: element.player.id, 
            playerName: element.number, 
            action_number: currentActionRef.current ? currentActionRef.current.label+"" : null, 
            action: currentActionRef.current ? currentActionRef.current.value : null, 
            position_action: currentPositionRef.current ? currentPositionRef.current.value : null, 
            quality_value: currentQuality ? currentQuality.label : null, 
            quality: currentQuality ? currentQuality.value : null, 
            rotation: rotationA, 
            position: Number(playerPositionRef.current),
            team: gridData.teamA, 
            rally_phase: null,
            set_match: setMatchContext,
        }
        setCurrentPlayerAction(currentPlayerAction)
        refresh()
    }

    const selectedItemTemplate = (e) => {
        return e ? e+' ' : ''
    }

    const refresh = async () => {
        setCurrentAction()
        currentActionRef.current = null
        setCurrentPosition()
        currentPositionRef.current = null
        setCurrentQuality()
        setCurrentButtonsLabel('Actions')
        setButtons(actionsRef.current)
    }
    
    return (
        <div className={`flex-order-${element.position > 0 ? element.position - 1 : 6} flex gap-2 p-2 border-1 w-full border-round ${currentAction ? 'bg-blue-300' : playerPosition && 'bg-blue-100'}`}>
            <div className='flex flex-column align-items-end gap-1'>
                <div className='flex gap-1 align-items-start justify-content-between'>
                    <Button size='small' className={"w-2rem h-full p-2"} onClick={refresh}>
                        <PiArrowUUpLeft size={18} />
                    </Button>
                    <InputText placeholder='#' onChange={(e)=>setPlayerPosition(e.target.value)} className="p-inputtext-sm w-3rem" value={playerPosition} />
                    <div className='flex align-items-center font-bold text-lg'>
                        {element.number < 10 ? `0${element.number}` : element.number}
                    </div>
                </div>
                <MultiSelect value={selectedActions} options={actionsList} onChange={(e) => setSelectedActions(e.value)} 
                    optionLabel="label" optionGroupLabel="label" optionGroupChildren="items" placeholder="Select Actions" 
                    selectedItemTemplate={selectedItemTemplate} className="w-6rem h-full" selectAllLabel='Actions'/>
            </div>
            <div className='flex flex-column w-full'>
                <div className={'flex justify-content-between pb-1'}>
                    <div className='text-sm white-space-nowrap font-bold'>
                        {element.player.first_name} {element.player.last_name}
                    </div>
                    <div className='flex align-items-center font-bold'>{currentButtonsLabel}</div>
                </div>
                <div className='flex flex-1 gap-1 justify-content-between'>
                    {
                        buttons && buttons.map((item, key)=>(
                            <div key={key} onClick={item?.action} style={{backgroundColor: item.color}}
                            className='p-2 w-full border-1 border-round align-items-center justify-content-center flex'>
                                {item.label}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default PracticeStatisticsListItemComponent