import React, { useContext, useEffect, useState } from 'react'
import DrillListComponent from './DrillListComponent'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'

function DrillList() {

    
    return (
        <AppBodyComponent activeMenuNumber={3}>
            <DrillListComponent />
        </AppBodyComponent>
    )
}

export default DrillList