import React, { useContext, useEffect, useState } from 'react'
import UserAutocompleteComponent from '../../../components/UserAutocompleteComponent/UserAutocompleteComponent'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { listAllTechnicalGoals } from '../../../services/DrillService'
import { useNavigate, useParams } from 'react-router-dom'
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { PiCalendar, PiUser, PiWarningCircle } from 'react-icons/pi'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'
import { saveStatsPLayer } from '../../../services/StatsService'
import AuthContext from '../../../context/AuthContext/AuthContext'
import { toast } from 'react-toastify'

function AddStatsPlayerComponent() {

    const [player, setPlayer] = useState()
    const [statsTechnicals, setStatsTechnicals] = useState([])
    const navigate = useNavigate()
    const [date,setDate] = useState('')
    const {userAuth} = useContext(AuthContext)

    useEffect(()=>{
        listAllTechnicalGoals().then(response=>{
            let _response = []
            response && response.length > 0 && response.filter(e=>e.show_stats).forEach(element => {
                _response.push({id: element.id, number: '', name: element.name})
            });
            setStatsTechnicals(_response)
        })
    },[])

    const handleNumber = (e, index) => {
        var _statsTechnicals = [...statsTechnicals]
        _statsTechnicals[index].number = e.target.value
        setStatsTechnicals(_statsTechnicals)
    }

    const cleanNumbers = () => {
        var _statsTechnicals = [...statsTechnicals]
        for (let index = 0; index < _statsTechnicals.length; index++) {
            _statsTechnicals[index].number = ''
        }
        setStatsTechnicals(_statsTechnicals)
    }
    const saveTechnicalStats = () => {
        const _statsTechnicals = statsTechnicals.filter(e=>e.number != '')
        if (!_statsTechnicals || _statsTechnicals.length === 0 || !date || !player || !userAuth?.user?.access_key){
            toast.error('Inform date, player and one technical skill.')
            return
        }
        saveStatsPLayer(_statsTechnicals, date, player, userAuth?.user?.access_key).then((res)=>{
            if (res){
                toast.success('Data saved.')
                confirmDialog({
                    message: `Do you want to add more stats data?`,
                    header: 'Confirm?',
                    icon: <PiWarningCircle />,
                    defaultFocus: 'accept',
                    accept: () => cleanNumbers(),
                    reject: () => redirectToMyStats()
                });
            } 
        })
    }

    const redirectToMyStats = () => {
        navigate('/app/my_stats')
    }

    return (
        <AppBodyComponent activeMenuNumber={8}>
            <div className='flex flex-column gap-2'>
                <ConfirmDialog />
                <div className="event_detail--actions">
                    <Button onClick={()=>navigate('/app/my_stats')} outlined size='small' icon='pi pi-angle-left' />
                </div>
                <div className='flex flex-wrap w-full gap-2'>
                    <div className='flex flex-column gap-1 w-full md:w-8rem'>
                        <span className='flex gap-1 align-items-center'><PiCalendar /> Date</span>
                        <Calendar className='w-full md:w-8rem' value={date} onChange={(e)=>setDate(e.value)} />
                    </div>
                    <div className='flex flex-column gap-1 flex-1'>
                        <span className='flex gap-1 align-items-center'><PiUser /> Player</span>
                        <UserAutocompleteComponent user={player} setUser={setPlayer} canCreate={false} />
                    </div>
                </div>
                <div className='flex flex-column mt-2'>
                    {statsTechnicals && statsTechnicals.map((item, key)=>(
                        <div className={'flex flex-wrap p-2 gap-2 justify-content-between w-full md:flex-1 align-items-center '+(((key%2)===0)?'bg-gray-100':'')} key={key}>
                            <div className='flex-1 text-lg'>{item.name}</div>
                            <InputText className='w-8rem' placeholder='value' value={item.number} name='player_number' onChange={(e)=>handleNumber(e, key)} />
                        </div>
                    ))}
                    <div className='flex gap-2 justify-content-center mt-4'>
                        <Button label='Save Stats' className='w-max' onClick={saveTechnicalStats} />
                        <Button label='Cancel' className='w-max' onClick={()=>navigate('/app/my_stats')} />
                    </div>
                </div>
                

            </div>
        </AppBodyComponent>
    )
}

export default AddStatsPlayerComponent