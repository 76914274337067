import React from 'react'
import { Skeleton } from 'primereact/skeleton';

function LoadingSkeletonDetailsComponent() {
    return (
        <div className='flex flex-column gap-2 border-1 border-round gap-2 p-2'>
            <Skeleton className="w-8 h-2rem"></Skeleton>

            <div className='md:flex justify-content-between'>
                <Skeleton className="w-full md:w-5 h-5rem mb-2"></Skeleton>
                <Skeleton className="w-full md:w-5 h-5rem"></Skeleton>
            </div>


            <Skeleton className="w-2 my-2"></Skeleton>
            
            <Skeleton className="w-3"></Skeleton>
            <Skeleton className="w-6 h-3rem"></Skeleton>

            <Skeleton className="w-2"></Skeleton>
            <Skeleton className="w-5 h-3rem"></Skeleton>

            <Skeleton className="w-3"></Skeleton>
            <Skeleton className="w-5 h-3rem"></Skeleton>
        </div>
    )
}

export default LoadingSkeletonDetailsComponent