import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React, { useContext, useState } from 'react'
import { PiUsersThree } from 'react-icons/pi'
import StatisticsContext from '../../StatisticsContext'
import ManageTeamButtonsComponent from './ManageTeamButtonsComponent'
import { TabPanel, TabView } from 'primereact/tabview'

function ManageTeamComponent() {
    const [visible, setVisible] = useState(false)
    const {playersA, setPlayersA, activePlayersAContext, setActivePlayersAContext, playersB, setPlayersB, activePlayersBContext, setActivePlayersBContext, 
        rotationA, rotationB} = useContext(StatisticsContext)
    const footer = () => {
        return (
            <div className='pt-2'>
                <Button className='h-2rem md:h-3rem' onClick={handleCancel}>Close</Button>
            </div>
        )
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const header = () => {
        return (
            <div className='flex flex-column gap-1'>
                <div className='text-base md:text-xl font-bold'>
                    Team Line-up
                </div>
            </div>
        )
    } 
    
   return (
        <div>
            <Dialog className='w-11 md:w-8' contentClassName='p-1 md:p-2' headerClassName='p-2 md:p-4' header={header} visible={visible} onHide={()=>setVisible(false)} footer={footer}>
                <TabView>
                    <TabPanel header="My Team">
                        <ManageTeamButtonsComponent teamAorB={1} rotation={rotationA} players={playersA} setPlayers={setPlayersA} activePlayersContext={activePlayersAContext} setActivePlayersContext={setActivePlayersAContext} />
                    </TabPanel>
                    <TabPanel header="Opponent Team">
                        <ManageTeamButtonsComponent teamAorB={2} rotation={rotationB} players={playersB} setPlayers={setPlayersB} activePlayersContext={activePlayersBContext} setActivePlayersContext={setActivePlayersBContext} />
                    </TabPanel>
                </TabView>
            </Dialog>

            <Button size='small' className={"w-max h-full p-2"} onClick={()=>setVisible(true)}><PiUsersThree size={18} /></Button>
        </div>
    )
}

export default ManageTeamComponent