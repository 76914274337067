
import { toast } from 'react-toastify';
import axios from 'axios';
// import {firebase_auth} from '../firebase';

export const listUsersByFilters = async (
    name, 
    mapBoundary, 
    addressMarker, miles, 
    sport, skill, rating, 
    page=1) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/user/list_by_filters`, {name, 
        mapBoundary, 
        addressMarker, miles, 
        sport, skill, rating, 
        page})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const listUsersByName = async (name, user_access_key) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/user/list_by_name`, {name, user_access_key})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const listCoachesByName = async (name) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/user/list_coaches_by_name`, {name})
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const getUserByAccessKey = async (access_key) => {
    try {
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/user/get_by_access_key/${access_key}`)
        .then((response)=>{
            return response.data
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const sendEmailNewMessage = async (
    message, 
    access_key, 
    user_name,
    email_to,
    email_reply) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/user/send_email_new_message`, {message, 
        access_key, 
        user_name,
        email_to,
        email_reply})
        .then((response)=>{
            toast.success('Message sent!')
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const forgotPassword = async (email) => {
    try {
        if (!email){
            toast.error('Email invalid.')
            return false
        }
        return await axios.get(`${process.env.REACT_APP_API_URL}/api/user/forgot_password/${email}`)
        .then((response)=>{
            toast.success('Check your email.')
            return true
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const resetPassword = async (code, password) => {
    try {
        if (!code && !password){
            toast.error('Invalid data.')
            return false
        }
        return await axios.put(`${process.env.REACT_APP_API_URL}/api/user/reset_password`,{code, password})
        .then((response)=>{
            toast.success('Password updated.')
            return true
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}

export const unsubscribeEmail = async (code) => {
    try {
        if (!code){
            toast.error('Invalid data.')
            return false
        }
        return await axios.post(`${process.env.REACT_APP_API_URL}/api/user/unsubscribe_email`,{code})
        .then((response)=>{
            toast.success('Email unsubscribed sucessfuly.')
            return true
        }).catch((error)=>{
            toast.error(error.response.data.message)
            return false
        })
    } catch (error) {
        toast.error('A server error ocurred.')
    }
}



