import React, { useContext, useEffect, useState } from 'react'
import {confirmDialog} from 'primereact/confirmdialog'
import AuthContext from '../../context/AuthContext/AuthContext'
import { listTeamByName, newTeam } from '../../services/TeamService'
import { AutoComplete } from "primereact/autocomplete";
import { PiWarningCircle } from 'react-icons/pi';

function TeamAutocompleteComponent({team, setTeam, size='', canCreate=true}) {

    const {userAuth} = useContext(AuthContext)
    const [teams, setTeams] = useState([])
    const [teamSelected, setTeamSelected] = useState('')
    const [filteredTeams, setFilteredTeams] = useState('')
    const [name, setName] = useState('')

    useEffect(() => {
        handleListTeamsByName(name);
    }, [name]);

    useEffect(()=>{
        setTeamSelected(team?.name || '')
    },[team])

    useEffect(()=>{
        if (teams?.length){
            setFilteredTeams(teams);
        } else {
            canCreate ? setFilteredTeams([{
                access_key: 0,
                name: 'Create '+ name
            }]) : 
            setFilteredTeams([])
        }
    },[teams])
    
    const handleListTeamsByName = async (_name = '') => {
        _name && setTeams(await listTeamByName(_name, userAuth?.user?.access_key))
    }

    const search = async (event) => {
        var _name = event.query.charAt(0).toUpperCase() + event.query.slice(1)
        await setName(_name)
    }

    const handleTeamOnChange = async (e) => {
        if (typeof e.value === "object"){
            if (e.value.access_key === 0){
                confirmDialog({
                    message: `Do you want to create the team ${name}?`,
                    header: 'Confirm?',
                    icon: <PiWarningCircle />,
                    defaultFocus: 'accept',
                    accept,
                    reject
                });
            } else {
                await setTeamSelected(e.value.name)
                await setTeam(e.value)
            }
        } else {
            await setTeamSelected(e.value)
        }
    }
      
    const accept = () => {
        if (name && userAuth.user.access_key){
            newTeam(name, userAuth.user.access_key).then(async(data)=>{
                if (data?.access_key){
                    await setTeam({access_key: data.access_key, name, id: data.id})
                    await setTeamSelected(name)
                }
            });
        }
    }

    const reject = async () => {
        
    }

    const itemTemplate = (item) => {
        return(item.name)
    }

    return (
        <>
            <AutoComplete 
                emptyMessage='not found'
                showEmptyMessage={true}
                className={`flex ${size}`}
                placeholder="Enter the team's name..."
                inputStyle={{width: '100%'}} style={{width: '100%'}}
                minLength={3}
                field="name" 
                value={teamSelected} 
                suggestions={filteredTeams} 
                completeMethod={search} 
                onChange={handleTeamOnChange} 
                itemTemplate={itemTemplate}
            />
        </>
    )
}

export default TeamAutocompleteComponent