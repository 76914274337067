import React, { useContext, useEffect, useState } from 'react'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { Button } from 'primereact/button'
import UserAutocompleteComponent from '../../../components/UserAutocompleteComponent/UserAutocompleteComponent'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { getTeamPlayersService, newTeamPlayers, updateTeamPlayers } from '../../../services/TeamService'
import { useNavigate, useParams } from 'react-router-dom'
import AuthContext from '../../../context/AuthContext/AuthContext'
import { toast } from 'react-toastify'

function TeamForm() {
    const navigate = useNavigate()
    const {access_key} = useParams()
    const {userAuth} = useContext(AuthContext)
    const [teamPlayers, setTeamPlayers] = useState([])
    const [name, setName] = useState('')
    const skills = [
        {value: 9, label: 'Middle Blocker'},
        {value: 10, label: 'Outside Hitter'},
        {value: 11, label: 'Opposite Hitter'},
        {value: 12, label: 'Setter'},
        {value: 14, label: 'Libero'},
    ]

    useEffect(()=>{
        handleLoadTeam()
    },[access_key])

    const handleLoadTeam = () => {
        access_key && getTeamPlayersService(access_key).then((response)=>{
            setName(response.name)
            response.players && response.players?.length > 0 && setTeamPlayers(response.players)
        })
    }

    const addTeamPlayers = () => {
        const _teamPlayer = {
            user: null,
            player_number: '',
            skill_id: '',
            report_order: '',
        }
        setTeamPlayers([...teamPlayers, _teamPlayer])
    }

    const handlePlayerNumber = (e, index) => {
        var _teamPlayers = [...teamPlayers]
        _teamPlayers[index].player_number = e.target.value
        setTeamPlayers(_teamPlayers)
    }

    const handleSkill = (e, index) => {
        var _teamPlayers = [...teamPlayers]
        _teamPlayers[index].skill_id = e.target.value
        const report_order = e.target.value === 9 ? 3 : e.target.value === 10 ? 1 : e.target.value === 11 ? 2 : e.target.value === 12 ? 5 : e.target.value === 14 ? 4 : 6
        _teamPlayers[index].report_order = report_order
        setTeamPlayers(_teamPlayers)
    }

    const handleRemoveTeamPLayer = (index) => {
        var _teamPlayers = [...teamPlayers]
        _teamPlayers = _teamPlayers.filter((e, key)=>key!==index)
        setTeamPlayers(_teamPlayers ? _teamPlayers : [])
    }

    const addUser = (e, key) => {

        if (typeof key !== 'number' || key < 0 || !Array.isArray(teamPlayers)) {
            return;
        }

        if (!e){
            let _teamPlayers = [...teamPlayers]
            _teamPlayers[key].user = null
            setTeamPlayers(_teamPlayers)
            return
        }

        const updatedTeamPlayers = teamPlayers.map((player, index) =>
            index === key ? { ...player, ...e } : player
        );
        setTeamPlayers(updatedTeamPlayers);
    }

    const saveTeamPlayers = () => {
        const missingUserIndex = teamPlayers.findIndex(e=>!e.player_id)
        if (missingUserIndex > -1){
            toast.error('Create all players before save')
        }
        const missingPositionIndex = teamPlayers.findIndex(e=>!e.skill_id)
        if (missingPositionIndex > -1){
            toast.error(`Inform all players' positions`)
        }
        const missingNumberIndex = teamPlayers.findIndex(e=>!e.player_number)
        if (missingNumberIndex > -1){
            toast.error(`Inform all players' numbers`)
        }
        else if (access_key){
            updateTeamPlayers(name, teamPlayers, userAuth.user.access_key, access_key).then((res)=>{
                console.log(res)
                if (res){
                    navigate('/app/team_list')
                } 
            })
        } else {
            newTeamPlayers(name, teamPlayers, userAuth.user.access_key).then((res)=>{
                if (res){
                    navigate('/app/team_list')
                } 
            })
        }
    }

    return (
        <AppBodyComponent activeMenuNumber={4}>
            <ConfirmDialog />
            <div className='font-bold text-2xl mb-4'>Create a Team</div>
            <div className='flex flex-column gap-2 mb-4'>
                <InputText placeholder='Team Name' className='w-full' value={name} onChange={(e)=>setName(e.target.value)} />
            </div>
            <Button className='w-max' onClick={addTeamPlayers} icon='pi pi-user-plus' label='Add Player' />
            <div className='flex flex-column gap-4 mt-2'>
                {teamPlayers && teamPlayers.map((item, key)=>(
                    <div className='flex flex-wrap gap-1 justify-content-between' key={key}>
                        <div className='w-full md:flex-0'>
                            <UserAutocompleteComponent user={item} setUser={(e)=>addUser(e, key)} />
                            <span className='text-red-500' hidden={!item?.player_id ? false : true}>Create/Select player {name}</span>
                        </div>
                        <div className='flex justify-content-between gap-1 w-full md:flex-1'>
                            <div className='flex gap-2'>
                                <div className='flex flex-column'>
                                    <Dropdown className='w-12rem' name='position' value={item.skill_id ? item.skill_id : ''} options={skills} 
                                    onChange={(e)=>handleSkill(e, key)} placeholder='Position' optionLabel='label' />
                                    <span className='text-red-500' hidden={!item.skill_id ? false : true}>Select player position</span>
                                </div>
                                <div className='flex flex-column'>
                                    <InputText className='w-5rem h-min' placeholder='#' value={item.player_number ? item.player_number : ''} name='player_number' onChange={(e)=>handlePlayerNumber(e, key)} />
                                    <span className='text-red-500' hidden={!item.player_number ? false : true}>Inform player number</span>
                                </div>
                            </div>
                            <div className='h-full'>
                                <Button severity='danger' onClick={()=>handleRemoveTeamPLayer(key)} icon='pi pi-trash'/ >
                            </div>
                        </div>
                    </div>
                ))}
                <div className='flex gap-2 justify-content-center mt-4'>
                    <Button label='Save Team' className='w-max' onClick={saveTeamPlayers} />
                    <Button label='Cancel' className='w-max' onClick={()=>navigate('/app/team_list')} />
                </div>
            </div>
        </AppBodyComponent>
    )
}

export default TeamForm