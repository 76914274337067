import React from 'react'

import { TabPanel, TabView } from 'primereact/tabview'
import DashboardGoals from '../../components/DashboardGoals'
import AppBodyComponent from '../../components/AppBody/AppBodyComponent'
import DashboardJournal from '../../components/DashboardJournal'
import DashboardMostUsedDrills from '../../components/DashboardMostUsedDrills'

function UserDashboard() {
    return(
        <AppBodyComponent activeMenuNumber={1}>
            <TabView>
                <TabPanel header='Practices Goals'>
                    <DashboardGoals />            
                </TabPanel>
                <TabPanel header='Practices Journal'>
                    <DashboardJournal />
                </TabPanel>
                <TabPanel header='My Drills'>
                    <DashboardMostUsedDrills />
                </TabPanel>
            </TabView>
        </AppBodyComponent>
    )
}

export default UserDashboard