import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { deleteDrillService, getDrillDetailsService, handleDrillFavouriteService } from '../../../services/DrillService'
import { PiArrowSquareOut, PiArticle, PiBarbell, PiClipboard, PiClock, PiEyeSlash, PiGlobe, PiHandPointing, PiHouse, PiIdentificationCard, PiLink, PiLock, PiPersonSimpleThrow, PiStar, PiTarget, PiThumbsUp, PiUser, PiUsers, PiUsersThree, PiWarningCircle } from 'react-icons/pi'
import { Button } from 'primereact/button'
import AuthContext from '../../../context/AuthContext/AuthContext'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import LoadingSkeletonDetailsComponent from '../../../components/LoadingSkeletonDetailsComponent'
import { translateService } from '../../../services/UtilService'
import { Chip } from 'primereact/chip'
import { InstagramEmbed, YouTubeEmbed } from 'react-social-media-embed'
import SocialMediaEmbedComponent from '../../../components/SocialMediaEmbedComponent'

function DrillDetails({access_key, canDeleteUpdate=true, setDialogIsOpen}) {
    const [drillDetails, setDrillDetails] = useState()
    const navigate = useNavigate()
    const {userAuth} = useContext(AuthContext)
    const [isFavorite, setIsFavorite] = useState()
    const [isLoading, setIsLoading] = useState()
    const [translatedText, setTranslatedText] = useState('');
    const languageCode = navigator.language || navigator.userLanguage; // Detect browser language
    const [showDescription, setShowDescription] = useState(false)
    const [detectedSourceLanguage, setDetectedSourceLanguage] = useState()
  
    
    useEffect(()=>{
        access_key && getDrillDetails()
    },[access_key])

    const getDrillDetails = async () => {
        setIsLoading(true)
        const user_access_key = userAuth?.user?.access_key ? userAuth?.user?.access_key : null
        access_key && await getDrillDetailsService(access_key, user_access_key).then(response=>{
            if (response) {
                setDrillDetails(response)
                setIsFavorite(response.is_favorite ? true : false)
            }
        })
        setIsLoading(false)
    }

    const showIconShareLevel = (share_level) => {
        return(
            share_level === 1 ? <PiLock size={18} /> :
            share_level === 2 ? <PiUsers size={18} /> :
            share_level === 3 ? <PiHouse size={18} /> :
            share_level === 4 ? <PiGlobe size={18} /> : ''
        )
    }

    const deleteDrill = () => {
        confirmDialog({
            message: `Do you want to delete the drill ${drillDetails.title}?`,
            header: 'Confirm?',
            icon: <PiWarningCircle />,
            defaultFocus: 'accept',
            accept: () => confirmDeleteDrill(),
            reject : ()=>{}
        });
    }

    const confirmDeleteDrill = () =>{
        deleteDrillService(drillDetails.access_key).then(async response=>{
            setDialogIsOpen && await setDialogIsOpen(false)
        })
    }

    const updateDrill = () => {
        navigate('/app/update_drill/'+drillDetails.access_key)
    }

    const handleFavorite = () =>{
        if (userAuth?.user?.access_key){
            handleDrillFavouriteService(userAuth?.user?.access_key,drillDetails.access_key)
            setIsFavorite(!isFavorite)
        } else {
            confirmDialog({
                message: `Do you want to create an account to add your favorites?`,
                header: 'Create an account',
                icon: 'pi pi-exclamation-circle',
                defaultFocus: 'accept',
                accept: () => navigate('../register'),
                reject : ()=>{}
            });    
        }
    }

    const handleTranslate = () => {
        if (!drillDetails.description && !drillDetails.title) return
        setShowDescription(true)
        translateService([drillDetails.description, drillDetails.title], languageCode).then((response)=>{
            if (!response || response.length === 0) return
            let _drill = {...drillDetails}
            _drill.description = response[0].translatedText
            _drill.title = response[1].translatedText
            setDrillDetails(_drill)
            setDetectedSourceLanguage(response[0].detectedSourceLanguage)
        })
    }

    return (
        <div>
            <ConfirmDialog />
            {isLoading ? <LoadingSkeletonDetailsComponent /> : drillDetails ? 
            <div className='flex flex-column'>
                <div className='flex justify-content-end gap-2'>
                    {drillDetails.user_access_key === userAuth?.user?.access_key && canDeleteUpdate && 
                    <div className='flex gap-2'>
                        <div className="event_detail--actions">
                            <Button onClick={updateDrill} size='small' icon='pi pi-pencil' />
                        </div>
                        <div className="event_detail--actions">
                            <Button onClick={deleteDrill} size='small' icon='pi pi-trash' />
                        </div>
                    </div>}
                </div>
                <div className='flex justify-content-between gap-2 pt-2 mb-4 align-items-center'>
                    <div className='flex flex-column gap-2'>
                        <div className='flex gap-1 align-items-center'>
                            {showIconShareLevel(drillDetails.share_level)} 
                            <span className='font-bold text-lg'>{drillDetails.title} 
                                <Button onClick={handleTranslate} text className='p-1 text-xs' size='small' label='translate'></Button>
                            </span>
                        </div>
                    </div>
                    <div className='w-max'>
                        <Button icon={`pi ${isFavorite ? 'pi-heart-fill' : 'pi-heart'}`} rounded outlined severity="danger" aria-label="Favorite" onClick={handleFavorite} />
                    </div>
                </div>
                <div className='flex flex-column'>
                    <div className='font-bold flex gap-1 align-items-center'><PiTarget /> Main Goals </div> 
                    <div className='white-space-nowrap overflow-hidden text-overflow-ellipsis'>
                        {drillDetails.main_categories ? drillDetails.main_categories.map(e=>e.name).join(', ') : '-'}
                    </div>
                </div>
                <div className='flex flex-wrap gap-4'>
                    <div className='flex justify-content-center mt-2 w-full lg:w-min w-full lg:w-3'>
                        <SocialMediaEmbedComponent media_url={drillDetails.media_url} />                      
                    </div>

                    <div className='flex flex-column gap-4 text-sm md:text-base w-full lg:w-8'>
                        <div className='flex gap-2 justify-content-between'>
                            <div className='flex flex-column'>
                                <div className='font-bold flex align-items-center gap-1 white-space-nowrap'><PiStar /> Rating:</div>
                                <div className='white-space-nowrap'>{drillDetails.rating ? Number(drillDetails.rating).toFixed(1) : '-'}</div>
                            </div>
                            <div className='flex flex-column gap-1 w-7rem'>
                                <div className='font-bold flex align-items-center gap-1'><PiThumbsUp /> Used in:</div>
                                <div className=''>{drillDetails.count_drill} {drillDetails.count_drill === 1 ? 'practice' : 'practices'}</div>
                            </div>
                            <div className='flex flex-column gap-1 w-7rem'>
                                <div className='font-bold flex align-items-center gap-1'><PiClock /> Duration </div>
                                <div className=''>{drillDetails.duration} min</div>
                            </div>
                        </div>
                        <div className='flex gap-2 justify-content-between flex-wrap'>
                            <div className='flex flex-wrap gap-2 justify-content-between flex-1'>
                                <div className='flex flex-column'>
                                    <div className='font-bold flex align-items-center gap-1 white-space-nowrap'><PiHandPointing /> I used this drill:</div>
                                    <div className='white-space-nowrap'>{drillDetails.used_by_me}% of my practices</div>
                                </div>
                                <div className='flex flex-column gap-1 w-7rem'>
                                    <div className='font-bold flex align-items-center gap-1'><PiUser /> Added by:</div>
                                    <div className=''>{drillDetails.first_name} {drillDetails.last_name}</div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-column gap-1'>
                            <div className='font-bold flex gap-1 align-items-center'><PiIdentificationCard /> Ages </div> 
                            <div className='flex gap-1 flex-wrap'>
                                {drillDetails.ages ? drillDetails.ages.sort((a,b)=>a.name-b.name).map((e,key)=>(<Chip key={key} className='text-xs white-space-nowrap' label={e.name} />)) : '-'}
                            </div>
                        </div>
                        <div className='flex flex-column gap-1'>
                            <div className='font-bold flex gap-1 align-items-center'><PiPersonSimpleThrow /> Technical Goals </div> 
                            <div className='flex gap-1 flex-wrap'>
                                {drillDetails.technical_categories ? drillDetails.technical_categories.map((e,key)=>(<Chip key={key} className='text-xs white-space-nowrap' label={e.name} />)) : '-'}
                            </div>
                        </div>
                        <div className='flex flex-column gap-1'>
                            <div className='font-bold flex gap-1 align-items-center'><PiClipboard /> Tactical Goals </div> 
                            <div className='flex gap-1 flex-wrap'>
                                {drillDetails.tatical_categories ? drillDetails.tatical_categories.map((e,key)=>(<Chip key={key} className='text-xs white-space-nowrap' label={e.name} />)) : '-'}
                            </div>
                        </div>
                        <div className='flex flex-column gap-1'>
                            <div className='font-bold flex gap-1 align-items-center'><PiBarbell /> Physical Goals</div> 
                            <div className='flex gap-1 flex-wrap'>
                                {drillDetails.physical_categories ? drillDetails.physical_categories.map((e,key)=>(<Chip key={key} className='text-xs white-space-nowrap' label={e.name} />)) : '-'}
                            </div>
                        </div>
                        <div className='flex flex-column gap-1'>
                            <div className='font-bold flex gap-1 align-items-center'><PiUsersThree /> Player Positions </div> 
                            <div className='flex gap-1 flex-wrap'>
                                {drillDetails.skills ? drillDetails.skills.map((e,key)=>(<Chip key={key} className='text-xs white-space-nowrap' label={e.name} />)) : '-'}
                            </div>
                        </div>
                        <div className='flex flex-column gap-1'>
                            <div className='font-bold flex gap-1 align-items-center'><PiArticle /> Description 
                            <Button onClick={handleTranslate} text className='p-1 text-xs' size='small' label='translate'></Button>
                            </div> 
                            {drillDetails?.description ? 
                            <div className='flex flex-column gap-1 '>
                                {showDescription && <div className='text-xs md:text-sm text-bluegray-500 font-italic'>translated from {detectedSourceLanguage} to {languageCode}</div>}
                                {drillDetails?.description && <div className='w-full' dangerouslySetInnerHTML={{__html: drillDetails?.description?.replace(/\n/g, '<br>')}} /> }
                            </div>
                            : <div className='text-xs md:text-sm text-bluegray-500 font-italic'>no description</div>}
                        </div>
                    </div>
                </div>

            </div> : 'Drill not found'}
        </div>
    )
}

export default DrillDetails