import React, { useContext, useEffect, useState } from 'react'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import AuthContext from '../../../context/AuthContext/AuthContext';
import { MultiSelect } from 'primereact/multiselect';
import { getDrillDetailsService, listAllAges, listAllMainGoals, listAllPhysicalGoals, listAllTaticalGoals, listAllTechnicalGoals, listAllVolleyballSkills, newDrill, updateDrillService } from '../../../services/DrillService';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { PiBarbell, PiClipboard, PiClock, PiIdentificationCard, PiLink, PiPersonSimpleThrow, PiTarget, PiUsersThree } from 'react-icons/pi';

function DrillForm() {

    const navigate = useNavigate();
    const {access_key} = useParams()

    const {userAuth} = useContext(AuthContext);
    const [title, setTitle] = useState('')
    const [url, setUrl] = useState('')
    const [duration, setDuration] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [playerPositionsSelected, setPlayerPositionsSelected] = useState([])
    const [playerPositions, setPlayerPositions] = useState([])
    const [agesSelected, setAgesSelected] = useState([])
    const [ages, setAges] = useState([])
    const share_levels = [
        {value: 1, label: 'Only me'},
        // {value: 2, label: 'My team'},
        // {value: 3, label: 'My club'},
        {value: 4, label: 'Everyone'},
    ]
    const [shareLevelsSelected, setShareLevelsSelected] = useState(4)

    const [taticalGoalsSelected, setTaticalGoalsSelected] = useState()
    const [taticalGoals, setTaticalGoals] = useState([])

    const [technicalGoalsSelected, setTechnicalGoalsSelected] = useState()
    const [technicalGoals, setTechnicalGoals] = useState([])

    const [physicalGoalsSelected, setPhysicalGoalsSelected] = useState()
    const [physicalGoals, setPhysicalGoals] = useState([])

    const [mainGoalsSelected, setMainGoalsSelected] = useState()
    const [mainGoals, setMainGoals] = useState([])

    const [description, setDescription] = useState('')

    useEffect(()=>{
        listGoals()
        loadDrill()
    },[])

    const loadDrill = () => {
        if (access_key) {
            getDrillDetailsService(access_key).then((element)=>{
                setTitle(element.title)
                setAgesSelected(element.ages ? element.ages.map(e=>e.id) : null)
                setPhysicalGoalsSelected(element.physical_categories ? element.physical_categories.map(e=>e.id) : null)
                setMainGoalsSelected(element.main_categories ? element.main_categories.map(e=>e.id) : null)
                setTaticalGoalsSelected(element.tatical_categories ? element.tatical_categories.map(e=>e.id) : null)
                setTechnicalGoalsSelected(element.technical_categories ? element.technical_categories.map(e=>e.id) : null)
                setPlayerPositionsSelected(element.skills ? element.skills.map(e=>e.id) : null)
                setShareLevelsSelected(element.share_level)
                setDescription(element.description)
                setDuration(element.duration)
                setUrl(element.media_url)
            })
        }
    }

    const listGoals = () => {
        listAllAges().then(response => {
            setAges(response)
        })
        listAllVolleyballSkills().then(response => {
            setPlayerPositions(response)
        })
        listAllPhysicalGoals().then(response => {
            setPhysicalGoals(response)
        })
        listAllMainGoals().then(response => {
            setMainGoals(response)
        })
        listAllTaticalGoals().then(response => {
            setTaticalGoals(response)
        })
        listAllTechnicalGoals().then(response => {
            setTechnicalGoals(response)
        })
    }

    const onSubmit = async () => {
        setIsLoading(true)
        const _ages = agesSelected?.length ? agesSelected : null
        const _technicalGoals = technicalGoalsSelected?.length ? technicalGoalsSelected : null
        const _taticalGoals = taticalGoalsSelected?.length ? taticalGoalsSelected : null  
        const _physicalGoals = physicalGoalsSelected?.length ? physicalGoalsSelected : null  
        const _mainGoals = mainGoalsSelected?.length ? mainGoalsSelected : null  
        const _playerPositions = playerPositionsSelected?.length ? playerPositionsSelected : null
        if (!access_key){
            await newDrill(title, _technicalGoals, _taticalGoals, _physicalGoals, _playerPositions, duration, url, 
                description, userAuth.user.access_key, shareLevelsSelected, _ages, _mainGoals).then((response)=>{
                response && navigate('/app/drill_list')
            })
        } else {
            await updateDrillService(access_key, title, _technicalGoals, _taticalGoals, _physicalGoals, _playerPositions, duration, url, 
            description, userAuth.user.access_key, shareLevelsSelected, _ages, _mainGoals).then((response)=>{
                response && navigate('/app/drill_list')
            })
        }
        setIsLoading(false)
    }

    const onError = (errors) => {
    }

    const handleAges = (e) => {
        setAgesSelected(e.value)
    }
    const handlePlayerPositions = (e) => {
        setPlayerPositionsSelected(e.value)
    }

    const handleTechnicalGoals = (e) => {
        setTechnicalGoalsSelected(e.value)
    }

    const handleMainlGoals = (e) => {
        setMainGoalsSelected(e.value)
    }

    const handleTaticalGoals = (e) => {
        setTaticalGoalsSelected(e.value)
    }

    const handlePhysicalGoals = (e) => {
        setPhysicalGoalsSelected(e.value)
    }

    const handleDescription = (e) => {
        setDescription(e.target.value)
    };
    
    const editorHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                <button className="ql-list" value="bullet" aria-label="List"></button>
                <button className="ql-list" value="ordered" aria-label="List"></button>
            </span>
        )
    }
    const header = editorHeader();

    return (
    <AppBodyComponent activeMenuNumber={3}>
        <div className='p-2 font-bold text-4xl'>
            {access_key ? 'Update Drill' : `Create new Drill`}
        </div>
        <div className="form--container">
            <div className="form--row">
                <div className='flex flex-column flex-1 gap-1'>
                    <div className='text-sm flex gap-1 align-items-center'>Title</div> 
                    <InputText className='flex flex-1' value={title} type='text' placeholder='Drill Title' name='title' onChange={(e)=>setTitle(e.target.value)} />
                </div>
                <div className='flex flex-column flex-1 gap-1'>
                    <div className='text-sm flex gap-1 align-items-center'>Share</div> 
                    <Dropdown placeholder='Share my drill' options={share_levels} value={shareLevelsSelected} onChange={e=>setShareLevelsSelected(e.value)} />
                </div>
            </div>
            <div className="form--row gap-1">
                <div className='text-sm flex gap-1 align-items-center'><PiTarget /> Main Goal</div> 
                <MultiSelect optionValue='id' optionLabel='name' name='main' className='w-full' placeholder='Main Goals' options={mainGoals} value={mainGoalsSelected} onChange={handleMainlGoals} />
            </div>
            <div className="form--row gap-1">
                <div className='text-sm flex gap-1 align-items-center'><PiIdentificationCard /> Ages </div> 
                <MultiSelect optionValue='id' optionLabel='age' className='w-full' options={ages} onChange={handleAges} value={agesSelected} placeholder={`Player's Ages`} />
            </div>
            <div className="form--row gap-1">
                <div className='text-sm flex gap-1 align-items-center'><PiUsersThree /> Player Positions </div> 
                <MultiSelect optionValue='id' optionLabel='name' className='w-full' options={playerPositions} onChange={handlePlayerPositions} value={playerPositionsSelected} placeholder={`Player's Positions`} />
            </div>
            <div className="form--row gap-1">
                <div className='text-sm flex gap-1 align-items-center'><PiPersonSimpleThrow /> Technical </div> 
                <MultiSelect optionValue='id' optionLabel='name' name='technicals' className='w-full' placeholder='Technical Goals' options={technicalGoals} value={technicalGoalsSelected} onChange={handleTechnicalGoals} />
            </div>
            <div className="form--row gap-1">
                <div className='text-sm flex gap-1 align-items-center'><PiClipboard /> Tactical </div> 
                <MultiSelect optionValue='id' optionLabel='name' name='taticals' className='w-full' options={taticalGoals} value={taticalGoalsSelected} onChange={handleTaticalGoals} placeholder='Tactical Goals' />
            </div>
            <div className="form--row gap-1">
                <div className='text-sm flex gap-1 align-items-center'><PiBarbell /> Physical </div> 
                <MultiSelect optionValue='id' optionLabel='name' name='physicals' className='w-full' options={physicalGoals} value={physicalGoalsSelected} onChange={handlePhysicalGoals} placeholder='Physical Goals' />
            </div>
            <div className="form--row gap-1">
                <InputTextarea className='w-full' value={description} style={{height: '220px'}} placeholder='Drill Description' onChange={handleDescription} />
            </div>
            <div className="flex gap-2">
                <div className='flex flex-column flex-1 gap-1'>
                    <div className='flex align-items-center gap-1 text-sm'><PiClock /> Duration </div>
                    <InputText className='w-full' type='number' name='duration' placeholder='Duration (minutes)' value={duration} onChange={(e)=>setDuration(e.target.value)} />
                </div>
                <div className='flex flex-column flex-1 gap-1'>
                    <div className='flex align-items-center gap-1 text-sm'><PiLink /> Media</div>
                    <InputText className='w-full' type='text' name='url' placeholder='Media URL' value={url} onChange={(e)=>setUrl(e.target.value)} />
                </div>
            </div>
            {/* <div className="form--row">
                <div className="form--error-fieldset">
                    <YouTubeUploaderComponent />
                </div>
            </div> */}
            <div className="form--action_row">
                <Button loading={isLoading} type="submit" onClick={onSubmit}>Save</Button>
                <Button onClick={()=>navigate(-1)}>Cancel</Button>
            </div>
        </div>
    </AppBodyComponent>
  )
}

export default DrillForm