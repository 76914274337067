import React, { useContext, useEffect, useState } from 'react'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'
import { useNavigate, useParams } from 'react-router-dom'
import { deletePracticeService, getPracticeDetailsService, savePracticeFeedbackService } from '../../../services/PracticeService'
import { PiArticle, PiConfetti, PiGlobe, PiHouse, PiLock, PiStar, PiUsers, PiWarningCircle } from 'react-icons/pi'
import { Button } from 'primereact/button'
import AuthContext from '../../../context/AuthContext/AuthContext'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Dialog } from 'primereact/dialog'
import PracticeGoalsComponent from '../../../components/PracticeGoalsComponent'
import DrillDetails from '../Drill/DrillDetails'
import { DateTime } from 'luxon'
import LoadingSkeletonDetailsComponent from '../../../components/LoadingSkeletonDetailsComponent'
import { InputTextarea } from 'primereact/inputtextarea'
import { Rating } from 'primereact/rating'

function PracticeFeedbackForm() {
    const {access_key} = useParams()
    const [practiceDetails, setPracticeDetails] = useState()
    const [practiceDrills, setPracticeDrills] = useState()
    const [drillDetails, setDrillDetails] = useState()
    const [dialogDetailIsOpen, setDialogDetailIsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showThanks, setShowThanks] = useState(false)

    useEffect(()=>{
        access_key && getPracticeDetails()
    },[access_key])

    const getPracticeDetails = async () => {
        setIsLoading(true); // Set loading state to true
    
        try {
            if (access_key) {
                const response = await getPracticeDetailsService(access_key); // Await the API call
    
                if (response?.practice?.practice_drills) {
                    const updatedDrills = response.practice.practice_drills.map(e => ({
                        ...e,
                        feedback_comments: '',
                        feedback_rating: '',
                    }));
    
                    const updatedPractice = {
                        ...response.practice,
                        practice_drills: updatedDrills,
                    };
    
                    setPracticeDetails(updatedPractice);
                } else {
                    setPracticeDetails(response?.practice || {});
                }
                setPracticeDrills(response?.drills || null);
            }
        } catch (error) {
        } finally {
            setIsLoading(false); // Set loading state to false
        }
    };

    const getDrillDetails = (drillAccessKey) => {
        if (practiceDrills && practiceDrills.length > 0){
            const drillIndex = practiceDrills.findIndex(e=>e.access_key === drillAccessKey)
            drillIndex >= 0 ? setDrillDetails(practiceDrills[drillIndex]) : setDrillDetails(null)
            setDialogDetailIsOpen(true)
        }
    }

    const openDrillDetails = (drillAccessKey) => {
        getDrillDetails(drillAccessKey)
    }

    const changeComments = (_access_key, value) => {
        const _practice = {...practiceDetails}
        const drillIndex = _practice.practice_drills?.findIndex(e=>e.access_key === _access_key)
        if (drillIndex >= 0){
            _practice.practice_drills[drillIndex].feedback_comments = value
            setPracticeDetails(_practice)
        } 
    }

    const changeRating = (_access_key, value) => {
        const _practice = {...practiceDetails}
        const drillIndex = _practice.practice_drills?.findIndex(e=>e.access_key === _access_key)
        if (drillIndex >= 0){
            _practice.practice_drills[drillIndex].feedback_rating = value
            setPracticeDetails(_practice)
        } 
    }

    const saveFeedback = () => {
        setIsLoading(true)
        savePracticeFeedbackService(practiceDetails.practice_drills).then(response=>{
            response && setShowThanks(true)
        })
        setIsLoading(false)
    }


    
    
    return (
        <AppBodyComponent activeMenuNumber={2}>
            <div className='font-bold text-4xl mb-4'>
                Practice Feedback
            </div>
            {showThanks ? 
            <div className='flex flex-column gap-4 justify-content-center'>
                <div className='font-bold text-lg text-center'><PiConfetti size={58} /></div>
                <div className='font-bold text-lg text-center'>Thank you!</div>
                <div className='font-bold text-lg text-center'>Your feedback was submited.</div>
                <div className='font-bold text-base text-center'>You can close this window</div>
            </div>
            : isLoading ? <LoadingSkeletonDetailsComponent /> : practiceDetails ? 
            <div className='flex flex-column gap-2'>
                <div className='flex gap-2 justify-content-between'>
                    <div className='flex gap-2'>
                        <div className='flex gap-1 flex-wrap'>
                            <span className='font-bold'>Date: </span> {DateTime.fromISO(practiceDetails.date, { zone: 'UTC' }).toFormat('MM/dd/yyyy')}
                        </div>
                    </div>
                </div>
                <div className='flex gap-2 justify-content-between'>
                    <div className='flex gap-1 flex-wrap'>
                        <span className='font-bold'>Team: </span> {practiceDetails.team_name}
                    </div>
                </div>
                <div className='font-bold mt-2'>
                    Practice's Drills
                </div>
                {practiceDetails.practice_drills ? practiceDetails.practice_drills.map((drill, key)=>(
                    <div key={key} className='flex flex-column gap-1 mb-2 text-sm md:text-base'>
                        <div className='flex gap-2 justify-content-between align-items-center'>
                            <div className='flex gap-2'>
                                <div className=''>
                                    {drill.drill_order}.
                                </div>
                                <div className='font-bold'>
                                    {drill.title}
                                </div>
                            </div>
                            <div className='flex gap-2 align-items-center'>
                                <div className='white-space-nowrap'>
                                    {drill.duration} min
                                </div>                                    
                                <Button size='small' onClick={()=>openDrillDetails(drill.access_key)} icon='pi pi-info-circle' />
                            </div>
                        </div>
                        <div className='flex gap-2 pl-3 flex-wrap'>
                            <div className='flex flex-column flex-0 gap-1'>
                                <div className='flex align-items-center gap-1 font-bold'><PiStar /> Rating</div>
                                <Rating value={drill.feedback_rating} onChange={(e)=>changeRating(drill.access_key, e.target.value)} />
                            </div>
                            <div className='flex flex-column flex-1 gap-1 min-w-max'>
                                <div className='flex align-items-center gap-1 font-bold'><PiArticle /> Comments</div>
                                <InputTextarea value={drill.feedback_comments} onChange={(e)=>changeComments(drill.access_key, e.target.value)} 
                                className='w-full h-full' />
                            </div>
                        </div>
                    </div>
                )) : '-'}
                <div className='flex justify-content-center flex-1'>
                    <Button label='Send' onClick={saveFeedback} loading={isLoading} />
                </div>
            </div> : 'Practice not found'}









            <Dialog className='w-11 md:w-8' header="Drill Details" visible={dialogDetailIsOpen} onHide={() => setDialogDetailIsOpen(false)}
                footer={
                    <div>
                        <Button label="Close" icon="pi pi-times" onClick={()=>setDialogDetailIsOpen(false)} className="p-button-text" />
                    </div>
                    }>
                    {drillDetails ? 
                    <DrillDetails access_key={drillDetails.access_key} canDeleteUpdate={false} /> : 'Drill not found'}
                </Dialog>
        </AppBodyComponent>
    )
}

export default PracticeFeedbackForm