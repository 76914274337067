import React, { useContext, useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'
import AuthContext from '../context/AuthContext/AuthContext'
import TeamAutocompleteComponent from './TeamAutocompleteComponent/TeamAutocompleteComponent'
import { listJournalByUserAccessKey } from '../services/PracticeService'
import { PiArticle } from 'react-icons/pi'
import { Divider } from 'primereact/divider'
import { useNavigate } from 'react-router-dom'

function DashboardJournal() {
    const {userAuth} = useContext(AuthContext)
    const navigate = useNavigate()
    const [practiceJournal, setPracticeJournal] = useState()
    const [practiceTotalDuration, setPracticeTotalDuration] = useState(0)
    const getDaysDiff = (days) => {
        const now = new Date();
        const weekAgo = new Date(now.getTime() + days * 24 * 60 * 60 * 1000);
        weekAgo.setHours(0, 0, 0, 0)
        return weekAgo;
    };
    const [startDate, setStartDate] = useState(getDaysDiff(-7))
    const [endDate, setEndDate] = useState(getDaysDiff(7))
    const [teamSelected, setTeamSelected] = useState()
    const [modalIsOpen, setModalIsOpen] = useState(false)

    useEffect(()=>{
        listMyJournal()
    },[])

    const listMyJournal = () => {
        listJournalByUserAccessKey(userAuth?.user?.access_key, startDate, endDate, teamSelected).then((response)=>{
            if (!response?.data) return
            setPracticeJournal(response.data)
            setPracticeTotalDuration(response.total_duration)
        })
        setModalIsOpen(false)
    } 

    const handleCancelFilter = () => {
        setModalIsOpen(!modalIsOpen)
    }

    const handleModalIsOpen = () => {
        setModalIsOpen(!modalIsOpen)
    }

    const footer = () => {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={handleCancelFilter} className="p-button-text" />
                <Button label="Apply" icon="pi pi-check" onClick={listMyJournal} autoFocus />
            </div>
        )
    }

    return (
        <div>
            <div className='flex flex-column gap-2'>
                <Button  onClick={handleModalIsOpen} icon='pi pi-search' label={'Search Practices'} className='w-max' />

                <div>
                    Practices total duration: <span className='font-bold'>{practiceTotalDuration} min</span>
                </div>






                <div>
                    {practiceJournal && practiceJournal.length > 0 ? 
                        practiceJournal.map((practiceDetails, key) => (
                        <div key={key} className='flex flex-column gap-2'>
                            <div className='flex gap-2 justify-content-between text-sm'>
                                <div className='flex flex-column'>
                                    Date: <span className=''>{new Date(practiceDetails.date).toLocaleDateString()}</span>
                                </div>
                                <div className='flex flex-column'>
                                    Team: <span className=''>{practiceDetails.team_name}</span>
                                </div>
                                <div className='flex flex-column'>
                                    Duration: <span className=''>{practiceDetails.duration} min</span>
                                </div>
                                <Button icon='pi pi-pencil' onClick={()=>navigate('/app/update_practice/'+practiceDetails.access_key)} size='small'/>
                            </div>
                            <div className='text-sm mt-2'>
                                Practice's Drills
                            </div>
                            {practiceDetails.practice_drills ? practiceDetails.practice_drills.map((drill, key)=>(
                                <div key={key} className='flex flex-column gap-1 mb-2'>
                                    <div className='flex gap-2 justify-content-between align-items-center text-sm'>
                                        <div className='flex gap-2'>
                                            <div className=''>
                                                {drill.drill_order}.
                                            </div>
                                            <div className='font-bold'>
                                                {drill.title}
                                            </div>
                                        </div>
                                        <div className='flex gap-2 align-items-center'>
                                            <div className='white-space-nowrap'>
                                                {drill.duration} min
                                            </div>                                    
                                        </div>
                                    </div>
                                    <div className='flex flex-column gap-1 pl-3'>
                                        <div className='flex align-items-center gap-1 font-bold'><PiArticle /> Comments</div>
                                        <div dangerouslySetInnerHTML={{__html: drill.comments ? drill.comments.replace(/\n/g, '<br>') : '-'}} />
                                    </div>
                                </div>
                            )) : '-'}                    
                        <Divider />
                    </div>)) : '-'
                }
                </div>
            </div>
                
            <Dialog className='w-11 md:w-8' header="Filter" visible={modalIsOpen} onHide={() => setModalIsOpen(false)}
                footer={footer}>
                <div className='flex gap-2 justify-content-between align-items-end'>
                    <div className='flex flex-column gap-1 flex-1'>
                        <div className='text-sm'>Team</div>
                        <TeamAutocompleteComponent setTeam={(e)=>setTeamSelected(e)} team={teamSelected} />
                    </div>
                    <div className='flex flex-column gap-1'>
                        <div className='text-sm'>Start Date</div>
                        <Calendar className='w-8rem' value={startDate} onChange={(e)=>setStartDate(e.value)} />
                    </div>
                    <div className='flex flex-column gap-1'>
                        <div className='text-sm'>End Date</div>
                        <Calendar className='w-8rem' value={endDate} onChange={(e)=>setEndDate(e.value)} />
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default DashboardJournal