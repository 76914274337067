import React, { useContext, useState } from 'react'
import Navbar from '../../../components/Navbar/Navbar'
import Footer from '../../../components/Footer/Footer'
import './Login.css'
import LoginImage from '../../../images/practice_screenshot.png'
import { useNavigate } from 'react-router-dom'
import { PiFacebookLogoBold, PiGoogleLogoBold } from 'react-icons/pi'
import AuthContext from '../../../context/AuthContext/AuthContext'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Check } from '@phosphor-icons/react'
import IPData from 'ipdata'
import { useForm } from 'react-hook-form'
import { jwtDecode } from 'jwt-decode'
import { RequestType, geocode } from 'react-geocode'
import { toast } from 'react-toastify'
import { GoogleLogin } from '@react-oauth/google'
import ReactGA from 'react-ga4'

function Login() {
    ReactGA.send({ hitType: "pageview", page: "/login", title: "Login User" });

    const navigate = useNavigate();
    const {userLogin, googleLogin} = useContext(AuthContext);
    const [login, setLogin] = useState({email: '', password: ''})
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        await userLogin(login.email, login.password).then((response)=>{
            // response && navigate('/app/home')
            response && navigate('/app/practice_list')
        })
        setIsLoading(false)
    }

    const handleLogin = (e) => {
        setLogin((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const handleGoogleSignUp = async (response) => {
        setIsLoading(true)
        const googleUser = jwtDecode(response.credential)

        await googleLogin(googleUser.email).then((response)=>{
            // response && navigate('/app/home')
            response && navigate('/app/practice_list')
        }).catch((error) => {
            toast.error('An error ocurred.')
        })
        setIsLoading(false)


    }

    const handleGoogleError = (response) => {
        console.log(response)
    }


  return (
    <div>
        <Navbar />
            <div className="login--container">
                <div className="login--content">
                    <img src={LoginImage} alt="pins map" className='login--image' />
                    <div className="login--form">
                        <div className="text--heading--h2" style={{paddingBottom: '12px'}}>Log In</div>
                        <GoogleLogin onSuccess={handleGoogleSignUp} onError={handleGoogleError} text='Sign in with Google' />
                        <div className="login--separator">or</div>
                        {/* 
                        <button type="button" className='button--social facebook'>
                            <PiFacebookLogoBold size={16} />
                            Log In with Facebook
                        </button>
                        <div className="login--separator">or</div> */}
                        <form onSubmit={handleSubmit} method="post" className='text--regular--normal'>
                            <div className="form--fieldset">
                                <label htmlFor="email">Email</label>
                                <InputText type="email" name="email" id="email" className='w-full' onChange={handleLogin} />
                            </div>
                            <div className="form--fieldset">
                                <label htmlFor="password">Password</label>
                                <InputText type="password" name="password" id="password" onChange={handleLogin} />
                            </div>
                            <div className="form--action_row">
                                <Button loading={isLoading} type="submit" label='Log In' />
                            </div>
                            <div className="form--action_row">
                                <Button text severity='primary' onClick={()=>navigate('/register')} type="button" label='Register' />
                            </div>
                            <div className="form--action_row">
                                <Button text severity='secondary' onClick={()=>navigate('/forgot_password')} type="button" label='Forgot password?' />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        <Footer />
    </div>
  )
}

export default Login