import { Button } from 'primereact/button'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../../context/AuthContext/AuthContext'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { PiWarningCircle } from 'react-icons/pi'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { DotsSix, PresentationChart, UsersThree } from '@phosphor-icons/react'
import { deleteTeam, listTeamByCreateBy } from '../../../services/TeamService'
import AppBodyComponent from '../../../components/AppBody/AppBodyComponent'
import { Panel } from 'primereact/panel'

function MyTeams() {
    const navigate = useNavigate()
    const {userAuth} = useContext(AuthContext)
    const [matches, setMatches] = useState()

    useEffect(()=>{
        userAuth && handleListTeams()
    },[])

    const handleListTeams = () => {
        listTeamByCreateBy(userAuth.user.access_key).then((res)=>{
            setMatches(res)
        })
    }

    const handleConfirmDelete = (item) => {
        confirmDialog({
            message: `Do you want to delete the team ${item.name}?`,
            header: 'Confirm?',
            icon: <PiWarningCircle />,
            defaultFocus: 'accept',
            accept: ()=>accept(item),
            reject
        });
    }

    const reject = () => {
    }

    const accept = (item) => {
        deleteTeam(item.access_key).then(response=>{
            response && handleListTeams()
        })
    }

    const handleAddTeam = () => {
        navigate('/app/create_team')
    }
    
    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Name" align={'center'} />
                <Column header="Actions" align={'center'} />
            </Row>
        </ColumnGroup>
    );

    return (
        <AppBodyComponent activeMenuNumber={4}>
            <ConfirmDialog />
            <div className='text-4xl font-bold'>Teams</div>
            <Panel unstyled>
                <div className="flex justify-content-between flex-wrap p-2 bg-blue-900 w-full border-round gap-2">
                    <div className="flex align-content-center flex-wrap gap-2">
                        <Button onClick={handleAddTeam} label='Create Team' icon='pi pi-plus' />
                    </div>                    
                </div>
            </Panel>
            <div className='flex flex-column gap-2'>
                <div>
                    <div className='font-bold text-lg my-4'>
                        Teams List
                    </div>
                    <DataTable showGridlines stripedRows value={matches} headerColumnGroup={headerGroup} tableStyle={{ minWidth: '10rem' }}>
                        <Column align={'left'} field="name" className='p-2' />
                        <Column className='p-2' align={'right'} body={(_team)=>
                            <div className='flex gap-1 justify-content-center gap-2'>
                                <Button icon='pi pi-pencil' onClick={(e)=>navigate('/app/update_team/'+_team.access_key)} />
                                <Button severity='danger' icon='pi pi-trash' onClick={(e)=>handleConfirmDelete(_team)} />
                                {/* <a href={`/app/show_team_stats/${_team.access_key}`} target="_blank" rel="noopener noreferrer" className="p-button font-bold p-2">
                                    <PresentationChart size={24} style={{color: 'white'}} />
                                </a>
                                <a href={`/app/show_team_rotations_date_stats/${_team.access_key}`} target="_blank" rel="noopener noreferrer" className="p-button font-bold p-2">
                                    <DotsSix className='border-1' size={24} style={{color: 'white'}} />
                                </a>
                                <a href={`/app/show_history_stats/${_team.access_key}`} target="_blank" rel="noopener noreferrer" className="p-button font-bold p-2">
                                    <UsersThree size={24} style={{color: 'white'}} />
                                </a> */}
                            </div>
                        } />
                    </DataTable>
                </div>
            </div>
        </AppBodyComponent>
    )
}

export default MyTeams