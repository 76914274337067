import React from 'react'
import { Skeleton } from 'primereact/skeleton';

function LoadingSkeletonListComponent() {
    return (
        <div className='flex flex-column gap-2 border-1 border-round gap-2 p-2'>
            <Skeleton className="w-8"></Skeleton>

            <Skeleton className="w-2"></Skeleton>
            <Skeleton className="w-3"></Skeleton>

            <Skeleton className="w-8 h-15rem align-self-center"></Skeleton>

            <Skeleton className="w-3"></Skeleton>
            <Skeleton className="w-5"></Skeleton>

            <Skeleton className="w-2"></Skeleton>
            <Skeleton className="w-6"></Skeleton>
        </div>
    )
}

export default LoadingSkeletonListComponent