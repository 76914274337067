import React, { useContext, useEffect, useState } from 'react'
import './PracticeList.css'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel'
import AuthContext from '../../../../context/AuthContext/AuthContext'
import { listPracticeByUserAccessKey } from '../../../../services/PracticeService'
import { PiGlobe, PiHouse, PiLock, PiUsers } from 'react-icons/pi'
import { Calendar } from 'primereact/calendar'
import PaginatorComponent from '../../../../components/PaginatorComponent/PaginatorComponent'
import TeamAutocompleteComponent from '../../../../components/TeamAutocompleteComponent/TeamAutocompleteComponent'
import AppBodyComponent from '../../../../components/AppBody/AppBodyComponent'
import { DateTime } from 'luxon'
import LoadingSkeletonListComponent from '../../../../components/LoadingSkeletonListComponent'

function PracticeList() {
    const navigate = useNavigate()
    const {userAuth} = useContext(AuthContext)
    const [practices, setPractices] = useState()
    const getDaysDiff = (days) => {
        const now = new Date();
        const weekAgo = new Date(now.getTime() + days * 24 * 60 * 60 * 1000);
        weekAgo.setHours(0, 0, 0, 0)
        return weekAgo;
    };
    const [startDate, setStartDate] = useState(getDaysDiff(-7))
    const [endDate, setEndDate] = useState(getDaysDiff(7))
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(1)
    const [practiceTotalDuration, setPracticeTotalDuration] = useState(0)
    const [drillsSelected, setDrillsSelected] = useState()
    const [teamSelected, setTeamSelected] = useState()
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{
        if (practices){
            const practiceDrills = practices?.flatMap(e=>e.practice_drills)
            setDrillsSelected(practiceDrills)
        }
    },[practices])

    useEffect(()=>{
        if (drillsSelected){
            setPracticeTotalDuration(
                drillsSelected ? 
                drillsSelected?.map(e=>(e && e?.duration) ? Number(e.duration) : 0).reduce((sum, duration) => sum + Number(duration), 0) 
            : 0)
        }
    },[drillsSelected])

    useEffect(()=>{
        listMyPractices()
    },[page])

    const listMyPractices = async () =>{
        setIsLoading(true)
        userAuth?.user?.access_key && await listPracticeByUserAccessKey(userAuth?.user?.access_key, page, startDate, endDate, teamSelected).then(response=>{
            setPractices(response?.data)
            setTotal(response?.total)
        })
        setModalIsOpen(false)
        setIsLoading(false)
    }

    const handleAddPractice = () => {
        navigate('/app/create_practice')
    }

    const showIconShareLevel = (share_level) => {
        return(
            share_level === 1 ? <PiLock size={18} /> :
            share_level === 2 ? <PiUsers size={18} /> :
            share_level === 3 ? <PiHouse size={18} /> :
            share_level === 4 ? <PiGlobe size={18} /> : ''
        )
    }

    const handleCancelFilter = () => {
        setModalIsOpen(!modalIsOpen)
    }

    const handleModalIsOpen = () => {
        setModalIsOpen(!modalIsOpen)
    }

    const footer = () => {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={handleCancelFilter} className="p-button-text" />
                <Button label="Apply" icon="pi pi-check" onClick={listMyPractices} autoFocus />
            </div>
        )
    }
    
    return (
        <AppBodyComponent activeMenuNumber={2}>
        <div className='flex flex-column gap-2'>
            <div className='text-4xl font-bold'>Practices</div>
            <Panel unstyled>
                <div className="flex justify-content-between flex-wrap p-2 bg-blue-900 w-full border-round gap-2">
                    <div className="flex align-content-center flex-wrap gap-2">
                        <Button onClick={handleAddPractice} label='Create' icon='pi pi-plus' />
                    </div>
                    
                    <Button onClick={handleModalIsOpen} icon='pi pi-search' label={'Search'} />
                </div>
            </Panel>
            <div className='flex flex-column gap-2'>
                <Dialog className='w-11 md:w-8' header="Filter" visible={modalIsOpen} onHide={() => setModalIsOpen(false)}
                    footer={footer}>
                    <div className='flex flex-column gap-2 pt-4'>
                        <div className='flex flex-column gap-1 flex-1'>
                            <div className='text-sm'>Team</div>
                            <TeamAutocompleteComponent setTeam={(e)=>setTeamSelected(e)} team={teamSelected} />
                        </div>
                        <div className='flex gap-2 justify-content-between'>
                            <div className='flex flex-column gap-1'>
                                <div className='text-sm'>Start Date</div>
                                <Calendar className='w-8rem' value={startDate} onChange={(e)=>setStartDate(e.value)} />
                            </div>
                            <div className='flex flex-column gap-1'>
                                <div className='text-sm'>End Date</div>
                                <Calendar className='w-8rem' value={endDate} onChange={(e)=>setEndDate(e.value)} />
                            </div>
                        </div>
                    </div>
                </Dialog>
                <div className='text-lg mt-4'>
                    <span className='font-bold'>Practice List:</span> {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
                </div>
                <div>
                    Team selected: {teamSelected?.name || 'no team selected'}
                </div>
                <div>
                    {/* Total duration: <span className='font-bold'>{practiceTotalDuration} min</span> */}
                </div>
                {isLoading ? <LoadingSkeletonListComponent /> : practices && practices?.length > 0 ? practices?.map((element,key)=>(
                    <div key={key} className='border-1 border-solid border-round p-2 text-sm md:text-base'>
                        <div className='flex gap-2 justify-content-between align-items-start  md:align-items-center'>
                            <div className='flex gap-2 align-items-start md:align-items-center'>
                                <div className='pt-1'>{showIconShareLevel(element.share_level)}</div>
                                
                                <div className='flex gap-1 flex-wrap'><span className='font-bold'>Date: </span> {DateTime.fromISO(element.date, { zone: 'UTC' }).toFormat('MM/dd/yyyy')}</div>
                                <div className='flex gap-1 flex-wrap'><span className='font-bold'>Team: </span> {element.team_name ? element.team_name : '-'}</div>
                            </div>
                            <div>
                                <Button icon='pi pi-info-circle' onClick={()=>navigate('/app/practice_details/'+element.access_key)}/>
                            </div>
                        </div>
                        <div className='flex flex-column gap-2'>
                            <div className='font-bold mt-2'>
                                Practice's Drills
                            </div>
                            {element.practice_drills ? element.practice_drills.map((drill, key)=>(
                                <div key={key} className='flex gap-2 justify-content-between'>
                                    <div className='flex gap-2'>
                                        <div className=''>
                                            {drill.drill_order}.
                                        </div>
                                        <div className=''>
                                            {drill.title}
                                        </div>
                                    </div>
                                    <div className='white-space-nowrap'>
                                        {drill.duration} min
                                    </div>                                    
                                </div>
                            )) : '-'}
                        </div>
                    </div>
                )):
                <div className='flex flex-column gap-4 m-4'>
                    <div className='font-bold text-2xl text-center'>No practices found.</div>
                    <div className='flex justify-content-center'>
                        <Button onClick={handleAddPractice} size='large' label='Create Practice' icon='pi pi-plus' />
                    </div>
                </div>}
            </div>
            <PaginatorComponent page={page} total={total} setPage={setPage} />
        </div>
        </AppBodyComponent>
    )
}

export default PracticeList