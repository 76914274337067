import React, { useContext, useState } from 'react'
import AppHead from '../AppHead/AppHead'
import AppNavbar from '../AppNavbar/AppNavbar'
import './AppBodyComponent.css'
import AuthContext from '../../context/AuthContext/AuthContext'
import { Dialog } from 'primereact/dialog'
import { sendEmailNewMessage } from '../../services/UserService'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import GoogleAdComponent from '../GoogleAdComponent'

function AppBodyComponent({children, activeMenuNumber=1}) {
    const {userAuth} = useContext(AuthContext);
    const [message, setMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const handleCancelMessage = () => {
        setMessage('')
        setModalIsOpen(false)
    }

    const handleConfirmMessage = () => {
        setModalIsOpen(false)
        sendEmailNewMessage(
            message, userAuth?.user.access_key,
            `${userAuth?.user.first_name} ${userAuth?.user.last_name}`, 
            'info@leagr.net', 
            userAuth?.user.email)
    }

    return (
    <div>
        <AppHead activeMenuNumber={activeMenuNumber} />

        <div className="app--container">
            <AppNavbar activeMenuNumber={activeMenuNumber} />
            <div className="app--content">
                <GoogleAdComponent slot={'9575219642'} />
                {children}
            </div>
        </div>
        {userAuth?.user && 
        <>
            <Dialog header='Contact us' visible={modalIsOpen} onHide={() => setModalIsOpen(false)}
            footer={
                <div>
                    <Button label="Cancel" icon="pi pi-times" onClick={handleCancelMessage} className="p-button-text" />
                    <Button label="Send" icon="pi pi-check" onClick={handleConfirmMessage} autoFocus />
                </div>
            }>
                <div className='flex flex-column'>
                    <label className='text--regular--normal'>Send your message to us.</label>
                    <InputTextarea value={message} onChange={(e) => setMessage(e.target.value)} rows={5} cols={30} />
                </div>
            </Dialog>
        </>}
    </div>
)
}

export default AppBodyComponent