import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'primereact/button';
import { PiArrowClockwise, PiCaretLeft, PiArrowsLeftRight, PiNotePencil,  PiEraser, PiSquaresFour, PiUserSound } from 'react-icons/pi';
import StatisticsContext from '../StatisticsContext';
import MiniCourtRotationComponent from './MiniCourtRotationComponent';
import RallyComponent from './RallyComponent';
import { useNavigate } from 'react-router-dom'
import ManageTeamComponent from './ManageTeamComponent/ManageTeamComponent';


function MenuButtonsComponent(props) {
    const {gridData,
        showSelectPosition, 
        setShowSelectionPosition,
        activeTeam, setActiveTeam, currentActionRefContext, setMatchContext, setSetMatchContext, 
        enabledAutoRotationContext, setEnabledAutoRotationContext, speachMode, setSpeachMode} = useContext(StatisticsContext)
    const [statsDataURL, setStatsDataURL] = useState('')
    const navigate = useNavigate()

    const handleSetMatch = () => {
        setSetMatchContext(setMatchContext === 5 ? 1 : setMatchContext + 1)
    }

    const switchActiveTeam = () => {
        currentActionRefContext?.current.number === "ser" && activeTeam === 1 ? props.setTeamServing(gridData.teamB) : props.setTeamServing(gridData.teamA)
        activeTeam === 1 ? setActiveTeam(2) : setActiveTeam(1)
    }

    useEffect(()=>{
        gridData?.statisticsType && handleShowData()
    },[gridData?.statisticsType])

    const handleShowData = () => {
        if (gridData?.statisticsType === 'full' || gridData?.statisticsType === 'game'){
            setStatsDataURL(`/app/rotations_stats/${gridData?.matchAccessKey}`)
        } else if (gridData?.statisticsType === 'player'){
            setStatsDataURL('/app/show_stats')
        }
    }

    const restartRally = () => {
        props.restartRally()
    }

    return (
        <div className={`flex gap-4 justify-content-between ${!props.gridLayout ? 'flex-column': ''}`}>
            <div className='flex flex-1 flex-column gap-2'>
                <div className='flex flex-wrap w-full gap-2 justify-content-between'>
                    <div className='flex flex-1'>
                        <Button size='small' className={"w-max h-min p-2"} onClick={()=>navigate('/app/stats_home')}><PiCaretLeft size={18} /></Button>
                    </div>
                    <div className='flex flex-0 gap-2 justify-content-between'>
                        {gridData?.statisticsType === 'player' && 
                        <Button size='small' className={"w-max h-min p-2"} onClick={()=>setShowSelectionPosition(!showSelectPosition)}><PiNotePencil size={18} /></Button> }
                        <Button size='small' className={"w-max h-min p-2"} onClick={restartRally}><PiEraser size={18} /></Button>
                        <Button size='small' className={"w-max h-min p-2"} onClick={handleSetMatch}>{setMatchContext} set</Button>
                        <Button size='small' className={"w-max h-min p-2"} onClick={()=>setSpeachMode(!speachMode)}>
                            {speachMode ? <PiSquaresFour size={18} /> : <PiUserSound size={18} />}
                        </Button>
                        {/* <Button size='small' className={"w-max h-min p-2"} onClick={()=>props.setGridLayout(!props.gridLayout)}>{props.gridLayout ? <PiColumns size={18} /> : <PiRows size={18} />}</Button> */}
                        <ManageTeamComponent />
                        <Button size='small' className={`w-max h-min p-2 ${enabledAutoRotationContext ? 'bg-green-400' : 'bg-red-400'}`} onClick={()=>setEnabledAutoRotationContext(!enabledAutoRotationContext)}><PiArrowClockwise className='-rotate-90' size={18} /></Button>
                        {gridData.teamB && 
                        <Button size='small' className={"w-max h-min p-2"} onClick={switchActiveTeam}><PiArrowsLeftRight size={18} /></Button>}
                        <RallyComponent saveRally={props.saveRally} />
                    </div>
                </div>
                {/* <div className='font-bold text-4xl text-center'>{`${currentActionRefContext?.current?.label ? currentActionRefContext?.current.label : ''} ${props?.courtType}`}</div> */}
            </div>

            <div className='flex flex-wrap w-full gap-2'>
                <MiniCourtRotationComponent teamAorB={1} />
                {gridData?.teamB?.id &&                 
                    <MiniCourtRotationComponent teamAorB={2} />
                }
            </div>
        </div>
)
}

export default MenuButtonsComponent