import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

import HomeLandingPage from './pages/landing_pages/HomeLandingPage/HomeLandingPage'
import Login from './pages/landing_pages/Login/Login';
import Register from './pages/landing_pages/Register/Register';
import Home from './pages/app_pages/Home/Home';
import Profile from './pages/app_pages/Profile/Profile';
import ProfileForm from './pages/app_pages/Profile/ProfileForm/ProfileForm';
import ProfileSkill from './pages/app_pages/Profile/ProfileSkill/ProfileSkill';
import ProfileSkillForm from './pages/app_pages/Profile/ProfileSkill/ProfileSkillForm';
import ProfileHistory from './pages/app_pages/Profile/ProfileHistory/ProfileHistory';
import ProfileHistoryForm from './pages/app_pages/Profile/ProfileHistory/ProfileHistoryForm';
import ProfileMediaForm from './pages/app_pages/Profile/ProfileMedia/ProfileMediaForm';
import ProfileMedia from './pages/app_pages/Profile/ProfileMedia/ProfileMedia';
import EventList from './pages/app_pages/Event/EventList/EventList';
import EventForm from './pages/app_pages/Event/EventForm/EventForm';
import EventDetail from './pages/app_pages/Event/EventDetail/EventDetail';
import PlayerList from './pages/app_pages/Player/PlayerList/PlayerList';
import PlayerDetail from './pages/app_pages/Player/PlayerDetail/PlayerDetail';
import PlayerDetailSkill from './pages/app_pages/Player/PlayerDetail/PlayerDetailSkill/PlayerDetailSkill';
import PlayerDetailHistory from './pages/app_pages/Player/PlayerDetail/PlayerDetailHistory/PlayerDetailHistory';
import PlayerDetailMedia from './pages/app_pages/Player/PlayerDetail/PlayerDetailMedia/PlayerDetailMedia';
import PlayerDetailAbout from './pages/app_pages/Player/PlayerDetail/PlayerDetailAbout/PlayerDetailAbout';
import ChatList from './pages/app_pages/Chat/ChatList';
import ChatDetail from './pages/app_pages/Chat/ChatDetail';
import Setting from './pages/app_pages/Setting/Setting';
import ForgotPassword from './pages/landing_pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/landing_pages/ResetPassword/ResetPassword';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import PrivacyPolice from './pages/landing_pages/PrivacyPolice/PrivacyPolice';
import Welcome from './pages/app_pages/Welcome/Welcome';
import LeagueDetail from './pages/app_pages/League/LeagueDetail';
import EmailUnsubscribe from './pages/landing_pages/EmailUnsubscribe/EmailUnsubscribe';
import AdminArea from './pages/app_pages/AdminArea/AdminArea';
import { HelmetProvider } from 'react-helmet-async';
import Statistics from './pages/app_pages/Statistics/Statistics';
import StartStats from './pages/app_pages/Statistics/StartStats';
import { StatisticsProvider } from './pages/app_pages/Statistics/StatisticsContext';
import MyStatsHome from './pages/app_pages/Statistics/MyStatsHome';
import ShowPlayerStats from './pages/app_pages/Statistics/ShowPlayerStats';
import ShowGameStats from './pages/app_pages/Statistics/ShowGameStats';
import ShowPracticeStats from './pages/app_pages/Statistics/ShowPracticeStats';
import ShowTeamStats from './pages/app_pages/Statistics/ShowTeamStats';
import ShowTeamRotationsDateStats from './pages/app_pages/Statistics/ShowTeamRotationsDateStats';
import ShowHistoryStats from './pages/app_pages/Statistics/ShowHistoryStats';
import DrillDetails from './pages/app_pages/Drill/DrillDetails';
import DrillForm from './pages/app_pages/Drill/DrillForm';
import PracticeForm from './pages/app_pages/Practice/PracticeForm';
import PracticeDetails from './pages/app_pages/Practice/PracticeDetails';
import PracticeList from './pages/app_pages/Practice/PracticeList/PracticeList';
import DrillList from './pages/app_pages/Drill/DrillList';
import UserDashboard from './pages/app_pages/UserDashboard';
import MyTeams from './pages/app_pages/Team/MyTeams';
import TeamForm from './pages/app_pages/Team/TeamForm';
import MyStats from './pages/app_pages/Statistics/MyStats';
import AddStatsPlayerComponent from './pages/app_pages/Statistics/AddStatsPlayerComponent';
import AddStatsTeamComponent from './pages/app_pages/Statistics/AddStatsTeamComponent';
import PracticeFeedbackForm from './pages/app_pages/Practice/PracticeFeedbackForm';
import Tips from './pages/app_pages/Tips';

function App() {
  const helmetContext = {}

  return (
    <HelmetProvider context={helmetContext}>

      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<HomeLandingPage />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/forgot_password' element={<ForgotPassword />} />
          <Route exact path='/reset_password/:code' element={<ResetPassword />} />
          <Route exact path='/register' element={<Register />} />
          <Route exact path='/privacy_police' element={<PrivacyPolice />} />
          <Route exact path='/email_unsubscribe/:code' element={<EmailUnsubscribe />} />
          
          <Route exact path='/app/admin_area' element={<ProtectedRoute><AdminArea /></ProtectedRoute>} />
          <Route exact path='/app/home' element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route exact path='/app/welcome' element={<ProtectedRoute><Welcome /></ProtectedRoute>} />

          <Route exact path='/app/event_list' element={<ProtectedRoute><EventList /></ProtectedRoute>} />
          <Route exact path='/app/event_form' element={<ProtectedRoute><EventForm /></ProtectedRoute>} />
          <Route exact path='/app/event_form/:access_key' element={<ProtectedRoute><EventForm /></ProtectedRoute>} />
          <Route exact path='/app/event_detail/:access_key' element={<ProtectedRoute><EventDetail /></ProtectedRoute>} />

          <Route exact path='/:access_key' element={<PlayerDetailAbout />} />
          <Route exact path='/app/player_list' element={<ProtectedRoute><PlayerList /></ProtectedRoute>} />
          <Route exact path='/app/player_detail_about/:access_key' element={<PlayerDetailAbout />} />
          <Route exact path='/app/player_detail_history/:access_key' element={<PlayerDetailHistory />} />
          <Route exact path='/app/player_detail_media/:access_key' element={<PlayerDetailMedia />} />
          <Route exact path='/app/player_detail_skill/:access_key' element={<PlayerDetailSkill />} />

          <Route exact path='/app/profile_view' element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route exact path='/app/profile_form' element={<ProtectedRoute><ProfileForm /></ProtectedRoute>} />
          <Route exact path='/app/profile_skill' element={<ProtectedRoute><ProfileSkill /></ProtectedRoute>} />
          <Route exact path='/app/profile_skill_form' element={<ProtectedRoute><ProfileSkillForm /></ProtectedRoute>} />
          <Route exact path='/app/profile_history' element={<ProtectedRoute><ProfileHistory /></ProtectedRoute>} />
          <Route exact path='/app/profile_history_form' element={<ProtectedRoute><ProfileHistoryForm /></ProtectedRoute>} />
          <Route exact path='/app/profile_history_form/:access_key' element={<ProtectedRoute><ProfileHistoryForm /></ProtectedRoute>} />
          <Route exact path='/app/profile_media' element={<ProtectedRoute><ProfileMedia /></ProtectedRoute>} />
          <Route exact path='/app/profile_media_form' element={<ProtectedRoute><ProfileMediaForm /></ProtectedRoute>} />
          <Route exact path='/app/profile_media_form/:access_key' element={<ProtectedRoute><ProfileMediaForm /></ProtectedRoute>} />

          <Route exact path='/app/chat_list' element={<ProtectedRoute><ChatList /></ProtectedRoute>} />
          <Route exact path='/app/chat_detail' element={<ProtectedRoute><ChatDetail /></ProtectedRoute>} />

          <Route exact path='/app/settings' element={<ProtectedRoute><Setting /></ProtectedRoute>} />

          <Route exact path='/app/league/:access_key' element={<LeagueDetail />} />

          <Route exact path='/app/statistics' element={<ProtectedRoute><StatisticsProvider><Statistics /></StatisticsProvider></ProtectedRoute>} />
          <Route exact path='/app/statistics/:access_key' element={<ProtectedRoute><StatisticsProvider><Statistics /></StatisticsProvider></ProtectedRoute>} />
          <Route exact path='/app/start_stats' element={<ProtectedRoute><StatisticsProvider><StartStats /></StatisticsProvider></ProtectedRoute>} />
          <Route exact path='/app/show_stats' element={<ProtectedRoute><ShowPlayerStats /></ProtectedRoute>} />
          <Route exact path='/app/show_team_rotations_date_stats/:team_access_key' element={<ProtectedRoute><ShowTeamRotationsDateStats /></ProtectedRoute>} />
          <Route exact path='/app/rotations_stats/:access_key' element={<ProtectedRoute><StatisticsProvider><ShowGameStats /></StatisticsProvider></ProtectedRoute>} />
          <Route exact path='/app/practice_stats/:access_key' element={<ProtectedRoute><StatisticsProvider><ShowPracticeStats /></StatisticsProvider></ProtectedRoute>} />
          <Route exact path='/app/show_team_stats/:access_key' element={<ProtectedRoute><StatisticsProvider><ShowTeamStats /></StatisticsProvider></ProtectedRoute>} />
          <Route exact path='/app/show_history_stats/:access_key' element={<ProtectedRoute><StatisticsProvider><ShowHistoryStats /></StatisticsProvider></ProtectedRoute>} />
          <Route exact path='/app/stats_home' element={<ProtectedRoute><MyStatsHome /></ProtectedRoute>} />
          <Route exact path='/app/my_stats' element={<ProtectedRoute><MyStats /></ProtectedRoute>} />
          <Route exact path='/app/my_stats_player' element={<ProtectedRoute><AddStatsPlayerComponent /></ProtectedRoute>} />
          <Route exact path='/app/my_stats_team' element={<ProtectedRoute><AddStatsTeamComponent /></ProtectedRoute>} />

          <Route exact path='/app/user_dashboard' element={<ProtectedRoute><UserDashboard /></ProtectedRoute>} />

          <Route exact path='/app/practice_list' element={<ProtectedRoute><PracticeList /></ProtectedRoute>} />
          <Route exact path='/app/create_practice' element={<ProtectedRoute><PracticeForm /></ProtectedRoute>} />
          <Route exact path='/app/update_practice/:access_key' element={<ProtectedRoute><PracticeForm /></ProtectedRoute>} />
          <Route exact path='/app/practice_details/:access_key' element={<PracticeDetails />} />
          <Route exact path='/app/practice_feedback/:access_key' element={<PracticeFeedbackForm />} />

          <Route exact path='/app/drill_list' element={<ProtectedRoute><DrillList /></ProtectedRoute>} />
          <Route exact path='/app/create_drill' element={<ProtectedRoute><DrillForm /></ProtectedRoute>} />
          <Route exact path='/app/update_drill/:access_key' element={<ProtectedRoute><DrillForm /></ProtectedRoute>} />
          <Route exact path='/app/drill_details/:access_key' element={<ProtectedRoute><DrillDetails /></ProtectedRoute>} />

          <Route exact path='/app/team_list' element={<ProtectedRoute><MyTeams /></ProtectedRoute>} />
          <Route exact path='/app/create_team' element={<ProtectedRoute><TeamForm /></ProtectedRoute>} />
          <Route exact path='/app/update_team/:access_key' element={<ProtectedRoute><TeamForm /></ProtectedRoute>} />

          <Route exact path='/app/tips' element={<ProtectedRoute><Tips /></ProtectedRoute>} />

        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
