import React, { useContext, useEffect, useState } from 'react'
import { listAllAges, listAllMainGoals, listAllPhysicalGoals, listAllTaticalGoals, listAllTechnicalGoals, listAllVolleyballSkills, listDrillByUserAccessKey } from '../../../services/DrillService'
import AuthContext from '../../../context/AuthContext/AuthContext'
import { PiBarbell, PiClipboard, PiClock, PiGlobe, PiHandPointing, PiHeart, PiHeartFill, PiHouse, PiIdentificationCard, PiLock, PiPersonSimpleThrow, PiStar, PiTarget, PiThumbsUp, PiTranslate, PiUser, PiUsers, PiUsersThree } from 'react-icons/pi'
import { Panel } from 'primereact/panel'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'
import { Dialog } from 'primereact/dialog'
import { MultiSelect } from 'primereact/multiselect'
import { InputText } from 'primereact/inputtext'
import PaginatorComponent from '../../../components/PaginatorComponent/PaginatorComponent'
import { InputSwitch } from 'primereact/inputswitch'
import DrillDetails from './DrillDetails'
import UserAutocompleteComponent from '../../../components/UserAutocompleteComponent/UserAutocompleteComponent'
import LoadingSkeletonListComponent from '../../../components/LoadingSkeletonListComponent'
import { translateService } from '../../../services/UtilService'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { Chip } from 'primereact/chip'
import SocialMediaEmbedComponent from '../../../components/SocialMediaEmbedComponent'

function DrillListComponent({isNavigable=true, addDrill, removeDrill, checkDrillAdded, demoMode=false}) {

    const navigate = useNavigate()
    const {userAuth} = useContext(AuthContext)
    const [myDrills, setMyDrills] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalDetailsIsOpen, setModalDetailsIsOpen] = useState(false);
    const [title, setTitle] = useState('')
    const [drillAccessKey, setDrillAccessKey] = useState()
    const [createdBy, setCreatedBy] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const languageCode = navigator.language || navigator.userLanguage; // Detect browser language
    const [translateText, setTranslateText] = useState()    

    const [agesSelected, setAgesSelected] = useState([])
    const [ages, setAges] = useState([]) 

    const [playerPositionsSelected, setPlayerPositionsSelected] = useState([])
    const [playerPositions, setPlayerPositions] = useState([]) 

    const [taticalGoalsSelected, setTaticalGoalsSelected] = useState([])
    const [taticalGoals, setTaticalGoals] = useState([])

    const [technicalGoalsSelected, setTechnicalGoalsSelected] = useState([])
    const [technicalGoals, setTechnicalGoals] = useState([])

    const [physicalGoalsSelected, setPhysicalGoalsSelected] = useState([])
    const [physicalGoals, setPhysicalGoals] = useState([])

    const [mainGoalsSelected, setMainGoalsSelected] = useState([])
    const [mainGoals, setMainGoals] = useState([])

    const [isFavorite, setIsFavorite] = useState(false)
    
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(1)
    useEffect(()=> {
        listDrills()
        listGoals()
        setTranslateText(languageCode === 'pt-BR' ? 'traduzir' : 'translate')
    },[page])

    const listGoals = () => {
        listAllAges().then(response => {
            setAges(response)
        })
        listAllVolleyballSkills().then(response => {
            setPlayerPositions(response)
        })
        listAllPhysicalGoals().then(response => {
            setPhysicalGoals(response)
        })
        listAllTaticalGoals().then(response => {
            setTaticalGoals(response)
        })
        listAllTechnicalGoals().then(response => {
            setTechnicalGoals(response)
        })
        listAllMainGoals().then(response => {
            setMainGoals(response)
        })
    }
    
    const listDrills = async (_page=null) => {
        setIsLoading(true)
        const currentPage = _page ? _page : page
        setPage(currentPage)
        const _agesSelected = agesSelected?.length > 0 ? agesSelected : null
        const _playerPositionsSelected = playerPositionsSelected?.length > 0 ? playerPositionsSelected : null
        const _taticalGoalsSelected = taticalGoalsSelected?.length > 0 ? taticalGoalsSelected : null
        const _technicalGoalsSelected = technicalGoalsSelected?.length > 0 ? technicalGoalsSelected : null
        const _physicalGoalsSelected = physicalGoalsSelected?.length > 0 ? physicalGoalsSelected : null
        const _mainGoalsSelected = mainGoalsSelected?.length > 0 ? mainGoalsSelected : null
        await listDrillByUserAccessKey(userAuth?.user?.access_key, title, _playerPositionsSelected,
            _taticalGoalsSelected, _technicalGoalsSelected, _physicalGoalsSelected, _agesSelected, currentPage, 
            isFavorite, createdBy, _mainGoalsSelected
        ).then((response)=>{
            setMyDrills(demoMode ? response.data?.slice(0, 3) : response.data)
            setTotal(response.total)
        })
        setIsLoading(false)
    }

    const showIconShareLevel = (share_level) => {
        return(
            share_level === 1 ? <PiLock size={18} className='min-w-min' /> :
            share_level === 2 ? <PiUsers size={18} className='min-w-min' /> :
            share_level === 3 ? <PiHouse size={18} className='min-w-min' /> :
            share_level === 4 ? <PiGlobe size={18} className='min-w-min' /> : ''
        )
    }
    
    const  handleAddDrill = () =>{
        navigate('/app/create_drill')
    }

    const handleModalIsOpen = () => {
        setModalIsOpen(!modalIsOpen)
    }
    
    const handleConfirmFilter = async () => {
        listDrills(1);
        setModalIsOpen(false)
    }

    const handleCancelFilter = () => {
        setModalIsOpen(false)
    }
    
    const handleCancelDetails = () => {
        setModalDetailsIsOpen(false)
    }
    
    const handleAges = (e) => {
        setAgesSelected(e.value)
    }

    const handlePlayerPositions = (e) => {
        setPlayerPositionsSelected(e.value)
    }

    const handleTechnicalGoals = (e) => {
        setTechnicalGoalsSelected(e.value)
    }

    const handleTaticalGoals = (e) => {
        setTaticalGoalsSelected(e.value)
    }

    const handlePhysicalGoals = (e) => {
        setPhysicalGoalsSelected(e.value)
    }

    const handleMainGoals = (e) => {
        setMainGoalsSelected(e.value)
    }

    const openDetails = (access_key) => {
        setModalDetailsIsOpen(true)
        setDrillAccessKey(access_key)
    }

    const handleTranslate = (index) => {
        let _myDrills = [...myDrills]
        translateService(['', _myDrills[index].title], languageCode).then((response)=>{
            if (!response || response.length === 0) return
            _myDrills[index].title = response[1].translatedText
            setMyDrills(_myDrills)
        })
    }

    const handleDeleteDrill = (e) => {
        listDrills()
        setModalDetailsIsOpen(e)
    }
    
    return (
        <div className='flex flex-column gap-4 w-full'>
            <div className='text-4xl font-bold'>Drills</div>
            <Panel unstyled>
                <div className="flex justify-content-between flex-wrap p-2 bg-blue-900 w-full border-round gap-2">
                    <div className="flex align-content-center flex-wrap gap-2">
                        {isNavigable && <Button onClick={handleAddDrill} label='Create' icon='pi pi-plus' />}
                    </div>
                    <div className="flex align-content-center flex-wrap gap-2">
                        <Button onClick={handleModalIsOpen} icon='pi pi-search' label={'Search'} />
                    </div>
                </div>
            </Panel>

            <div className='font-bold text-lg'>
                Drill's List
            </div>

            <div className='flex flex-wrap gap-4 justify-content-center'>
                {isLoading ? 
                    <div className='w-full flex flex-column gap-4'>
                        <LoadingSkeletonListComponent />
                        <LoadingSkeletonListComponent />
                        <LoadingSkeletonListComponent />
                    </div> 
                : (myDrills && myDrills?.length > 0) ? myDrills.map((element, key)=>(
                    <div key={key} className='flex flex-column border-solid border-round border-1 border-400 w-12 lg:flex-1 p-2 gap-2'>
                        <div className='flex justify-content-between gap-2 align-items-center mb-2'>
                            <div className='flex flex-column gap-2'>
                                <div className='flex gap-1 align-items-center'>
                                    {showIconShareLevel(element.share_level)}
                                    <div className='font-bold text-lg'>
                                        {element.title} 
                                        <Button onClick={()=>handleTranslate(key)} text className='p-1 text-xs' size='small' label='translate'></Button>
                                    </div>
                                </div>
                            </div>
                            <div className='flex gap-2 align-items-center'>
                                {element.is_favorite ? <PiHeartFill color='red' size={24} /> : <PiHeart color='red' size={24} />}
                                <Button icon='pi pi-info-circle' onClick={e=>openDetails(element.access_key)}/>
                                {(!isNavigable && !checkDrillAdded(element.access_key)) ? <Button severity='success' icon='pi pi-plus' onClick={e=>addDrill(element)} />
                                : (!isNavigable && checkDrillAdded(element.access_key)) ? <Button severity='danger' icon='pi pi-trash' onClick={e=>removeDrill(element.access_key)} /> : ''}
                            </div>
                        </div>
                        
                        <div className='flex flex-column text-sm md:text-base'>
                            <div className='font-bold flex gap-1 align-items-center'><PiTarget /> Main Goals </div> 
                            <div className='white-space-nowrap overflow-hidden text-overflow-ellipsis'>
                                {element.main_categories ? element.main_categories.map(e=>e.name).join(', ') : '-'}
                            </div>
                        </div>

                        <div className='flex justify-content-center'>
                            <SocialMediaEmbedComponent media_url={element.media_url} />                        
                        </div>

                        <div className='flex flex-column gap-2 text-sm md:text-base'>
                            <div className='flex gap-2 justify-content-between'>
                                <div className='flex flex-column'>
                                    <div className='font-bold flex align-items-center gap-1 white-space-nowrap'><PiStar /> Rating:</div>
                                    <div className='white-space-nowrap'>{element.rating ? Number(element.rating).toFixed(1) : '-'}</div>
                                </div>
                                <div className='flex flex-column'>
                                    <div className='flex align-items-center gap-1 font-bold'><PiThumbsUp />Used in</div>
                                    <div className='text-center'>{element.count_drill} {Number(element.count_drill) === 1 ? 'practice' : 'practices'}</div>
                                </div>
                                <div className='flex flex-column'>
                                    <div className='flex align-items-center gap-1 font-bold'><PiClock /> Time </div>
                                    <div className='text-center'>{element.duration} min</div>
                                </div>
                            </div>
                            <div className='flex gap-2 justify-content-between'>
                                <div className='flex flex-column'>
                                    <div className='font-bold flex align-items-center gap-1 white-space-nowrap'><PiHandPointing /> I used this drill:</div>
                                    <div className='white-space-nowrap'>{element.used_by_me}% of my practices</div>
                                </div>
                                <div className='flex flex-column'>
                                    <div className='flex align-items-center gap-1 font-bold'><PiUser /> Added by:</div>
                                    <div className='text-center'>{element.first_name} {element.last_name}</div>
                                </div>
                            </div>
                            <Accordion>
                                <AccordionTab header='More details'>
                                    <div className='flex flex-column gap-4'>
                                        <div className='flex flex-column gap-1'>
                                            <div className='font-bold flex gap-1 align-items-center'><PiIdentificationCard /> Ages </div> 
                                            <div className='flex gap-1 flex-wrap'>
                                                {element.ages ? element.ages.sort((a,b)=>a.name-b.name).map((e,key)=>(<Chip key={key} className='text-xs white-space-nowrap' label={e.name} />)) : '-'}
                                            </div>
                                        </div>
                                        <div className='flex flex-column gap-1'>
                                            <div className='font-bold flex gap-1 align-items-center'><PiPersonSimpleThrow /> Technical Goals </div> 
                                            <div className='flex gap-1 flex-wrap'>
                                                {element.technical_categories ? element.technical_categories.map((e,key)=>(<Chip key={key} className='text-xs white-space-nowrap' label={e.name} />)) : '-'}
                                            </div>
                                        </div>
                                        <div className='flex flex-column gap-1'>
                                            <div className='font-bold flex gap-1 align-items-center'><PiClipboard /> Tactical Goals </div> 
                                            <div className='flex gap-1 flex-wrap'>
                                                {element.tatical_categories ? element.tatical_categories.map((e,key)=>(<Chip key={key} className='text-xs white-space-nowrap' label={e.name} />)) : '-'}
                                            </div>
                                        </div>
                                        <div className='flex flex-column gap-1'>
                                            <div className='font-bold flex gap-1 align-items-center'><PiBarbell /> Physical Goals </div> 
                                            <div className='flex gap-1 flex-wrap'>
                                                {element.physical_categories ? element.physical_categories.map((e,key)=>(<Chip key={key} className='text-xs white-space-nowrap' label={e.name} />)) : '-'}
                                            </div>
                                        </div>
                                        <div className='flex flex-column gap-1'>
                                            <div className='font-bold flex gap-1 align-items-center'><PiUsersThree /> Player Positions </div> 
                                            <div className='flex gap-1 flex-wrap'>
                                                {element.skills ? element.skills.map((e,key)=>(<Chip key={key} className='text-xs white-space-nowrap' label={e.name} />)) : '-'}
                                            </div>
                                        </div>
                                    </div>
                                </AccordionTab>
                            </Accordion>
                        </div>
                    </div>
                )) : 'No drills found.'}
            </div>

        {demoMode ? <Button label='Register for more' onClick={()=>navigate('/register')} className='w-max align-self-center' size='large' /> : <PaginatorComponent page={page} total={total} setPage={setPage} />}








        <Dialog className='w-11 md:w-8' header="Filter" visible={modalIsOpen} onHide={() => setModalIsOpen(false)}
            footer={
                <div>
                    <Button label="Cancel" icon="pi pi-times" onClick={handleCancelFilter} className="p-button-text" />
                    <Button label="Apply" icon="pi pi-check" onClick={handleConfirmFilter} autoFocus />
                </div>
            }>
                <div className="flex flex-column gap-2">

                    <div className="flex flex-column">
                        <label htmlFor='title' className='text--small--normal'>Title</label>
                        <InputText name='title' value={title} onChange={e=>setTitle(e.target.value)} />
                    </div>
                    <div className="flex flex-column">
                        <label htmlFor='title' className='text--small--normal'>Filter by favorites</label>
                        <InputSwitch checked={isFavorite} onChange={(e) => setIsFavorite(e.value)} />
                    </div>
                    <div className="flex flex-column">
                        <label htmlFor='title' className='text--small--normal'>Created by</label>
                        <UserAutocompleteComponent canCreate={false} user={createdBy} setUser={setCreatedBy} 
                        placeholder={`Enter the coach's name.`} searchCoaches={true} />
                    </div>
                    <div className="flex flex-column">
                        <label htmlFor='ages' className='text--small--normal'>Ages</label>
                        <MultiSelect name='ages' optionValue='id' optionLabel='age' className='w-full' options={ages} value={agesSelected} onChange={handleAges} placeholder='Ages' />
                    </div>
                    <div className="flex flex-column">
                        <label htmlFor='main' className='text--small--normal'>Main Goals</label>
                        <MultiSelect name='main' optionValue='id' optionLabel='name' className='w-full' options={mainGoals} value={mainGoalsSelected} onChange={handleMainGoals} placeholder='Main Goals' />
                    </div>
                    <div className="flex flex-column">
                        <label htmlFor='physicals' className='text--small--normal'>Physical Goals</label>
                        <MultiSelect name='physicals' optionValue='id' optionLabel='name' className='w-full' options={physicalGoals} value={physicalGoalsSelected} onChange={handlePhysicalGoals} placeholder='Physical Goals' />
                    </div>
                    <div className='flex flex-column'>
                        <label htmlFor='taticals' className='text--small--normal'>Tactical Goals</label>
                        <MultiSelect name='taticals' optionValue='id' optionLabel='name' className='w-full' options={taticalGoals} value={taticalGoalsSelected} onChange={handleTaticalGoals} placeholder='Tactical Goals' />
                    </div>

                    <div className='flex flex-column'>
                        <label htmlFor='technicals' className='text--small--normal'>Technical Goals</label>
                        <MultiSelect name='technicals' optionValue='id' optionLabel='name' className='w-full' placeholder='Technical Goals' options={technicalGoals} value={technicalGoalsSelected} onChange={handleTechnicalGoals} />
                    </div>

                    <div className='flex flex-column'>
                        <label htmlFor='positions' className='text--small--normal'>Player Position</label>
                        <MultiSelect name='positions' optionValue='id' optionLabel='name' className='w-full' options={playerPositions} onChange={handlePlayerPositions} value={playerPositionsSelected} placeholder={`Player's Positions`} />
                    </div>
                </div>
            </Dialog>


            <Dialog maximized header="Drill Details" visible={modalDetailsIsOpen} onHide={() => setModalDetailsIsOpen(false)}
            footer={
                <div>
                    <Button label="Close" icon="pi pi-times" onClick={handleCancelDetails} className="p-button-text" />
                </div>
            }>
                <DrillDetails access_key={drillAccessKey} setDialogIsOpen={(e)=>handleDeleteDrill(e)} />
            </Dialog>





        </div>
    )
}

export default DrillListComponent