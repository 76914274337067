import { ProgressBar } from 'primereact/progressbar';
import React from 'react'
import { MeterGroup } from 'primereact/metergroup';
import { Card } from 'primereact/card';
import { Chart } from 'primereact/chart';

        

function PracticeGoalsComponent({drillsSelected, practiceTotalDuration}) {

    const footer = (tooltipItems) => {
        return Math.round(tooltipItems[0]?.raw/practiceTotalDuration*100) +'%'
    }

    const showGoals = (arrayMap, title) => {
        const data = {
            labels: [...arrayMap.map(e=>e.name), 'Total (min)'],
            datasets: [
                {
                    label: title,
                    data: [...arrayMap.map(e=>e.duration), practiceTotalDuration],
                    backgroundColor: [
                        'rgba(255, 159, 64, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(153, 102, 255, 0.2)'
                      ],
                      borderColor: [
                        'rgb(255, 159, 64)',
                        'rgb(75, 192, 192)',
                        'rgb(54, 162, 235)',
                        'rgb(153, 102, 255)'
                      ],
                      borderWidth: 1
                }
            ]
        };
        const options = {
            scales: {
                y: {
                    beginAtZero: true
                }
            },
            plugins: {
                tooltip: {
                    callbacks:{
                        footer: footer
                    }
                }
            }
        };

        return(
            <Chart className='w-full lg:w-5' type="bar" data={data} options={options} />
        )
    }

    const listPhysicalPractice =  () =>{
        if (!drillsSelected) return <div></div>;
        const arrayMap = Object.values(drillsSelected.reduce((acc, drill) => {
            const duration = Number(drill.duration) || 0;
            drill.physical_categories?.forEach(pc => {
                if (pc?.id) {
                    if (!acc[pc.id]) {
                    acc[pc.id] = { ...pc, duration: duration };
                    } else {
                    acc[pc.id].duration += duration;
                    }
                }
            });
            return acc;
        }, {}))

        arrayMap.sort((a,b) => a.duration - b.duration)        

        return (
            showGoals(arrayMap, "Physicals Goals")
        );
    }
    
    const listMainPractice =  () =>{
        if (!drillsSelected) return <div></div>;
        const arrayMap = Object.values(drillsSelected.reduce((acc, drill) => {
            const duration = Number(drill.duration) || 0;
            drill.main_categories?.forEach(pc => {
                if (pc?.id) {
                    if (!acc[pc.id]) {
                    acc[pc.id] = { ...pc, duration: duration };
                    } else {
                    acc[pc.id].duration += duration;
                    }
                }
            });
            return acc;
        }, {}))

        arrayMap.sort((a,b) => a.duration - b.duration)        

        return (
            showGoals(arrayMap, "Main Goals")
        );
    }
    
    const listTaticalPractice =  () =>{
        if (!drillsSelected) return <div></div>;
        const arrayMap = Object.values(drillsSelected.reduce((acc, drill) => {
            const duration = Number(drill.duration) || 0;
            drill.tatical_categories?.forEach(pc => {
                if (pc?.id) {
                    if (!acc[pc.id]) {
                    acc[pc.id] = { ...pc, duration: duration };
                    } else {
                    acc[pc.id].duration += duration;
                    }
                }
            });
            return acc;
        }, {}))

        arrayMap.sort((a,b) => a.duration - b.duration)

        return (
            showGoals(arrayMap, 'Tacticals Goals')
        );
    }
    
    const listTechnicalPractice =  () =>{
        if (!drillsSelected) return <div></div>;
        const arrayMap = Object.values(drillsSelected.reduce((acc, drill) => {
            const duration = Number(drill.duration) || 0;
            drill.technical_categories?.forEach(pc => {
                if (pc?.id) {
                    if (!acc[pc.id]) {
                    acc[pc.id] = { ...pc, duration: duration };
                    } else {
                    acc[pc.id].duration += duration;
                    }
                }
            });
            return acc;
        }, {}))
        
        arrayMap.sort((a,b) => a.duration - b.duration)

        return (
            showGoals(arrayMap, 'Technicals Goals')
        );
    }
    
    const listPositionPractice =  () =>{
        if (!drillsSelected) return <div></div>;
        const arrayMap = Object.values(drillsSelected?.reduce((acc, drill) => {
            const duration = Number(drill.duration) || 0;
            drill.skills?.forEach(pc => {
                if (pc?.id) {
                    if (!acc[pc.id]) {
                    acc[pc.id] = { ...pc, duration: duration };
                    } else {
                    acc[pc.id].duration += duration;
                    }
                }
            });
            return acc;
        }, {}));
        
        arrayMap.sort((a,b) => a.duration - b.duration)

        return (
            showGoals(arrayMap, 'Positions Goals')
        );
    }
    
    return (
            <div className='flex flex-wrap gap-4 justify-content-between'>
                {listMainPractice()}
                {listTechnicalPractice()}
                {listTaticalPractice()}
                {listPhysicalPractice()}
                {listPositionPractice()}
            </div>
    )
}

export default PracticeGoalsComponent