import { Component } from "react";
import AppBodyComponent from "../AppBody/AppBodyComponent";

export class ErrorBoundary extends Component {
    constructor(props){
        super(props);
        this.state = {hasError: false}
    }

    static getDerivedStateFromError(error){
        return {hasError: true}
    }

    componentDidCatch(error, errorInfo){
        console.log(error, errorInfo)
    }

    render () {
        if(this.state.hasError){
            return (
                <AppBodyComponent activeMenuNumber={1}>
                    <p style={{padding: '24px'}}>
                        <h3>Something went wrong!</h3>
                        <a href="/app/home"><h5>Go to home</h5></a>
                    </p>
                </AppBodyComponent>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary